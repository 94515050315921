import { Coupon } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function addCoupon(provider_id: string, coupon: Coupon) {
  return {
    type: actionTypes.COUPON_ADD,
    provider_id,
    coupon,
  };
}

export function callbackAddCoupon(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.COUPON_ADD_CALLBACK,
    success,
    errorCode,
  };
}

export function updateCoupon(coupon_id: string, coupon: Coupon, reset: boolean) {
  return {
    type: actionTypes.COUPON_UPDATE,
    coupon_id,
    coupon,
    reset,
  };
}

export function callbackUpdateCoupon(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.COUPON_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

import querystring from "querystring";

const subsidyApi = {
  listSubsidies(headers: Headers, id: string, itemType: "center" | "provider" | "leguplist" | "parent" | "partner"): Promise<any> {
    const queryParams = querystring.stringify({ id, type: itemType });

    return fetch(`/api/subsidies?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data) 
    .catch(e => {
      console.log(e);
    });
  },
};

export default subsidyApi;

import { Coupon } from "@legup/legup-model";

const couponApi = {
  addCoupon(headers: Headers, provider_id: string, coupon: Coupon): Promise<any> {
    return fetch("/api/coupon", {
      headers,
      method: "POST",
      body: JSON.stringify({ provider: provider_id, coupon }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateCoupon(headers: Headers, coupon: Coupon, reset: boolean): Promise<any> {
    return fetch(`/api/coupon/${coupon.getId()}?reset=${reset ? 1 : 0}`, {
      headers,
      method: "POST",
      body: JSON.stringify(coupon),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default couponApi;

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultFeedbackState {
  errorCode: string | undefined;
  submitSuccess: boolean | undefined;
  uploadSuccess: boolean | undefined;
  removeSuccess: boolean | undefined;
  feedbackText: string | undefined;
  currentBucket: string | undefined;
  isLoading: boolean;
}
const defaultProductState: IDefaultFeedbackState = {
  errorCode: undefined,
  submitSuccess: undefined,
  uploadSuccess: undefined,
  removeSuccess: undefined,
  feedbackText: undefined,
  currentBucket: undefined,
  isLoading: false,
};

function reduceUploadFile(state: any, action: any) {
  return { ...state, uploadSuccess: undefined, isLoading: true, currentBucket: action.bucketType };
}

function reduceUploadFileCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, 
      uploadSuccess: false,
      errorCode: action.errorCode,
      isLoading: false };
  }

  return { ...state, uploadSuccess: action.success, isLoading: false };
}

function reduceRemoveFile(state: any, action: any) {
  return { ...state, removeSuccess: undefined, currentBucket: action.bucketType, isLoading: true };
}

function reduceRemoveFileCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, removeSuccess: false, errorCode: action.errorCode, isLoading: false };
  }

  return { ...state, removeSuccess: action.success, isLoading: false };
}

function reduceSubmitFeedback(state: any, _action: any) {
  return { ...state, submitSuccess: undefined };
}

function reduceSubmitFeedbackCallback(state: any, action: any) {
  return { ...state, submitSuccess: action.success, feedbackText: action.toastText };
}

function reduceFeedback(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.FEEDBACK_UPLOAD_FILE:
      return reduceUploadFile(state, action);
    case actionTypes.FEEDBACK_UPLOAD_FILE_CALLBACK:
      return reduceUploadFileCallback(state, action);
    case actionTypes.FEEDBACK_REMOVE_FILE:
      return reduceRemoveFile(state, action);
    case actionTypes.FEEDBACK_REMOVE_FILE_CALLBACK:
      return reduceRemoveFileCallback(state, action);
    case actionTypes.FEEDBACK_SUBMIT:
      return reduceSubmitFeedback(state, action);
    case actionTypes.FEEDBACK_SUBMIT_CALLBACK:
      return reduceSubmitFeedbackCallback(state, action);
    default:
      return state;
  }
}

export default reduceFeedback;

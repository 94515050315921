
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import authApi from "../api/authApi";
import {
  callbackAcceptTerms,
  callbackSignInTokenProvider,
} from "../actions/authAction";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchSignInTokenSaga(action: any) {
  const data = yield call(authApi.token, action.code, action.group, action.name, action.email, action.partner_id);
  yield put(callbackSignInTokenProvider(data.authToken, data.accepted_terms, data.last_login, data.brand, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSignInTokenSaga() {
  yield takeLatest(actionTypes.SIGN_IN_TOKEN, fetchSignInTokenSaga);
}

function* fetchAcceptTermsSaga(action: any) {
  const state = yield select();
  const data = yield call(authApi.acceptTerms, prepareHeader(state.clientStateReducer));
  yield put(callbackAcceptTerms(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAcceptTermsSaga() {
  yield takeLatest(actionTypes.ACCEPT_TERMS, fetchAcceptTermsSaga);
}

function* yieldAuthSagas() {
  yield all([
    fork(getSignInTokenSaga),
    fork(getAcceptTermsSaga),
  ]);
}

export default yieldAuthSagas;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import waitlistApi from "../api/waitlistApi";
import {
  callbackAddSpot,
  callbackExportList,
  callbackFillWaitlist,
  callbackGenerateOfferLinks,
  callbackGetFees,
  callbackImportList,
  callbackListWaitlist,
  callbackReactivateSpot,
  callbackReadSeat,
  callbackReadSpot,
  callbackRemoveSpot,
  callbackSendEmailInvite,
  callbackSetFees,
  callbackUpdateSpot,
  callbackWaitlistFormUrl,
} from "../actions/waitlistActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchFillWaitlistSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.fillWaitlist, prepareHeader(state.clientStateReducer), action.spot_id, action.seat_id, action.remove);
  const success = !!(data && data.success);
  yield put(callbackFillWaitlist(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getFillWaitlistSaga() {
  yield takeLatest(actionTypes.WAITLIST_FILL, fetchFillWaitlistSaga);
}

function* fetchListWaitlistSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.listWaitlist, prepareHeader(state.clientStateReducer), action.list_id, action.states);
  yield put(callbackListWaitlist(data.spots, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getListWaitlistSaga() {
  yield takeLatest(actionTypes.WAITLIST_LIST_WAITLIST, fetchListWaitlistSaga);
}

function* fetchFormUrlSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.getWaitlistFormUrl, prepareHeader(state.clientStateReducer), action.provider_id, action.center_id);
  yield put(callbackWaitlistFormUrl(data.url, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getFormUrlSaga() {
  yield takeLatest(actionTypes.WAITLIST_FORM_URL, fetchFormUrlSaga);
}

function* fetchGetFeesSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.getFees, prepareHeader(state.clientStateReducer), action.provider_id, action.center_id);
  yield put(callbackGetFees(data.waitlist_fees, data.commission, data.center_count, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getFeesSaga() {
  yield takeLatest(actionTypes.WAITLIST_FEES, fetchGetFeesSaga);
}

function* fetchSetFeesSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.setFees, prepareHeader(state.clientStateReducer), action.provider_id, action.center_id, action.waitlist_fees);
  yield put(callbackSetFees(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* setFeesSaga() {
  yield takeLatest(actionTypes.WAITLIST_SET_FEES_LOADING, fetchSetFeesSaga);
}

function* fetchImportListSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.importList, prepareHeader(state.clientStateReducer), action.center_id, action.list_id, action.provider, action.center, action.onboard, action.records, action.initialEmails);
  const success = !!(data && data.success);
  yield put(callbackImportList(success, !data.success ? (data.errorCode || "APIERROR") : undefined, data.spot_ids));
}

function* getImportListSaga() {
  yield takeLatest(actionTypes.WAITLIST_IMPORT_LIST, fetchImportListSaga);
}

function* fetchExportListSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.exportList, prepareHeader(state.clientStateReducer), action.center_id, action.list_id, action.mode);
  yield put(callbackExportList(data.list, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getExportListSaga() {
  yield takeLatest(actionTypes.WAITLIST_EXPORT_LIST, fetchExportListSaga);
}

function* fetchReadSpotSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.readSpot, prepareHeader(state.clientStateReducer), action.spot_id);
  yield put(callbackReadSpot(data.spot, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadSpotSaga() {
  yield takeLatest(actionTypes.WAITLIST_SPOT_READ, fetchReadSpotSaga);
}

function* fetchReadSeatSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.readSeat, prepareHeader(state.clientStateReducer), action.spot_id);
  yield put(callbackReadSeat(data.seat, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadSeatSaga() {
  yield takeLatest(actionTypes.WAITLIST_SPOT_READ_SEAT, fetchReadSeatSaga);
}

function* fetchAddSpotSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.addSpot, prepareHeader(state.clientStateReducer), action.waitlist_spot);
  const success = !!(data && data.success);
  yield put(callbackAddSpot(data.spot_id, success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddSpotSaga() {
  yield takeLatest(actionTypes.WAITLIST_SPOT_ADD, fetchAddSpotSaga);
}

function* fetchRemoveSpotSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.removeSpot, prepareHeader(state.clientStateReducer), action.waitlist_spot,
    action.removal_reason, action.removalRefund, action.deleteSpot, action.mailParents);
  const success = !!(data && data.success);
  yield put(callbackRemoveSpot(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getRemoveSpotSaga() {
  yield takeLatest(actionTypes.WAITLIST_SPOT_REMOVE, fetchRemoveSpotSaga);
}

function* fetchReactivateSpotSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.reactivateSpot, prepareHeader(state.clientStateReducer), action.waitlist_spot);
  const success = !!(data && data.success);
  yield put(callbackReactivateSpot(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReactivateSpotSaga() {
  yield takeLatest(actionTypes.WAITLIST_SPOT_REACTIVATE, fetchReactivateSpotSaga);
}

function* fetchUpdateSpotSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.updateSpot, prepareHeader(state.clientStateReducer), action.waitlist_spot);
  const success = !!(data && data.success);
  yield put(callbackUpdateSpot(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateSpotSaga() {
  yield takeLatest(actionTypes.WAITLIST_SPOT_UPDATE, fetchUpdateSpotSaga);
}

function* fetchGenerateOfferCodesSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.generateOfferLinks, prepareHeader(state.clientStateReducer), action.spot_id, action.seat_id);
  yield put(callbackGenerateOfferLinks(data.email, data.codes, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getGenerateOfferCodesSaga() {
  yield takeLatest(actionTypes.WAITLIST_GENERATE_OFFER_LINKS, fetchGenerateOfferCodesSaga);
}

function* fetchSendEmailInviteSaga(action: any) {
  const state = yield select();
  const data = yield call(waitlistApi.sendEmailInvite, prepareHeader(state.clientStateReducer), action.recipient, action.child_id, action.center_id);
  const success = (data && data.success) ? data.success : undefined;
  yield put(callbackSendEmailInvite(data.recipient, data.child_id, data.center_id, success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSendEmailInviteSaga() {
  yield takeLatest(actionTypes.WAITLIST_SEND_EMAIL_INVITE, fetchSendEmailInviteSaga);
}

function* yieldWaitlistSagas() {
  yield all([
    fork(getFillWaitlistSaga),
    fork(getListWaitlistSaga),
    fork(getFormUrlSaga),
    fork(getFeesSaga),
    fork(setFeesSaga),
    fork(getImportListSaga),
    fork(getExportListSaga),
    fork(getReadSpotSaga),
    fork(getAddSpotSaga),
    fork(getRemoveSpotSaga),
    fork(getReadSeatSaga),
    fork(getReactivateSpotSaga),
    fork(getUpdateSpotSaga),
    fork(getGenerateOfferCodesSaga),
    fork(getSendEmailInviteSaga),
  ]);
}

export default yieldWaitlistSagas;

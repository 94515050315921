
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import seatApi from "../api/seatApi";
import {
  callbackActionableSeats,
  callbackAddSeat,
  callbackOfferFamilySeat,
  callbackOfferLegUpFamilies,
  callbackOfferSeat,
  callbackPauseSeat,
  callbackReadNearHits,
  callbackReadNotes,
  callbackReadSeat,
  callbackReadSpot,
  callbackRemoveSeat,
  callbackSeatReminders,
  callbackSeatUpdateReminder,
  callbackSeatsAdminStats,
  callbackSeatsSummary,
  callbackSplitSeat,
  callbackUpdateSeat,
  callbackUpdateSeatStates,
} from "../actions/seatActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchReadSeatSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.readSeat, prepareHeader(state.clientStateReducer), action.seat_id);
  yield put(callbackReadSeat(data.seat, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadSeatSaga() {
  yield takeLatest(actionTypes.SEAT_READ, fetchReadSeatSaga);
}

function* fetchAddSeatSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.addSeat, prepareHeader(state.clientStateReducer), action.classroom_id, action.seat);
  const success = !!(data && data.success);
  yield put(callbackAddSeat(success, data.seat_id, data.analysis, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddSeatSaga() {
  yield takeLatest(actionTypes.SEAT_ADD, fetchAddSeatSaga);
}

function* fetchUpdateSeatSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.updateSeat, prepareHeader(state.clientStateReducer), action.seat);
  const success = !!(data && data.success);
  yield put(callbackUpdateSeat(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateSeatSaga() {
  yield takeLatest(actionTypes.SEAT_UPDATE, fetchUpdateSeatSaga);
}

function* fetchOfferSeatSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.offerSeat, prepareHeader(state.clientStateReducer), action.seat_id, action.child_id, action.legupFamily);
  const success = !!(data && data.success);
  yield put(callbackOfferSeat(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getOfferSeatSaga() {
  yield takeLatest(actionTypes.SEAT_OFFER, fetchOfferSeatSaga);
}

function* fetchOfferFamilySeatSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.offerFamilySeat, prepareHeader(state.clientStateReducer), action.seat_id,
    action.contract_id, action.tour, action.state, action.notes, action.child, action.parent, action.start_date,
    action.expire_date, action.reminder_date, action.discount_percentage, action.discount_amount,
    action.discount_refundable_deposit, action.discount_reason);
  const success = !!(data && data.success);
  yield put(callbackOfferFamilySeat(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getOfferFamilySeatSaga() {
  yield takeLatest(actionTypes.SEAT_OFFER_FAMILY, fetchOfferFamilySeatSaga);
}

function* fetchUpdateSeatStatesSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.updateSeatStates, prepareHeader(state.clientStateReducer), action.updates, action.mailProvider, action.mailForm);
  const success = !!(data && data.success);
  yield put(callbackUpdateSeatStates(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateSeatStatesSaga() {
  yield takeLatest(actionTypes.SEAT_UPDATE_STATES, fetchUpdateSeatStatesSaga);
}

function* fetchRemoveSeatSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.removeSeat, prepareHeader(state.clientStateReducer), action.seat_id, action.reason);
  const success = !!(data && data.success);
  yield put(callbackRemoveSeat(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getRemoveSeatSaga() {
  yield takeLatest(actionTypes.SEAT_REMOVE, fetchRemoveSeatSaga);
}

function* fetchPauseSeatSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.pauseSeat, prepareHeader(state.clientStateReducer), action.seat_id,
    action.pause, action.paused_until);
  const success = !!(data && data.success);
  yield put(callbackPauseSeat(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getPauseSeatSaga() {
  yield takeLatest(actionTypes.SEAT_PAUSE, fetchPauseSeatSaga);
}

function* fetchOfferLegupFamiliesSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.offerLegupFamilies, prepareHeader(state.clientStateReducer), action.seat_id, action.offer);
  const success = !!(data && data.success);
  yield put(callbackOfferLegUpFamilies(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getOfferLegupFamiliesSaga() {
  yield takeLatest(actionTypes.SEAT_OFFER_LEGUP_FAMILIES, fetchOfferLegupFamiliesSaga);
}

function* fetchSplitSeatSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.splitSeat, prepareHeader(state.clientStateReducer), action.seat_id, action.schedules);
  const success = !!(data && data.success);
  yield put(callbackSplitSeat(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSplitSeatSaga() {
  yield takeLatest(actionTypes.SEAT_SPLIT, fetchSplitSeatSaga);
}

function* fetchGetSeatsSummarySaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.seatsSummary, prepareHeader(state.clientStateReducer), action.partner_id, action.center_id, action.latitude, action.longitude);
  yield put(callbackSeatsSummary(data.directory, data.basics, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSeatsSummarySaga() {
  yield takeLatest(actionTypes.SEAT_SUMMARY, fetchGetSeatsSummarySaga);
}

function* fetchGetSeatsRemindersSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.seatReminders, prepareHeader(state.clientStateReducer));
  yield put(callbackSeatReminders(data.reminders, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSeatsRemindersSaga() {
  yield takeLatest(actionTypes.SEAT_LIST_REMINDERS, fetchGetSeatsRemindersSaga);
}

function* fetchUpdateSeatReminderSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.updateSeatReminder, prepareHeader(state.clientStateReducer), action.reminder_id, action.reminder_time);
  yield put(callbackSeatUpdateReminder(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateSeatReminderSaga() {
  yield takeLatest(actionTypes.SEAT_UPDATE_REMINDER, fetchUpdateSeatReminderSaga);
}

function* fetchSeatsAdminStats(action: any) {
  const state = yield select();
  const data = yield call(seatApi.seatsAdminStats, prepareHeader(state.clientStateReducer));
  yield put(callbackSeatsAdminStats(data.stats, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSeatsAdminStatsSaga() {
  yield takeLatest(actionTypes.SEAT_ADMIN_STATS, fetchSeatsAdminStats);
}

function* fetchActionableSeatsSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.getActionableSeats, prepareHeader(state.clientStateReducer), action.provider_id, action.center_id, action.admin);
  yield put(callbackActionableSeats(data.seats, data.spots, data.mappings, data.stripe, data.legupFees,
    data.tourRequests, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getActionableSeatsSaga() {
  yield takeLatest(actionTypes.SEAT_READ_ACTIONABLE, fetchActionableSeatsSaga);
}

function* fetchReadSpotSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.readSpot, prepareHeader(state.clientStateReducer), action.seat_id);
  yield put(callbackReadSpot(data.spot, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadSpotSaga() {
  yield takeLatest(actionTypes.SEAT_READ_SPOT, fetchReadSpotSaga);
}

function* fetchReadNotesSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.readNotes, prepareHeader(state.clientStateReducer), action.seat_id);
  yield put(callbackReadNotes(data.history, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadNotesSaga() {
  yield takeLatest(actionTypes.SEAT_READ_NOTES, fetchReadNotesSaga);
}

function* fetchReadNearHitsSaga(action: any) {
  const state = yield select();
  const data = yield call(seatApi.readNearHits, prepareHeader(state.clientStateReducer), action.seat_id);
  yield put(callbackReadNearHits(data.nearhits, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadNearHitsSaga() {
  yield takeLatest(actionTypes.SEAT_READ_NEAR_HITS, fetchReadNearHitsSaga);
}

function* yieldAgeGroupSagas() {
  yield all([
    fork(getReadSeatSaga),
    fork(getAddSeatSaga),
    fork(getUpdateSeatSaga),
    fork(getOfferSeatSaga),
    fork(getOfferFamilySeatSaga),
    fork(getUpdateSeatStatesSaga),
    fork(getRemoveSeatSaga),
    fork(getPauseSeatSaga),
    fork(getOfferLegupFamiliesSaga),
    fork(getSplitSeatSaga),
    fork(getSeatsSummarySaga),
    fork(getSeatsRemindersSaga),
    fork(getUpdateSeatReminderSaga),
    fork(getSeatsAdminStatsSaga),
    fork(getActionableSeatsSaga),
    fork(getReadSpotSaga),
    fork(getReadNotesSaga),
    fork(getReadNearHitsSaga),
  ]);
}

export default yieldAgeGroupSagas;

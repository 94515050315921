import querystring from "querystring";

import { AgeGroup } from "@legup/legup-model";

const ageGroupApi = {
  readAgeGroup(headers: Headers, age_group_id: string): Promise<AgeGroup | undefined> {
    return fetch(`/api/agegroup/${age_group_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addAgeGroup(headers: Headers, ageGroup: AgeGroup): Promise<AgeGroup | undefined> {
    const queryParams = querystring.stringify({ center: ageGroup.getCenterId() });

    return fetch(`/api/agegroup?${queryParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify(ageGroup),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateAgeGroup(headers: Headers, ageGroup: AgeGroup): Promise<string> {
    return fetch(`/api/agegroup/${ageGroup.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify(ageGroup),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  deleteAgeGroup(headers: Headers, age_group_id: string, safe: boolean): Promise<string> {
    const queryParams = querystring.stringify({ safe: (safe ? 1 : 0) });

    return fetch(`/api/agegroup/${age_group_id}?${queryParams}`, {
      headers,
      method: "DELETE",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default ageGroupApi;


import querystring from "querystring";

const feedbackApi = {
  uploadFile(headers: Headers, group: string, provider_id: string | undefined, center_id: string | undefined, employee_id: string | undefined, bucketType: string, fileContent: any, filename: string, filetype: string, base64: boolean, filesList?: string[]) {
    const params: any = { group, bucketType, provider: provider_id, center: center_id, employee: employee_id, filename, filetype, filesList };
    if (base64) {
      params.base64 = 1;
    }
    const searchParams = querystring.stringify(params);

    // Several steps 
    // 1) get a signed URL that allows us to upload
    // 2) Upload the file to that temporary URL
    // 3) Let the backend know that we successfully uploaded the file so it can do the needful
    return fetch(`/api/feedback/bucket?${searchParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => {
      if (data.url) {
        return fetch(data.url, {
          headers: {
            "Content-Type": filetype,
            "Content-Encoding": base64 ? "base64" : undefined,
          },
          method: "PUT",
          body: fileContent,
        })
        .then(res => ({ success: (res.status === 200) }))
        .then(dat => {
          if (dat.success) {
            return fetch(`/api/feedback/uploaded?${searchParams}`, {
              headers,
              method: "POST",
            })
            .then(res => res.json())
            .then(d => d);
          }
 
          return dat;
        });
      }
 
      return { success: false };
      
    }).catch(e => {
      console.log(e);
    });
  },

  async removeFile(headers: Headers, group: string, provider_id: string | undefined, center_id: string | undefined, bucketType: string, filename: string, fileId?: string) {
    const params: any = { group, bucketType, provider: provider_id, center: center_id, filename, fileId };
    const searchParams = querystring.stringify(params);
    if (fileId && bucketType !== "profileImages") {
      const req = await fetch(`/api/provider/${provider_id}/files?fileid=${fileId}`, {
        headers,
        method: "DELETE",
      });

      if (req.status === 200) {
        const res = await req.json();
        if (!res.success) {
          return { success: false, errorCode: res.errorCode };
        }
      }
    }
    
    return fetch(`/api/feedback/bucket?${searchParams}`, {
      headers,
      method: "DELETE",
    })
    .then(res => ({ success: (res.status === 200) }))
    .then(data => {
      if (data.success) {
        const fileList: Map<string, string> = new Map([
          [bucketType, null],
        ]);

        return fetch("/api/feedback/updated", {
          headers,
          method: "PUT",
          body: JSON.stringify({ center: center_id, provider: provider_id, fileList: Array.from(fileList.entries()) }),
        })
        .then(res => res.json())
        .then(d => d);
      }

      return data;
    })
    .catch(e => {
      console.log(e);
    });
  },

  submitFeedback(headers: Headers, subject: string, feedback: string): Promise<boolean> {
    return fetch("/api/feedback/submit", {
      headers,
      method: "POST",
      body: JSON.stringify({ subject, feedback }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default feedbackApi;

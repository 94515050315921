import { AgeGroup, Center, Child, Navigator, Parent, Seat, Tour } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface FamilyCenters {
  waitlists: Center[];
  tours: Center[];
  favoriteOnly: Center[];
  centerData: any[];
  favorites: string[];
}

interface IDefaultNavigatorState {
  currentNavigator: Navigator,
  familyList: Array<{ child: Child, parent: Parent }>;
  familyCenters: FamilyCenters;
  childrenWaitlists: any[];
  tourList: Tour[];
  legupMarketId: string | undefined;
  legupWaitlistId: string | undefined;
  addSuccess: boolean | undefined;
  updateSuccess: boolean | undefined;
  errorCode: string | undefined;
}
const defaultProductState: IDefaultNavigatorState = {
  currentNavigator: undefined,
  familyList: undefined,
  familyCenters: undefined,
  childrenWaitlists: undefined,
  tourList: undefined,
  legupMarketId: undefined,
  legupWaitlistId: undefined,
  addSuccess: undefined,
  updateSuccess: undefined,
  errorCode: undefined,
};

function reduceAddNavigator(state: any, action: any) {
  return { ...state, addSuccess: undefined };
}

function reduceAddNavigatorCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, addSuccess: !!action.navigator_id };
}

function reduceUpdateNavigator(state: any, action: any) {
  return { ...state, updateSuccess: undefined };
}

function reduceUpdateNavigatorCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSuccess: action.success };
}

function reduceReadNavigatorCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const currentNavigator: Navigator = new Navigator();

  currentNavigator.buildFromJSON(action.navigator);

  return { ...state, currentNavigator };
}

function reduceReadNavigatorFamiliesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const familyList: Array<{ child: Child, parent: Parent }> = [];
  action.families?.forEach(f => {
    const child = new Child();
    child.buildFromJSON(f.child);
    const parent = new Parent();
    parent.buildFromJSON(f.parent);

    familyList.push({ child, parent });
  });

  return { ...state, familyList };
}

function reduceReadNavigatorStatusCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const legupMarketId: string | undefined = action.legupMarketId;
  const marketWaitlistId: string | undefined = action.marketWaitlistId;

  const childrenWaitlists: any = [];
  if (action.lists) {
    action.lists.forEach(c => {
      // Get child and parent
      const result: any = {};
      result.child = new Child();
      result.child.buildFromJSON(c.child);
      result.parent = new Parent();
      result.parent.buildFromJSON(c.parent);

      // Get waitlists
      result.waitlists = [];
      c.waitlists.forEach(w => {
        const ageGroup: AgeGroup = new AgeGroup();
        ageGroup.buildFromJSON(w.ageGroup);
        const center: Center = new Center();
        center.buildFromJSON(w.center);
        let seat: Seat;
        if (w.seat) {
          seat = new Seat();
          seat.buildFromJSON(w.seat);
        }
        result.waitlists.push({
          ageGroup,
          center,
          seat,
          position: w.position,
          waitlist_type: w.waitlist_type,
          state: w.state,
          spot_id: w.spot_id,
          add_date: w.add_date,
          preferred_date: w.preferred_date,
        });
      });

      if (c.legup) {
        result.legup = {
          list_id: c.legup.list_id,
          spot_id: c.legup.spot_id,
          position: c.legup.position,
          add_date: c.legup.add_date,
          preferred_date: c.legup.preferred_date,
        };
      }

      childrenWaitlists.push(result);
    });
  }

  const familySeats: any = [];
  if (action.seats) {
    action.seats.forEach(s => {
      // Get child
      const result: any = {};
      result.child = new Child();
      result.child.buildFromJSON(s.child);
      result.parent = new Parent();
      result.parent.buildFromJSON(s.parent);

      result.seat = new Seat();
      result.seat.buildFromJSON(s.seat);

      result.center = new Center();
      result.center.buildFromJSON(s.center);

      familySeats.push(result);
    });
  }

  const tourList: Tour[] = [];
  if (action.tours) {
    action.tours.forEach(t => {
      const tour: Tour = new Tour();
      tour.buildFromJSON(t);
      tourList.push(tour);
    });
  }

  return { ...state, childrenWaitlists, familySeats, tourList, legupMarketId, marketWaitlistId };
}

function reduceReadNavigatorCentersCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const familyCenters = { waitlists: [], tours: [], centerData: [], favoriteOnly: [], favorites: [] };
  (action.waitlists || []).forEach(c => {
    const center: Center = new Center();
    center.buildFromJSON(c);
    familyCenters.waitlists.push(center);
  });
  (action.tours || []).forEach(c => {
    const center: Center = new Center();
    center.buildFromJSON(c);
    familyCenters.tours.push(center);
  });
  (action.favoriteOnly || []).forEach(c => {
    const center: Center = new Center();
    center.buildFromJSON(c);
    familyCenters.favoriteOnly.push(center);
  });
  (action.centerData || []).forEach(c => {
    familyCenters.centerData.push(c);
  });
  (action.favorites || []).forEach(f => {
    familyCenters.favorites.push(f);
  });

  return { ...state, familyCenters };
}

function reduceNavigators(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.NAVIGATOR_ADD:
      return reduceAddNavigator(state, action);
    case actionTypes.NAVIGATOR_ADD_CALLBACK:
      return reduceAddNavigatorCallback(state, action);
    case actionTypes.NAVIGATOR_UPDATE:
      return reduceUpdateNavigator(state, action);
    case actionTypes.NAVIGATOR_UPDATE_CALLBACK:
      return reduceUpdateNavigatorCallback(state, action);
    case actionTypes.NAVIGATOR_READ_CALLBACK:
      return reduceReadNavigatorCallback(state, action);
    case actionTypes.NAVIGATOR_READ_STATUS_CALLBACK:
      return reduceReadNavigatorStatusCallback(state, action);
    case actionTypes.NAVIGATOR_READ_FAMILIES_CALLBACK:
      return reduceReadNavigatorFamiliesCallback(state, action);
    case actionTypes.NAVIGATOR_READ_CENTERS_CALLBACK:
      return reduceReadNavigatorCentersCallback(state, action);
    default:
      return state;
  }
}

export default reduceNavigators;

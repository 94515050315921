import { Center, Child, Parent, Seat, Tour, WaitlistSpot } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultLinkState {
  validateSuccess: boolean | undefined;
  actionSuccess: boolean | undefined;
  actionIsActive: boolean | undefined;
  action: string | undefined;
  payload: any | undefined;
  errorCode: string | undefined;
}
const defaultProductState: IDefaultLinkState = {
  actionSuccess: undefined,
  actionIsActive: undefined,
  validateSuccess: undefined,
  action: undefined,
  payload: undefined,
  errorCode: undefined,
};

function reduceValidateLink(state: any, action: any) {
  return { ...state, validateSuccess: undefined, action: undefined };
}

function reduceValidateLinkCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, validateSuccess: false, errorCode: action.errorCode };
  }

  // If we have legup-model objects, build them up
  const payload = action.payload;
  if (payload) {
    if (payload.seat) {
      const seat = new Seat();
      seat.buildFromJSON(payload.seat);
      payload.seat = seat;
    }
    if (payload.spot) {
      const spot = new WaitlistSpot();
      spot.buildFromJSON(payload.spot);
      payload.spot = spot;
    }
    if (payload.parent) {
      const parent = new Parent();
      parent.buildFromJSON(payload.parent);
      payload.parent = parent;
    }
    if (payload.parent2) {
      const parent2 = new Parent();
      parent2.buildFromJSON(payload.parent2);
      payload.parent2 = parent2;
    }
    if (payload.child) {
      const child = new Child();
      child.buildFromJSON(payload.child);
      payload.child = child;
    }
    if (payload.center) {
      const center = new Center();
      center.buildFromJSON(payload.center);
      payload.center = center;
    }
    if (payload.tour) {
      const tour = new Tour();
      tour.buildFromJSON(payload.tour);
      payload.tour = tour;
    }
  }

  return { ...state, validateSuccess: action.success, action: action.action, payload };
}

function reduceActionLink(state: any, action: any) {
  return { ...state, actionSuccess: undefined, actionIsActive: undefined };
}

function reduceActionLinkCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, actionSuccess: false, actionIsActive: false, errorCode: action.errorCode };
  }

  return { ...state, actionSuccess: action.success, actionIsActive: action.active };
}

function reduceLink(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.LINK_VALIDATE:
      return reduceValidateLink(state, action);
    case actionTypes.LINK_VALIDATE_CALLBACK:
      return reduceValidateLinkCallback(state, action);
    case actionTypes.LINK_ACTION:
      return reduceActionLink(state, action);
    case actionTypes.LINK_ACTION_CALLBACK:
      return reduceActionLinkCallback(state, action);
    default:
      return state;
  }
}

export default reduceLink;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import legupListApi from "../api/leguplistApi";
import {
  callbackFindLegupList,
  callbackReadLegupList,
  callbackUpdateLegUpListQuestions,
} from "../actions/leguplistActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchFindLegupListSaga(action: any) {
  const state = yield select();
  const data = yield call(legupListApi.findLegupList, prepareHeader(state.clientStateReducer), action.market);
  yield put(callbackFindLegupList(data.legupLists, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getFindLegupListSaga() {
  yield takeLatest(actionTypes.LEGUPLIST_FIND, fetchFindLegupListSaga);
}

function* fetchReadLegupListSaga(action: any) {
  const state = yield select();
  const data = yield call(legupListApi.readLegupList, prepareHeader(state.clientStateReducer), action.legup_list_id);
  yield put(callbackReadLegupList(data.legupList, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadLegupListSaga() {
  yield takeLatest(actionTypes.LEGUPLIST_READ, fetchReadLegupListSaga);
}

function* fetchUpdateQuestionsSaga(action: any) {
  const state = yield select();
  const data = yield call(legupListApi.updateLegUpListQuestions, prepareHeader(state.clientStateReducer), action.legup_list_id, action.questions);
  yield put(callbackUpdateLegUpListQuestions(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateQuestionsSaga() {
  yield takeLatest(actionTypes.LEGUPLIST_UPDATE_QUESTIONS, fetchUpdateQuestionsSaga);
}

function* yieldLeguplistSagas() {
  yield all([
    fork(getFindLegupListSaga),
    fork(getReadLegupListSaga),
    fork(getUpdateQuestionsSaga),
  ]);
}

export default yieldLeguplistSagas;

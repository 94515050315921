import querystring from "querystring";

const searchApi = {
  findItem(headers: Headers, provider_id: string, name: string): Promise<any> {
    const queryParams = querystring.stringify({ pid: provider_id, name });

    return fetch(`/api/search?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  findEmail(headers: Headers, email: string, includePhone: boolean, types: string[]): Promise<any> {
    const queryParams = querystring.stringify({ email, phone: !!includePhone, types: JSON.stringify(types) });

    return fetch(`/api/search/email?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default searchApi;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import commsApi from "../api/commsApi";
import {
  callbackReadCenterContacts,
  callbackReadInboxMessages,
  callbackReadMessageThread,
  callbackReadMessages,
  callbackReadParentContacts, callbackReadSentMessages,
} from "../actions/chatActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchRetrieveMessagesSaga(action: any) {
  const state = yield select();
  const data = yield call(commsApi.readMessages, prepareHeader(state.clientStateReducer), action.to_contact, action.from_contact);
  yield put(callbackReadMessages(data.messages, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getMessagesSaga() {
  yield takeLatest(actionTypes.CHAT_READ, fetchRetrieveMessagesSaga);
}

function* fetchRetrieveMessageThreadSaga(action: any) {
  const state = yield select();
  const data = yield call(commsApi.readMessageThread, prepareHeader(state.clientStateReducer), action.thread);
  yield put(callbackReadMessageThread(data.messages, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getMessageThreadSaga() {
  yield takeLatest(actionTypes.COMMS_THREAD_READ, fetchRetrieveMessageThreadSaga);
}

function* fetchRetrieveInboxMessagesSaga(action: any) {
  const state = yield select();
  const data = yield call(commsApi.readInboxMessages, prepareHeader(state.clientStateReducer), action.to_contact);
  yield put(callbackReadInboxMessages(data.messages, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getInboxMessagesSaga() {
  yield takeLatest(actionTypes.COMMS_INBOX_READ, fetchRetrieveInboxMessagesSaga);
}

function* fetchRetrieveSentMessagesSaga(action: any) {
  const state = yield select();
  const data = yield call(commsApi.readSentMessages, prepareHeader(state.clientStateReducer), action.from_contact);
  yield put(callbackReadSentMessages(data.messages, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSentMessagesSaga() {
  yield takeLatest(actionTypes.COMMS_SENT_READ, fetchRetrieveSentMessagesSaga);
}

function* fetchRetrieveParentContactsSaga(action: any) {
  const state = yield select();
  const data = yield call(commsApi.readParentContacts, prepareHeader(state.clientStateReducer), action.center_id);
  yield put(callbackReadParentContacts(data.contacts, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getParentContactsSaga() {
  yield takeLatest(actionTypes.COMMS_PARENT_CONTACTS_READ, fetchRetrieveParentContactsSaga);
}

function* fetchRetrieveCenterContactsSaga(action: any) {
  const state = yield select();
  const data = yield call(commsApi.readParentContacts, prepareHeader(state.clientStateReducer), action.center_id);
  yield put(callbackReadCenterContacts(data.contacts, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getCenterContactsSaga() {
  yield takeLatest(actionTypes.COMMS_CENTER_CONTACTS_READ, fetchRetrieveCenterContactsSaga);
}

function* yieldCommsSagas() {
  yield all([
    fork(getMessagesSaga),
    fork(getInboxMessagesSaga),
    fork(getSentMessagesSaga),
    fork(getMessageThreadSaga),
    fork(getCenterContactsSaga),
    fork(getParentContactsSaga),

  ]);
}

export default yieldCommsSagas;

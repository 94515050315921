import { AdminUnit } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function findAdminUnits(name: string) {
  return {
    type: actionTypes.ADMIN_UNIT_FIND,
    name,
  };
}

export function callbackFindAdminUnits(adminUnitList: AdminUnit[], errorCode: string | undefined) {
  return {
    type: actionTypes.ADMIN_UNIT_FIND_CALLBACK,
    adminUnitList,
    errorCode,
  };
}

export function readAdminUnit(admin_unit_id: string) {
  return {
    type: actionTypes.ADMIN_UNIT_READ,
    admin_unit_id,
  };
}

export function callbackReadAdminUnit(adminUnit: AdminUnit, errorCode: string | undefined) {
  return {
    type: actionTypes.ADMIN_UNIT_READ_CALLBACK,
    adminUnit,
    errorCode,
  };
}

export function addAdminUnit(adminUnit: AdminUnit) {
  return {
    type: actionTypes.ADMIN_UNIT_ADD,
    adminUnit,
  };
}

export function callbackAddAdminUnit(admin_unit_id: string, errorCode: string | undefined) {
  return {
    type: actionTypes.ADMIN_UNIT_ADD_CALLBACK,
    admin_unit_id,
    errorCode,
  };
}

export function updateAdminUnit(adminUnit: AdminUnit) {
  return {
    type: actionTypes.ADMIN_UNIT_UPDATE,
    adminUnit,
  };
}

export function callbackUpdateAdminUnit(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.ADMIN_UNIT_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

import { DashboardTip, User } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultUserState {
  currentUser: User | undefined;
  dashboardTips: DashboardTip[] | undefined;
  notifications: any;
  interactions: string[];
  avatarUrl: string | undefined;
  updateSuccess: boolean | undefined;
  setFavoriteSuccess: boolean | undefined;
  setInteractionSuccess: boolean | undefined;
  errorCode: string | undefined;
}
const defaultProductState: IDefaultUserState = {
  currentUser: undefined,
  dashboardTips: undefined,
  notifications: undefined,
  interactions: undefined,
  avatarUrl: undefined,
  updateSuccess: undefined,
  setFavoriteSuccess: undefined,
  setInteractionSuccess: undefined,
  errorCode: undefined,
};

function reduceReadUserCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const currentUser: User = new User();
  currentUser.buildFromJSON(action.user);

  return { ...state, currentUser };
}

function reduceReadUserTipsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, dashboardTips: action.tips };
}

function reduceUserNotificationsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, notifications: action.notifications };
}

function reduceUpdateUserCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSuccess: action.success, avatarUrl: action.avatarUrl };
}

function reduceUserSetFavorite(state: any, action: any) {
  return { ...state, setFavoriteSuccess: undefined };
}

function reduceUserGetInteractionCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, interactions: action.interactions || [] };
}

function reduceUserSetFavoriteCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, setFavoriteSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, setFavoriteSuccess: action.success };
}

function reduceUserSetInteractionCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, setInteractionSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, setInteractionSuccess: action.success };
}

function reduceLink(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.USER_READ_CALLBACK:
      return reduceReadUserCallback(state, action);
    case actionTypes.USER_READ_TIPS_CALLBACK:
      return reduceReadUserTipsCallback(state, action);
    case actionTypes.USER_NOTIFICATIONS_CALLBACK:
      return reduceUserNotificationsCallback(state, action);
    case actionTypes.USER_UPDATE_CALLBACK:
      return reduceUpdateUserCallback(state, action);
    case actionTypes.USER_SET_FAVORITE:
      return reduceUserSetFavorite(state, action);
    case actionTypes.USER_SET_FAVORITE_CALLBACK:
      return reduceUserSetFavoriteCallback(state, action);
    case actionTypes.USER_GET_INTERACTION_CALLBACK:
      return reduceUserGetInteractionCallback(state, action);
    case actionTypes.USER_SET_INTERACTION_CALLBACK:
      return reduceUserSetInteractionCallback(state, action);
    default:
      return state;
  }
}

export default reduceLink;

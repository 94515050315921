import { AgeGroup } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function readAgeGroup(age_group_id: string) {
  return {
    type: actionTypes.AGEGROUP_READ,
    age_group_id,
  };
}

export function callbackReadAgeGroup(ageGroup: AgeGroup, errorCode: string | undefined) {
  return {
    type: actionTypes.AGEGROUP_READ_CALLBACK,
    ageGroup,
    errorCode,
  };
}

export function addAgeGroup(ageGroup: AgeGroup, centerId: string) {
  return {
    type: actionTypes.AGEGROUP_ADD,
    ageGroup,
    centerId,
  };
}

export function callbackAddAgeGroup(ageGroup: AgeGroup, errorCode: string | undefined) {
  return {
    type: actionTypes.AGEGROUP_ADD_CALLBACK,
    ageGroup,
    errorCode,
  };
}

export function updateAgeGroup(ageGroup: AgeGroup, centerId: string) {
  return {
    type: actionTypes.AGEGROUP_UPDATE,
    ageGroup,
    centerId,
  };
}

export function callbackUpdateAgeGroup(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.AGEGROUP_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

export function deleteAgeGroup(age_group_id: string, center_id: string, safe: boolean) {
  return {
    type: actionTypes.AGEGROUP_DELETE,
    age_group_id,
    center_id,
    safe,
  };
}

export function callbackDeleteAgeGroup(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.AGEGROUP_DELETE_CALLBACK,
    success,
    errorCode,
  };
}

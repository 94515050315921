import querystring from "querystring";

const legupListApi = {
  findLegupList(headers: Headers, market: string): Promise<string | undefined> {
    const queryParams = querystring.stringify({ market });

    return fetch(`/api/leguplist/find?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readLegupList(headers: Headers, legup_list_id: string): Promise<any[]> {
    return fetch(`/api/leguplist/${legup_list_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateLegUpListQuestions(headers: Headers, legup_list_id: string, questions: any) {
    return fetch(`/api/leguplist/${legup_list_id}/questions`, {
      headers,
      method: "POST",
      body: JSON.stringify({ questions }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default legupListApi;


import actionTypes from "../infra/constants/actionTypes";

interface Messages {
  messages: any[]
}

interface IDefaultChatState {
  messages: Messages;
  parentContacts: any[];
  centerContacts: any[];
  folderMessages: Messages;
  errorCode: string | undefined;
}
const defaultProductState: IDefaultChatState = {
  messages: undefined,
  parentContacts: undefined,
  centerContacts: undefined,
  folderMessages: undefined,
  errorCode: undefined,
};

function reduceReadMessagesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  let messages;
  let folderMessages;

  switch (action.type) {
    case actionTypes.COMMS_THREAD_READ_CALLBACK:
    case actionTypes.CHAT_READ_CALLBACK:
      messages = action.messages;

      return { ...state, messages };
    case actionTypes.COMMS_INBOX_READ_CALLBACK:
      folderMessages = action.messages;

      return { ...state, folderMessages };
    case actionTypes.COMMS_SENT_READ_CALLBACK:
      folderMessages = action.messages;

      return { ...state, folderMessages };

    case actionTypes.COMMS_RESET_FOLDER_MESSAGES:
      folderMessages = null;

      return { ...state, folderMessages };

  }

}

function reduceReadContactsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  switch (action.type) {
    case actionTypes.COMMS_PARENT_CONTACTS_READ_CALLBACK:
      const parentContacts = action.contacts;

      return { ...state, parentContacts };
    case actionTypes.COMMS_CENTER_CONTACTS_READ_CALLBACK:
      const centerContacts = action.contacts;

      return { ...state, centerContacts };
    
  }

}

function reduceChat(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.CHAT_READ_CALLBACK:
      return reduceReadMessagesCallback(state, action);
    case actionTypes.COMMS_INBOX_READ_CALLBACK:
      return reduceReadMessagesCallback(state, action);
    case actionTypes.COMMS_SENT_READ_CALLBACK:
      return reduceReadMessagesCallback(state, action);
    case actionTypes.COMMS_THREAD_READ_CALLBACK:
      return reduceReadMessagesCallback(state, action);
    case actionTypes.COMMS_CENTER_CONTACTS_READ_CALLBACK:
      return reduceReadContactsCallback(state, action);
    case actionTypes.COMMS_PARENT_CONTACTS_READ_CALLBACK:
      return reduceReadContactsCallback(state, action);
    case actionTypes.COMMS_RESET_FOLDER_MESSAGES:
      return reduceReadMessagesCallback(state, action);
    default:
      return state;
  }
}

export default reduceChat;

import { LegUpList } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function findLegupList(market: string) {
  return {
    type: actionTypes.LEGUPLIST_FIND,
    market,
  };
}

export function callbackFindLegupList(legupLists: LegUpList[] | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.LEGUPLIST_FIND_CALLBACK,
    legupLists,
    errorCode,
  };
}

export function readLegupList(legup_list_id: string) {
  return {
    type: actionTypes.LEGUPLIST_READ,
    legup_list_id,
  };
}

export function callbackReadLegupList(legupList: LegUpList, errorCode: string | undefined) {
  return {
    type: actionTypes.LEGUPLIST_READ_CALLBACK,
    legupList,
    errorCode,
  };
}

export function updateLegUpListQuestions(legup_list_id: string, questions: any) {
  return {
    type: actionTypes.LEGUPLIST_UPDATE_QUESTIONS,
    legup_list_id,
    questions,
  };
}

export function callbackUpdateLegUpListQuestions(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.LEGUPLIST_UPDATE_QUESTIONS_CALLBACK,
    success,
    errorCode,
  };
}

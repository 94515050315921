import { AgeGroup, Center, Child, Seat, Tour, TourRequest } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultChildState {
  currentChild: Child | undefined;
  waitlists: any | undefined;
  tours: Tour[] | undefined;
  tourRequests: TourRequest[] | undefined;
  enrolledSeat: Seat | undefined | null;
  positionDetails: any | undefined;
  history: any[] | undefined;
  addSuccess: boolean | undefined;
  updateSuccess: boolean | undefined;
  errorCode: string | undefined;
}
const defaultProductState: IDefaultChildState = {
  currentChild: null,
  waitlists: undefined,
  tours: undefined,
  tourRequests: undefined,
  enrolledSeat: undefined,
  positionDetails: undefined,
  history: undefined,
  addSuccess: undefined,
  updateSuccess: undefined,
  errorCode: undefined,
};

function reduceReadChildCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }
  const child: Child = new Child();

  child.buildFromJSON(action.child);

  return { ...state, currentChild: child };
}

function reduceReadWaitlistsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }
  const waitlists: any = {};

  if (action.waitlists && action.waitlists.waitlists) {
    waitlists.waitlists = [];
    action.waitlists.waitlists.forEach(w => {
      const ageGroup: AgeGroup = new AgeGroup();
      const center: Center = new Center();
      ageGroup.buildFromJSON(w.ageGroup);
      center.buildFromJSON(w.center);
      waitlists.waitlists.push({
        ageGroup,
        center,
        position: w.position,
        waitlist_type: w.waitlist_type,
        state: w.state,
        spot_id: w.spot_id,
        seat_id: w.seat_id,
        preferred_date: w.preferred_date,
        provider_notes: w.provider_notes,
      });
    });
  }
  if (action.waitlists?.legup) {
    waitlists.legup = {
      list_id: action.waitlists.legup.list_id,
      spot_id: action.waitlists.legup.spot_id,
      preferred_date: action.waitlists.legup.preferred_date,
    };
  }
  if (action.waitlists?.partner) {
    waitlists.partner = {
      list_id: action.waitlists.partner.list_id,
      spot_id: action.waitlists.partner.spot_id,
      preferred_date: action.waitlists.partner.preferred_date,
    };
  }

  return { ...state, waitlists };
}

function reduceReadWaitlistPositionCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, positionDetails: action.positionDetails };
}

function reduceReadSeatsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  let enrolledSeat = null;
  if (action.seats && action.seats.length) {
    // We're just going to take the first one if there are multiple
    // We'll take the highest seat ID to be consistent
    let seat;
    action.seats.forEach(s => {
      if (!seat || (s.seat_id > seat.seat_id)) {
        seat = s;
      }
    });

    enrolledSeat = new Seat();
    enrolledSeat.buildFromJSON(seat);
  }

  return { ...state, enrolledSeat };
}

function reduceReadToursCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const tours: Tour[] = [];
  const tourRequests: TourRequest[] = [];
  (action.tours || []).forEach(t => {
    const tour: Tour = new Tour();
    tour.buildFromJSON(t);
    tours.push(tour);
  });
  (action.tourRequests || []).forEach(r => {
    const tourRequest: TourRequest = new TourRequest();
    tourRequest.buildFromJSON(r);
    tourRequests.push(tourRequest);
  });

  return { ...state, tours, tourRequests };
}

function reduceAddChild(state: any, action: any) {
  return { ...state, addSuccess: undefined };
}

function reduceAddChildCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addSuccess: false, errorCode: action.errorCode };
  }
  if (action.child_id) {
    // Set the ID of the current child
    const child: Child = state.currentChild ? state.currentChild : new Child();

    child.setId(action.child_id);

    return { ...state, currentChild: child, addSuccess: true };
  }

  return { ...state, addSuccess: false };

}

function reduceReadChildNotesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, history: action.history };
}

function reduceUpdateChild(state: any, action: any) {
  return { ...state, updateSuccess: undefined };
}

function reduceUpdateChildCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSuccess: action.success };
}

function reduceChild(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.CHILD_READ_CALLBACK:
      return reduceReadChildCallback(state, action);
    case actionTypes.CHILD_READ_WAITLISTS_CALLBACK:
      return reduceReadWaitlistsCallback(state, action);
    case actionTypes.CHILD_READ_WAITLIST_POSITION_CALLBACK:
      return reduceReadWaitlistPositionCallback(state, action);
    case actionTypes.CHILD_READ_SEATS_CALLBACK:
      return reduceReadSeatsCallback(state, action);
    case actionTypes.CHILD_READ_TOURS_CALLBACK:
      return reduceReadToursCallback(state, action);
    case actionTypes.CHILD_ADD:
      return reduceAddChild(state, action);
    case actionTypes.CHILD_ADD_CALLBACK:
      return reduceAddChildCallback(state, action);
    case actionTypes.CHILD_UPDATE:
      return reduceUpdateChild(state, action);
    case actionTypes.CHILD_READ_NOTES_CALLBACK:
      return reduceReadChildNotesCallback(state, action);
    case actionTypes.CHILD_UPDATE_CALLBACK:
      return reduceUpdateChildCallback(state, action);
    default:
      return state;
  }
}

export default reduceChild;

import querystring from "querystring";

import { Parent, Tour, WaitlistSpot } from "@legup/legup-model";

const linkApi = {
  // NOTE: This API does not require authentication
  validateLink(code: string, email: string | undefined, token: string | undefined): Promise<any> {
    const params: any = {};
    if (email) {
      params.email = email;
    }
    if (token) {
      params.token = token;
    }
    const queryParams = querystring.stringify(params);

    return fetch(`/api/link/${code}/validate?${queryParams}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  // NOTE: This API does not require authentication
  actionLink(code: string, email: string | undefined, token: string | undefined, action: any): Promise<any> {
    const params: any = {};
    if (email) {
      params.email = email;
    }
    if (token) {
      params.token = token;
    }
    const queryParams = querystring.stringify(params);

    return fetch(`/api/link/${code}/action?${queryParams}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(action),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default linkApi;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import programApi from "../api/programApi";
import {
  callbackAddPrograms,
  callbackCopyProgram,
  callbackDeleteProgram,
  callbackUpdateProgram,
} from "../actions/programActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchAddProgramSaga(action: any) {
  const state = yield select();
  const data = yield call(programApi.addPrograms, prepareHeader(state.clientStateReducer), action.programs);
  const success = !!(data && data.success);
  yield put(callbackAddPrograms(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddProgramSaga() {
  yield takeLatest(actionTypes.PROGRAM_ADD, fetchAddProgramSaga);
}

function* fetchUpdateProgramSaga(action: any) {
  const state = yield select();
  const data = yield call(programApi.updateProgram, prepareHeader(state.clientStateReducer), action.program);
  const success = !!(data && data.success);
  yield put(callbackUpdateProgram(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateProgramSaga() {
  yield takeLatest(actionTypes.PROGRAM_UPDATE, fetchUpdateProgramSaga);
}

function* fetchCopyProgramSaga(action: any) {
  const state = yield select();
  const data = yield call(programApi.copyProgram, prepareHeader(state.clientStateReducer), action.program_id,
    action.program_prefix, action.program_suffix, action.contract_prefix, action.contract_suffix);
  const success = !!(data && data.success);
  yield put(callbackCopyProgram(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getCopyProgramSaga() {
  yield takeLatest(actionTypes.PROGRAM_COPY, fetchCopyProgramSaga);
}

function* fetchDeleteProgramSaga(action: any) {
  const state = yield select();
  const data = yield call(programApi.deleteProgram, prepareHeader(state.clientStateReducer), action.program_id);
  const success = !!(data && data.success);
  yield put(callbackDeleteProgram(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getDeleteProgramSaga() {
  yield takeLatest(actionTypes.PROGRAM_DELETE, fetchDeleteProgramSaga);
}

function* yieldProgramSagas() {
  yield all([
    fork(getAddProgramSaga),
    fork(getUpdateProgramSaga),
    fork(getCopyProgramSaga),
    fork(getDeleteProgramSaga),
  ]);
}

export default yieldProgramSagas;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import parentApi from "../api/parentApi";
import {
  callbackAddParent,
  callbackFindParents,
  callbackMergeChildren,
  callbackReadFamilyCenters,
  callbackReadParent,
  callbackReadParentStatus,
  callbackUpdateParent,
  callbackUpdateParentSubsidies,
} from "../actions/parentActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchFindParentsSaga(action: any) {
  const state = yield select();
  const data = yield call(parentApi.findParents, prepareHeader(state.clientStateReducer), action.name, action.provider_id, action.center_id);
  yield put(callbackFindParents(data.parents, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getFindParentsSaga() {
  yield takeLatest(actionTypes.PARENT_FIND, fetchFindParentsSaga);
}

function* fetchReadParentSaga(action: any) {
  const state = yield select();
  const data = yield call(parentApi.readParent, prepareHeader(state.clientStateReducer), action.parent_id);
  let data2;
  const parents = [];

  if (data.parent) {
    parents.push(data.parent);

    // If the parent has a spouse, read that parent too
    // We don't fail if that call fails however
    if (data.parent.pid_spouse) {
      data2 = yield call(parentApi.readParent, prepareHeader(state.clientStateReducer), data.parent.pid_spouse);
      if (data2.parent) {
        parents.push(data2.parent);
      }
    }
  }

  yield put(callbackReadParent(parents, data.navigator, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadParentSaga() {
  yield takeLatest(actionTypes.PARENT_READ, fetchReadParentSaga);
}

function* fetchReadParentStatusSaga(action: any) {
  const state = yield select();
  const data = yield call(parentApi.readParentStatus, prepareHeader(state.clientStateReducer), action.parent_id, action.partner_id);
  yield put(callbackReadParentStatus(data.children, data.seats, data.tours, data.matchedSeats,
    data.savedCenters, data.unseenCenters, data.market_waitlist_id,
    data.legup_list_id, data.tookSurvey, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadParentStatusSaga() {
  yield takeLatest(actionTypes.PARENT_READ_STATUS, fetchReadParentStatusSaga);
}

function* fetchReadFamilyCentersSaga(action: any) {
  const state = yield select();
  const data = yield call(parentApi.readFamilyCenters, prepareHeader(state.clientStateReducer), action.parent_id, action.center_id);
  yield put(callbackReadFamilyCenters(data.waitlists, data.tours, data.favoriteOnly, data.centerData, data.favorites, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadFamilyCentersSaga() {
  yield takeLatest(actionTypes.PARENT_READ_CENTERS, fetchReadFamilyCentersSaga);
}

function* fetchAddParentSaga(action: any) {
  const state = yield select();
  const data = yield call(parentApi.addParent, prepareHeader(state.clientStateReducer), action.parent, action.children);
  const parent_id = (data && data.parent_id) ? data.parent_id : undefined;
  yield put(callbackAddParent(parent_id, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddParentSaga() {
  yield takeLatest(actionTypes.PARENT_ADD, fetchAddParentSaga);
}

function* fetchUpdateParentSaga(action: any) {
  const state = yield select();
  const data = yield call(parentApi.updateParent, prepareHeader(state.clientStateReducer), action.parent);
  const success = (data && data.success) ? data.success : undefined;
  yield put(callbackUpdateParent(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateParentSaga() {
  yield takeLatest(actionTypes.PARENT_UPDATE, fetchUpdateParentSaga);
}

function* fetchUpdateParentSubsidiesSaga(action: any) {
  const state = yield select();
  const data = yield call(parentApi.updateParentSubsidies, prepareHeader(state.clientStateReducer), action.parent_id, action.subsidies);
  const success = (data && data.success) ? data.success : undefined;
  yield put(callbackUpdateParentSubsidies(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateParentSubsidiesSaga() {
  yield takeLatest(actionTypes.PARENT_UPDATE_SUBSIDIES, fetchUpdateParentSubsidiesSaga);
}

function* fetchMergeChildrenSaga(action: any) {
  const state = yield select();
  const data = yield call(parentApi.mergeChildren, prepareHeader(state.clientStateReducer), action.parent_id, action.child_id_from, action.child_id_to);
  const success = (data && data.success) ? data.success : undefined;
  yield put(callbackMergeChildren(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getMergeChildrenaga() {
  yield takeLatest(actionTypes.PARENT_MERGE_CHILDREN, fetchMergeChildrenSaga);
}

function* yieldParentSagas() {
  yield all([
    fork(getFindParentsSaga),
    fork(getReadParentSaga),
    fork(getReadParentStatusSaga),
    fork(getReadFamilyCentersSaga),
    fork(getAddParentSaga),
    fork(getUpdateParentSaga),
    fork(getUpdateParentSubsidiesSaga),
    fork(getMergeChildrenaga),
  ]);
}

export default yieldParentSagas;

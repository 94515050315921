import { Classroom } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function readClassroom(classroom_id: string) {
  return {
    type: actionTypes.CLASSROOM_READ_LOADING,
    classroom_id,
  };
}

export function callbackReadClassroom(classroom: Classroom, errorCode: string | undefined) {
  if (errorCode) {
    return {
      type: actionTypes.CLASSROOM_READ_ERROR,
      classroom,
      errorCode,
    };  
  }

  return {
    type: actionTypes.CLASSROOM_READ_SUCCESS,
    classroom,
  };
}

export function addClassroom(classroom: Classroom) {
  return {
    type: actionTypes.CLASSROOM_ADD_LOADING,
    classroom,
  };
}

export function callbackAddClassroom(classroom_id: string, errorCode: string | undefined) {
  if (errorCode) {
    return {
      type: actionTypes.CLASSROOM_ADD_ERROR,
      classroom_id,
      errorCode,
    };
  }

  return {
    type: actionTypes.CLASSROOM_ADD_SUCCESS,
    classroom_id,
  };
}

export function updateClassroom(classroom: Classroom) {
  return {
    type: actionTypes.CLASSROOM_UPDATE_LOADING,
    classroom,
  };
}

export function callbackUpdateClassroom(success: boolean, errorCode: string | undefined) {
  if (errorCode) {
    return {
      type: actionTypes.CLASSROOM_UPDATE_ERROR,
      success,
      errorCode,
    };  
  }

  return {
    type: actionTypes.CLASSROOM_UPDATE_SUCCESS,
    success,
  };
}

export function deleteClassroom(classroom: Classroom) {
  return {
    type: actionTypes.CLASSROOM_DELETE_LOADING,
    classroom,
  };
}

export function callbackDeleteClassroom(success: boolean, errorCode: string | undefined) {
  if (errorCode) {
    return {
      type: actionTypes.CLASSROOM_DELETE_ERROR,
      success,
      errorCode,
    };  
  }

  return {
    type: actionTypes.CLASSROOM_DELETE_SUCCESS,
    success,
  };
}

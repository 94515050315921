import actionTypes from "../infra/constants/actionTypes";

export function uploadFile(group: string, provider_id: string | undefined, center_id: string | undefined, employee_id: string | undefined, bucketType: string, fileContent: any, filename: string, filetype: string, base64: boolean, filesList?: string[]) {  
  return {
    type: actionTypes.FEEDBACK_UPLOAD_FILE,
    group,
    provider_id,
    center_id,
    employee_id,
    bucketType,
    fileContent,
    filename,
    filetype,
    base64,
    filesList,
  };
}

export function callbackUploadFile(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.FEEDBACK_UPLOAD_FILE_CALLBACK,
    success,
    errorCode,
  };
}

export function removeFile(group: string, provider_id: string | undefined, center_id: string | undefined, bucketType: string, filename: string, fileId?: string) {
  return {
    type: actionTypes.FEEDBACK_REMOVE_FILE,
    group,
    provider_id,
    center_id,
    bucketType,
    filename,
    fileId,
  };
}

export function callbackRemoveFile(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.FEEDBACK_REMOVE_FILE_CALLBACK,
    success,
    errorCode,
  };
}

export function submitFeedback(subject: string, feedback: string, toastText: string | undefined) {
  return {
    type: actionTypes.FEEDBACK_SUBMIT,
    subject,
    feedback,
    toastText,
  };
}

export function callbackSubmitFeedback(success: boolean, toastText: string | undefined) {
  return {
    type: actionTypes.FEEDBACK_SUBMIT_CALLBACK,
    success,
    toastText,
  };
}


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import searchApi from "../api/searchApi";
import {
  callbackFindEmail,
  callbackFindItem,
} from "../actions/searchActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchFindItemSaga(action: any) {
  const state = yield select();
  const data = yield call(searchApi.findItem, prepareHeader(state.clientStateReducer), action.provider_id, action.name);
  yield put(callbackFindItem(data.centers, data.parents, data.children, data.moreItems, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getFindItemSaga() {
  yield takeLatest(actionTypes.SEARCH_ITEM, fetchFindItemSaga);
}

function* fetchFindEmailSaga(action: any) {
  const state = yield select();
  const data = yield call(searchApi.findEmail, prepareHeader(state.clientStateReducer), action.email, action.includePhone, action.types);
  yield put(callbackFindEmail(data.emails, data.moreItems, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getFindEmailSaga() {
  yield takeLatest(actionTypes.SEARCH_EMAIL, fetchFindEmailSaga);
}

function* yieldSearchSagas() {
  yield all([
    fork(getFindItemSaga),
    fork(getFindEmailSaga),
  ]);
}

export default yieldSearchSagas;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import providerApi from "../api/providerApi";
import {
  callbackAddProvider,
  callbackAddProviderFile,
  callbackConfirmFeesStructure,
  callbackFindProviders,
  callbackGetNewToursCount,
  callbackGetUnreadBadge,
  callbackListCoupons,
  callbackListPartners,
  callbackListQuestions,
  callbackNoWaiveSubsidies,
  callbackReadActionItems,
  callbackReadProvider,
  callbackReadProviderAgeGroups,
  callbackReadProviderClassrooms,
  callbackReadProviderCommission,
  callbackReadProviderFamilies,
  callbackReadProviderFiles,
  callbackReadProviderPrograms,
  callbackReadProviderReport,
  callbackReadProviderSeats,
  callbackReadProviderUserAccess,
  callbackRemoveUser,
  callbackSignUpUser,
  callbackStripeAuthorizeUrl,
  callbackUpdateCenterStates,
  callbackUpdateOnboarding,
  callbackUpdateProvider,
} from "../actions/providerActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchUnreadBadgeSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.getUnreadBadge, prepareHeader(state.clientStateReducer), action.email);
  yield put(callbackGetUnreadBadge(data.badgeCount));
}

function* getUnreadBadgeSaga() {
  yield takeLatest(actionTypes.PROVIDER_UNREAD_BADGE, fetchUnreadBadgeSaga);
}

function* fetchNewToursCount(action: any) {
  const state = yield select();
  const data = yield call(providerApi.getNewToursCount, prepareHeader(state.clientStateReducer), action.provider_id);
  yield put(callbackGetNewToursCount(data.newToursCount));
}

function* getNewToursCountSaga() {
  yield takeLatest(actionTypes.PROVIDER_NEW_TOURS_COUNT, fetchNewToursCount);
}

function* fetchFindProviderSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.findProviders, prepareHeader(state.clientStateReducer), action.name, action.relationship);
  yield put(callbackFindProviders(data.providers, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getFindProvidersSaga() {
  yield takeLatest(actionTypes.PROVIDER_FIND, fetchFindProviderSaga);
}

function* fetchReadProviderSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.readProvider, prepareHeader(state.clientStateReducer), action.provider_id);
  yield put(callbackReadProvider(data.provider, data.files, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadProviderSaga() {
  yield takeLatest(actionTypes.PROVIDER_READ, fetchReadProviderSaga);
}

function* fetchReadProviderFamiliesSaga(action: any) {
  const state = yield select();
  const data = yield call(
    providerApi.readProviderFamilies,
    prepareHeader(state.clientStateReducer),
    action.provider_id
  );
  yield put(
    callbackReadProviderFamilies(
      data.families,
      data.tours,
      data.tourRequests,
      data.leads,
      data.enrollments,
      !data.success ? (data.errorCode || "APIERROR") : undefined
    )
  );
}

function* getReadProviderFamiliesSaga() {
  yield takeLatest(actionTypes.PROVIDER_READ_FAMILIES, fetchReadProviderFamiliesSaga);
}

function* fetchReadProviderClassroomsSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.readProviderClassrooms, prepareHeader(state.clientStateReducer), action.provider_id);
  yield put(callbackReadProviderClassrooms(data.classrooms, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadProviderClassroomsSaga() {
  yield takeLatest(actionTypes.PROVIDER_READ_CLASSROOMS, fetchReadProviderClassroomsSaga);
}

function* fetchReadProviderCommissionSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.readProviderCommission, prepareHeader(state.clientStateReducer), action.provider_id);
  yield put(callbackReadProviderCommission(data.commission, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadProviderCommissionSaga() {
  yield takeLatest(actionTypes.PROVIDER_READ_COMMISSION, fetchReadProviderCommissionSaga);
}

function* fetchReadProviderAgeGroupsSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.readProviderAgeGroups, prepareHeader(state.clientStateReducer), action.provider_id);
  yield put(callbackReadProviderAgeGroups(data.ageGroups, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadProviderAgeGroupsSaga() {
  yield takeLatest(actionTypes.PROVIDER_READ_AGEGROUPS, fetchReadProviderAgeGroupsSaga);
}

function* fetchReadProviderProgramsSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.readProviderPrograms, prepareHeader(state.clientStateReducer), action.provider_id);
  yield put(callbackReadProviderPrograms(data.programs, data.contracts, data.commission, data.ageGroupsByContractId, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadProviderProgramsSaga() {
  yield takeLatest(actionTypes.PROVIDER_READ_PROGRAMS, fetchReadProviderProgramsSaga);
}

function* fetchReadProviderSeatsSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.readProviderSeats, prepareHeader(state.clientStateReducer), action.provider_id, action.center_id);
  yield put(callbackReadProviderSeats(data.seats, data.classrooms, data.centers, data.spots, data.mappings,
    data.stripe, data.legupFees, data.availableSubsidies, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadProviderSeatsSaga() {
  yield takeLatest(actionTypes.PROVIDER_READ_SEATS, fetchReadProviderSeatsSaga);
}

function* fetchReadProviderReportSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.readProviderReport, prepareHeader(state.clientStateReducer), action.provider_id);
  yield put(callbackReadProviderReport(data.report, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadProviderReportSaga() {
  yield takeLatest(actionTypes.PROVIDER_READ_REPORT, fetchReadProviderReportSaga);
}

function* fetchListQuestionsSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.listQuestions, prepareHeader(state.clientStateReducer), action.provider_id, action.addCenter, action.includeInternal, action.form);
  yield put(callbackListQuestions(data.questions, data.centerCount, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getListQuestionsSaga() {
  yield takeLatest(actionTypes.PROVIDER_QUESTIONS, fetchListQuestionsSaga);
}

function* fetchReadProviderUserAccessSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.readProviderUserAccess, prepareHeader(state.clientStateReducer), action.provider_id);
  yield put(callbackReadProviderUserAccess(data.users, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadProviderUserAccessSaga() {
  yield takeLatest(actionTypes.PROVIDER_READ_USER_ACCESS, fetchReadProviderUserAccessSaga);
}

function* fetchListPartnersSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.listPartners, prepareHeader(state.clientStateReducer), action.states);
  yield put(callbackListPartners(data.partners, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getListPartnersSaga() {
  yield takeLatest(actionTypes.PROVIDER_LIST_PARTNERS, fetchListPartnersSaga);
}

function* fetchListCouponsSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.listCoupons, prepareHeader(state.clientStateReducer), action.provider_id);
  yield put(callbackListCoupons(data.coupons, data.waive_subsidies, data.subsidies, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getListCouponsSaga() {
  yield takeLatest(actionTypes.PROVIDER_LIST_COUPONS, fetchListCouponsSaga);
}

function* fetchNoWaiveSubsidiesSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.updateWaiveSubsidies, prepareHeader(state.clientStateReducer), action.provider_id, action.waive_subsidies);
  yield put(callbackNoWaiveSubsidies(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getNoWaiveSubsidiesSaga() {
  yield takeLatest(actionTypes.PROVIDER_NO_WAIVE_SUBSIDIES, fetchNoWaiveSubsidiesSaga);
}

function* fetchAddProviderSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.addProvider, prepareHeader(state.clientStateReducer), action.provider);
  const provider_id = (data && data.provider_id) ? data.provider_id : undefined;
  yield put(callbackAddProvider(provider_id, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddProviderSaga() {
  yield takeLatest(actionTypes.PROVIDER_ADD, fetchAddProviderSaga);
}

function* fetchUpdateProviderSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.updateProvider, prepareHeader(state.clientStateReducer), action.provider);
  const success = !!(data && data.success);
  yield put(callbackUpdateProvider(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateProviderSaga() {
  yield takeLatest(actionTypes.PROVIDER_UPDATE, fetchUpdateProviderSaga);
}

function* fetchUpdateOnboardingSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.updateProviderOnboarding, prepareHeader(state.clientStateReducer), action.provider_id, action.state, action.reason);
  const success = !!(data && data.success);
  yield put(callbackUpdateOnboarding(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateOnboardingSaga() {
  yield takeLatest(actionTypes.PROVIDER_UPDATE_ONBOARDING, fetchUpdateOnboardingSaga);
}

function* fetchUpdateCenterStatesSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.updateCenterStates, prepareHeader(state.clientStateReducer), action.provider_id, action.centerStates);
  const success = !!(data && data.success);
  yield put(callbackUpdateCenterStates(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateCenterStatesSaga() {
  yield takeLatest(actionTypes.PROVIDER_UPDATE_CENTER_STATES, fetchUpdateCenterStatesSaga);
}

function* fetchSignupUserSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.signUpUserAccount, prepareHeader(state.clientStateReducer), action.provider_id,
    action.email, action.phone_number, action.first_name, action.last_name, action.sendEmail, action.notification);
  const success = !!(data && data.success);
  yield put(callbackSignUpUser(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSignupUserSaga() {
  yield takeLatest(actionTypes.PROVIDER_SIGNUP_USER, fetchSignupUserSaga);
}

function* fetchRemoveUserSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.removeUserAccount, prepareHeader(state.clientStateReducer), action.provider_id, action.email, action.deleteUser);
  const success = !!(data && data.success);
  yield put(callbackRemoveUser(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getRemoveUserSaga() {
  yield takeLatest(actionTypes.PROVIDER_REMOVE_USER, fetchRemoveUserSaga);
}

function* fetchStripeAuthorizeUrl(action: any) {
  const state = yield select();
  const data = yield call(providerApi.getStripeUrl, prepareHeader(state.clientStateReducer), action.provider_id);
  yield put(callbackStripeAuthorizeUrl(data.urls, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getStripeAuthorizeUrl() {
  yield takeLatest(actionTypes.PROVIDER_STRIPE_AUTHORIZE, fetchStripeAuthorizeUrl);
}

function* fetchReadActionItemsSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.readActionItems, prepareHeader(state.clientStateReducer), action.provider_id, action.center_id);
  const success = !!(data && data.success);
  yield put(callbackReadActionItems(data, success, !success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadActionItemsSaga() {
  yield takeLatest(actionTypes.PROVIDER_READ_ACTION_ITEMS, fetchReadActionItemsSaga);
}

function* fetchReadProviderFilesSaga(action: any) {
  const state = yield select();
  const data = yield call(providerApi.readFiles, prepareHeader(state.clientStateReducer), action.provider_id);
  const success = !!(data && data.success);
  yield put(callbackReadProviderFiles(data.files, success, !success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadProviderFilesSaga() {
  yield takeLatest(actionTypes.PROVIDER_READ_FILES, fetchReadProviderFilesSaga);
}

function* fetchAddProviderFileSage(action: any) {
  const state = yield select();
  const data = yield call(providerApi.addFile, prepareHeader(state.clientStateReducer), action.provider_id, action.file);
  const success = !!(data && data.success);
  yield put(callbackAddProviderFile(success, !success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddProviderFileSaga() {
  yield takeLatest(actionTypes.PROVIDER_ADD_FILE, fetchAddProviderFileSage);
}

function* fetch(action: any) {
}

function* getIncrementUnreadMessagesSaga() {
  yield takeLatest(actionTypes.PROVIDER_INCREMENT_UNREAD_MESSAGES, fetch);
}

function* fetchConfirmFeesStructure(action: any) {
  const state = yield select();
  const data = yield call(providerApi.confirmFeesStructure, prepareHeader(state.clientStateReducer), action.provider_id);
  const success = !!(data && data.success);
  yield put(callbackConfirmFeesStructure(success));
}

function* confirmFeesStructureSaga() {
  yield takeLatest(actionTypes.PROVIDER_CONFIRM_FEES_STRUCTURE, fetchConfirmFeesStructure);
}

function* yieldProviderSagas() {
  yield all([
    fork(getUnreadBadgeSaga),
    fork(getFindProvidersSaga),
    fork(getNoWaiveSubsidiesSaga),
    fork(getReadProviderSaga),
    fork(getReadProviderFamiliesSaga),
    fork(getReadProviderClassroomsSaga),
    fork(getReadProviderAgeGroupsSaga),
    fork(getReadProviderProgramsSaga),
    fork(getReadProviderSeatsSaga),
    fork(getReadProviderReportSaga),
    fork(getListQuestionsSaga),
    fork(getReadProviderUserAccessSaga),
    fork(getListPartnersSaga),
    fork(getListCouponsSaga),
    fork(getAddProviderSaga),
    fork(getUpdateProviderSaga),
    fork(getUpdateOnboardingSaga),
    fork(getUpdateCenterStatesSaga),
    fork(getSignupUserSaga),
    fork(getRemoveUserSaga),
    fork(getStripeAuthorizeUrl),
    fork(getReadActionItemsSaga),
    fork(getReadProviderFilesSaga),
    fork(getAddProviderFileSaga),
    fork(getIncrementUnreadMessagesSaga),
    fork(getReadProviderCommissionSaga),
    fork(confirmFeesStructureSaga),
    fork(getNewToursCountSaga),
  ]);
}

export default yieldProviderSagas;

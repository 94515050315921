import { Contract } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultContractState {
  currentContract: Contract | undefined;
  addSuccess: boolean | undefined;
  addedContractIds: string[] | undefined;
  updateSuccess: boolean | undefined;
  deleteSuccess: boolean | undefined;
  errorCode: string | undefined;
}

const defaultProductState: IDefaultContractState = {
  currentContract: null,
  addSuccess: undefined,
  addedContractIds: undefined,
  updateSuccess: undefined,
  deleteSuccess: undefined,
  errorCode: undefined,
};

function reduceReadContractCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }
  const currentContract: Contract = new Contract();

  currentContract.buildFromJSON(action.contract);

  return { ...state, currentContract };
}

function reduceAddContract(state: any, action: any) {
  return { ...state, addSuccess: undefined };
}

function reduceAddContractCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addSuccess: false, errorCode: action.errorCode };
  }

  const addedContractIds: string[] = Object.assign([], action.ids);

  return { ...state, addedContractIds, addSuccess: action.success };
}

function reduceUpdateContract(state: any, action: any) {
  return { ...state, updateSuccess: undefined };
}

function reduceUpdateContractCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSuccess: action.success };
}

function reduceDeleteContract(state: any, action: any) {
  return { ...state, deleteSuccess: undefined };
}

function reduceDeleteContractCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, deleteSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, deleteSuccess: action.success };
}

function reduceContract(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.CONTRACT_READ_CALLBACK:
      return reduceReadContractCallback(state, action);
    case actionTypes.CONTRACT_ADD:
      return reduceAddContract(state, action);
    case actionTypes.CONTRACT_ADD_CALLBACK:
      return reduceAddContractCallback(state, action);
    case actionTypes.CONTRACT_UPDATE:
      return reduceUpdateContract(state, action);
    case actionTypes.CONTRACT_UPDATE_CALLBACK:
      return reduceUpdateContractCallback(state, action);
    case actionTypes.CONTRACT_DELETE:
      return reduceDeleteContract(state, action);
    case actionTypes.CONTRACT_DELETE_CALLBACK:
      return reduceDeleteContractCallback(state, action);
    default:
      return state;
  }
}

export default reduceContract;

import querystring from "querystring";

import { Contract } from "@legup/legup-model";

const contractApi = {
  readContract(headers: Headers, contract_id: string): Promise<Contract | undefined> {
    return fetch(`/api/contract/${contract_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addContracts(headers: Headers, provider_id: string, contracts: Contract[], ageGroupIds: string[]): Promise<string | undefined> {
    const queryParams = querystring.stringify({ provider: provider_id });

    return fetch(`/api/contract?${queryParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify({ contracts, ageGroupIds }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateContract(headers: Headers, contract: Contract, ageGroupIds: string[]): Promise<boolean> {
    return fetch(`/api/contract/${contract.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify({ contract, ageGroupIds }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  deleteContract(headers: Headers, contract_id: string): Promise<boolean> {
    return fetch(`/api/contract/${contract_id}`, {
      headers,
      method: "DELETE",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default contractApi;

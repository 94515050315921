
import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import linkApi from "../api/linkApi";
import {
  callbackActionLink,
  callbackValidateLink,
} from "../actions/linkActions";
import actionTypes from "../infra/constants/actionTypes";

function* fetchValidateLinkSaga(action: any) {
  const data = yield call(linkApi.validateLink, action.code, action.email, action.token);
  yield put(callbackValidateLink(data.success, data.action, data.payload, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getValidateLinkSaga() {
  yield takeLatest(actionTypes.LINK_VALIDATE, fetchValidateLinkSaga);
}

function* fetchActionLinkSaga(action: any) {
  const data = yield call(linkApi.actionLink, action.code, action.email, action.token, action.action);
  yield put(callbackActionLink(data.success, data.active, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getActionLinkSaga() {
  yield takeLatest(actionTypes.LINK_ACTION, fetchActionLinkSaga);
}

function* yieldLinkSagas() {
  yield all([
    fork(getValidateLinkSaga),
    fork(getActionLinkSaga),
  ]);
}

export default yieldLinkSagas;

import { Center, Child, Parent } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface SearchResults {
  centers: Center[]
  parents: Parent[]
  children: Child[]
  moreItems: boolean | undefined
}

interface IDefaultSearchState {
  searchResults: SearchResults;
  emails: string[] | undefined;
  moreEmails: boolean | undefined;
  errorCode: string | undefined;
}
const defaultProductState: IDefaultSearchState = {
  searchResults: undefined,
  emails: undefined,
  moreEmails: undefined,
  errorCode: undefined,
};

function reduceSearchItemCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const searchResults: SearchResults = {
    centers: [],
    parents: [],
    children: [],
    moreItems: action.moreItems,
  };

  action.centers.forEach(c => {
    const center = new Center();
    center.buildFromJSON(c);
    searchResults.centers.push(center);
  });
  action.parents.forEach(p => {
    const parent = new Parent();
    parent.buildFromJSON(p);
    searchResults.parents.push(parent);
  });
  action.children.forEach(c => {
    const child = new Child();
    child.buildFromJSON(c);
    searchResults.children.push(child);
  });

  return { ...state, searchResults };
}

function reduceSearchEmailCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, emails: action.emails || [], moreEmails: action.moreItems };
}

function reduceLink(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.SEARCH_ITEM_CALLBACK:
      return reduceSearchItemCallback(state, action);
    case actionTypes.SEARCH_EMAIL_CALLBACK:
      return reduceSearchEmailCallback(state, action);
    default:
      return state;
  }
}

export default reduceLink;

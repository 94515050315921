import { Parent, Tour, WaitlistSpot } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function validateLink(code: string, email: string | undefined, token: string | undefined) {
  return {
    type: actionTypes.LINK_VALIDATE,
    code,
    email,
    token,
  };
}

export function callbackValidateLink(success: boolean, action: string, payload: any, errorCode: string | undefined) {
  return {
    type: actionTypes.LINK_VALIDATE_CALLBACK,
    success,
    action,
    payload,
    errorCode,
  };
}

export function actionLink(code: string, email: string, token: string | undefined, action: any) {
  return {
    type: actionTypes.LINK_ACTION,
    code,
    email,
    token,
    action,
  };
}

export function callbackActionLink(success: boolean, active: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.LINK_ACTION_CALLBACK,
    success,
    active,
    errorCode,
  };
}

import querystring from "querystring";

import { Program } from "@legup/legup-model";

const programApi = {
  addPrograms(headers: Headers, programs: Program[]): Promise<string | undefined> {
    const queryParams = querystring.stringify({ provider: programs[0].getProviderId() });

    return fetch(`/api/program?${queryParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify(programs),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateProgram(headers: Headers, program: Program): Promise<boolean> {
    return fetch(`/api/program/${program.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify(program),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  copyProgram(headers: Headers, program_id: string, program_prefix: string | undefined, program_suffix: string | undefined, contract_prefix: string | undefined, contract_suffix: string | undefined): Promise<boolean> {
    return fetch(`/api/program/${program_id}/copy`, {
      headers,
      method: "POST",
      body: JSON.stringify({ program_prefix, program_suffix, contract_prefix, contract_suffix }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  deleteProgram(headers: Headers, program_id: string): Promise<boolean> {
    return fetch(`/api/program/${program_id}`, {
      headers,
      method: "DELETE",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default programApi;

import { Center, Child, Parent, Seat, SeatAnalysis, SeatHistory, Tour, WaitlistSpot } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface ActionableSeats {
  seats: Seat[],
  spots: WaitlistSpot[],
  mappings: any,
  stripe: string,
  legupFees: boolean,
  tourRequests: number,
}

interface Directory {
  seats: Seat[],
  centers: Center[],
  centerBasics: { [s: string]: Array<{ profile_basic_id: string, value: string }>},
  ads: Array<{center_id: string, ad_text: string, ad_html: string}>,
  images: Array<{center_id: string, gallery_result_url: string}>,
  alternateCare: { [s: string]: string[] },
}

interface CenterBasic {
  profile_basic_id: string,
  title: string,
  icon_url: string,
  choices: string[],  
}
interface AdminStats {
  providers: Array<{provider_id: string, name: string, market: string, seats: {open: number, filled: number, parentAction: number, providerAction: number}}>,
  history: any;
  markets: any;
  reports: any;
  preferredDates: any;
}

interface Reminder {
  reminder: {
    reminder_id: string,
    reminder_time: Date,
    reminder: "provider" | "parent",
  },
  seat: Seat,
  center: Center,
  child: Child,
  parent: Parent,
  parent2?: Parent,
  tour?: Tour,
}

interface IDefaultSeatState {
  currentSeat: Seat | undefined;
  seatAnalysis: SeatAnalysis | undefined;
  seatHistory: SeatHistory[] | undefined;
  addSuccess: boolean | undefined;
  updateSuccess: boolean | undefined;
  offerSuccess: boolean | undefined;
  offerFamilySuccess: boolean | undefined;
  updateStateSuccess: boolean | undefined;
  removeSuccess: boolean | undefined;
  pauseSuccess: boolean | undefined;
  offerLegupFamiliesSuccess: boolean | undefined;
  splitSuccess: boolean | undefined;
  directory: Directory | undefined;
  centerBasics: CenterBasic[] | undefined;
  seatReminders: Reminder[] | undefined;
  updateReminderSuccess: boolean | undefined;
  adminStats: AdminStats | undefined;
  actionableSeats: ActionableSeats | undefined;
  addedSeat: string | undefined;
  currentSpot: WaitlistSpot | undefined;
  errorCode: string | undefined;
}

const defaultProductState: IDefaultSeatState = {
  currentSeat: undefined,
  seatAnalysis: undefined,
  seatHistory: undefined,
  addSuccess: null,
  updateSuccess: undefined,
  offerSuccess: undefined,
  offerFamilySuccess: undefined,
  updateStateSuccess: undefined,
  removeSuccess: undefined,
  pauseSuccess: undefined,
  offerLegupFamiliesSuccess: undefined,
  splitSuccess: undefined,
  directory: undefined,
  centerBasics: undefined,
  seatReminders: undefined,
  updateReminderSuccess: undefined,
  adminStats: undefined,
  actionableSeats: undefined,
  addedSeat: undefined,
  currentSpot: undefined,
  errorCode: undefined,
};

function reduceReadSeatCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const currentSeat = new Seat();
  currentSeat.buildFromJSON(action.seat);

  return { ...state, currentSeat };
}

function reduceAddSeat(state: any, action: any) {
  return { ...state, addSuccess: undefined, seatAnalysis: undefined, errorCode: undefined };
}

function reduceAddSeatCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, addSuccess: action.success, seatAnalysis: action.seatAnalysis, addedSeat: action.seat_id };
}

function reduceUpdateSeat(state: any, action: any) {
  return { ...state, updateSuccess: undefined, errorCode: undefined };
}

function reduceUpdateSeatCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSuccess: action.success };
}

function reduceOfferSeat(state: any, action: any) {
  return { ...state, offerSuccess: undefined, errorCode: undefined };
}

function reduceOfferSeatCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, offerSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, offerSuccess: action.success };
}

function reduceOfferFamilySeatCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, offerFamilySuccess: false, errorCode: action.errorCode };
  }

  return { ...state, offerFamilySuccess: action.success };
}

function reduceOfferFamilySeatReset(state: any) {
  return { ...state, offerFamilySuccess: undefined };
}

function reduceUpdateSeatState(state: any, action: any) {
  return { ...state, updateStateSuccess: undefined, errorCode: undefined };
}

function reduceUpdateSeatStateCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateStateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateStateSuccess: action.success };
}

function reduceRemoveSeat(state: any, action: any) {
  return { ...state, removeSuccess: undefined, errorCode: undefined };
}

function reduceRemoveSeatCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, removeSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, removeSuccess: action.success };
}

function reducePauseSeat(state: any, action: any) {
  return { ...state, pauseSuccess: undefined, errorCode: undefined };
}

function reducePauseSeatCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, pauseSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, pauseSuccess: action.success };
}

function reduceOfferLegupFamilies(state: any, action: any) {
  return { ...state, offerLegupFamiliesSuccess: undefined, errorCode: undefined };
}

function reduceOfferLegupFamiliesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, offerLegupFamiliesSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, offerLegupFamiliesSuccess: action.success };
}

function reduceSplitSeat(state: any, action: any) {
  return { ...state, splitSuccess: undefined, errorCode: undefined };
}

function reduceSplitSeatCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, splitSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, splitSuccess: action.success };
}

function reduceSeatsSummaryCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const directory: Directory = { seats: [], centers: [], ads: [], images: [], centerBasics: {}, alternateCare: {} };
  action.directory.centers.forEach(c => {
    const center = new Center();
    center.buildFromJSON(c);
    directory.centers.push(center);
  });
  action.directory.seats.forEach(s => {
    const seat = new Seat();
    seat.buildFromJSON(s);
    directory.seats.push(seat);
  });
  directory.ads = [].concat(action.directory.ads || []);
  directory.images = [].concat(action.directory.images || []);
  directory.centerBasics = action.directory.centerBasics || {};
  directory.alternateCare = action.directory.alternateCare || {};

  const centerBasics: CenterBasic[] = action.basics || [];

  return { ...state, directory, centerBasics };
}

function reduceSeatRemindersCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const seatReminders: Reminder[] = [];
  action.reminders.forEach(r => {
    const center = new Center();
    center.buildFromJSON(r.center);
    const seat = new Seat();
    seat.buildFromJSON(r.seat);
    const child = new Child();
    child.buildFromJSON(r.child);
    const parent = new Parent();
    parent.buildFromJSON(r.parent);
    let parent2: Parent | undefined;
    if (r.parent2) {
      parent2 = new Parent();
      parent2.buildFromJSON(r.parent2);
    }
    let tour: Tour | undefined;
    if (r.tour) {
      tour = new Tour();
      tour.buildFromJSON(r.tour);
    }

    seatReminders.push({
      reminder: {
        reminder_id: r.reminder.reminder_id,
        reminder_time: new Date(r.reminder.reminder_time),
        reminder: r.reminder.reminder,
      },
      center,
      seat,
      child,
      parent,
      parent2,
      tour,
    });
  });

  return { ...state, seatReminders };
}

function reduceUpdateSeatReminder(state: any, action: any) {
  return { ...state, updateReminderSuccess: undefined, errorCode: undefined };
}

function reduceUpdateSeatReminderCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateReminderSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateReminderSuccess: action.success };
}

function reduceSeatsAdminStatsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const adminStats: AdminStats = { markets: [], history: undefined, reports: undefined, providers: [], preferredDates: {} };
  adminStats.providers = (action.stats.providers || []).slice();
  adminStats.markets = { ...action.stats.markets };
  adminStats.history = { ...action.stats.history };
  adminStats.reports = { ...action.stats.reports };
  adminStats.preferredDates = { ...action.stats.preferredDates };

  return { ...state, adminStats };
}

function reduceGetActionableSeatsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const actionableSeats: ActionableSeats = {
    seats: [],
    spots: [],
    mappings: [],
    stripe: action.stripe,
    legupFees: action.legupFees,
    tourRequests: action.tourRequests,
  };
  action.seats.forEach(s => {
    const seat = new Seat();
    seat.buildFromJSON(s);
    actionableSeats.seats.push(seat);
  });
  action.spots.forEach(s => {
    const spot = new WaitlistSpot();
    spot.buildFromJSON(s);
    actionableSeats.spots.push(spot);
  });
  action.mappings.forEach(m => {
    actionableSeats.mappings.push(m);
  });

  return { ...state, actionableSeats };
}

function reduceReadSpotCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const currentSpot: WaitlistSpot = new WaitlistSpot();
  currentSpot.buildFromJSON(action.spot);

  return { ...state, currentSpot };
}

function reduceReadNotesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, seatHistory: action.history };
}

function reduceReadNearHitsCallback(state: any, action: any) {
  if (action.errorCOde) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, seatNearHits: action.nearhits };
}

function reduceSeats(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.SEAT_READ_CALLBACK:
      return reduceReadSeatCallback(state, action);
    case actionTypes.SEAT_ADD:
      return reduceAddSeat(state, action);
    case actionTypes.SEAT_ADD_CALLBACK:
      return reduceAddSeatCallback(state, action);
    case actionTypes.SEAT_UPDATE:
      return reduceUpdateSeat(state, action);
    case actionTypes.SEAT_UPDATE_CALLBACK:
      return reduceUpdateSeatCallback(state, action);
    case actionTypes.SEAT_OFFER:
      return reduceOfferSeat(state, action);
    case actionTypes.SEAT_OFFER_CALLBACK:
      return reduceOfferSeatCallback(state, action);
    case actionTypes.SEAT_OFFER_FAMILY_CALLBACK:
      return reduceOfferFamilySeatCallback(state, action);
    case actionTypes.SEAT_OFFER_FAMILY_RESET:
      return reduceOfferFamilySeatReset(state);
    case actionTypes.SEAT_UPDATE_STATES:
      return reduceUpdateSeatState(state, action);
    case actionTypes.SEAT_UPDATE_STATES_CALLBACK:
      return reduceUpdateSeatStateCallback(state, action);
    case actionTypes.SEAT_REMOVE:
      return reduceRemoveSeat(state, action);
    case actionTypes.SEAT_REMOVE_CALLBACK:
      return reduceRemoveSeatCallback(state, action);
    case actionTypes.SEAT_PAUSE:
      return reducePauseSeat(state, action);
    case actionTypes.SEAT_PAUSE_CALLBACK:
      return reducePauseSeatCallback(state, action);
    case actionTypes.SEAT_OFFER_LEGUP_FAMILIES:
      return reduceOfferLegupFamilies(state, action);
    case actionTypes.SEAT_OFFER_LEGUP_FAMILIES_CALLBACK:
      return reduceOfferLegupFamiliesCallback(state, action);
    case actionTypes.SEAT_SPLIT:
      return reduceSplitSeat(state, action);
    case actionTypes.SEAT_SPLIT_CALLBACK:
      return reduceSplitSeatCallback(state, action);
    case actionTypes.SEAT_SUMMARY_CALLBACK:
      return reduceSeatsSummaryCallback(state, action);
    case actionTypes.SEAT_LIST_REMINDERS_CALLBACK:
      return reduceSeatRemindersCallback(state, action);
    case actionTypes.SEAT_UPDATE_REMINDER:
      return reduceUpdateSeatReminder(state, action);
    case actionTypes.SEAT_UPDATE_REMINDER_CALLBACK:
      return reduceUpdateSeatReminderCallback(state, action);
    case actionTypes.SEAT_ADMIN_STATS_CALLBACK:
      return reduceSeatsAdminStatsCallback(state, action);
    case actionTypes.SEAT_READ_ACTIONABLE_CALLBACK:
      return reduceGetActionableSeatsCallback(state, action);
    case actionTypes.SEAT_READ_SPOT_CALLBACK:
      return reduceReadSpotCallback(state, action);
    case actionTypes.SEAT_READ_NOTES_CALLBACK:
      return reduceReadNotesCallback(state, action);
    case actionTypes.SEAT_READ_NEAR_HITS_CALLBACK:
      return reduceReadNearHitsCallback(state, action);
    default:
      return state;
  }
}

export default reduceSeats;

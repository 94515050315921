
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import partnerApi from "../api/partnerApi";
import {
  callbackFindPartners,
  callbackReadPartner,
} from "../actions/partnerActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchFindPartnersSaga(action: any) {
  const state = yield select();
  const data = yield call(partnerApi.findPartners, prepareHeader(state.clientStateReducer), action.name);
  yield put(callbackFindPartners(data.partners, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getFindPartnersSaga() {
  yield takeLatest(actionTypes.PARTNER_FIND, fetchFindPartnersSaga);
}

function* fetchReadPartnerSaga(action: any) {
  const state = yield select();
  const data = yield call(partnerApi.readPartner, prepareHeader(state.clientStateReducer), action.partner_id);
  yield put(callbackReadPartner(data.partner, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadPartnerSaga() {
  yield takeLatest(actionTypes.PARTNER_READ, fetchReadPartnerSaga);
}

function* yieldPartnerSagas() {
  yield all([
    fork(getFindPartnersSaga),
    fork(getReadPartnerSaga),
  ]);
}

export default yieldPartnerSagas;

import querystring from "querystring";

import { Child } from "@legup/legup-model";

const childApi = {
  readChild(headers: Headers, child_id: string): Promise<Child> {
    return fetch(`/api/child/${child_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readChildWaitlists(headers: Headers, provider_id: string | undefined, child_id: string): Promise<any> {
    let url;
    if (provider_id) {
      const searchParams = querystring.stringify({ provider_id, includeAllStates: true });
      url = `/api/child/${child_id}/waitlists?${searchParams}`;
    }
    else {
      url = `/api/child/${child_id}/waitlists?includeAllStates=true`;
    }

    return fetch(url, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readChildWaitlistPosition(headers: Headers, child_id: string, list_id: string): Promise<any> {
    const searchParams = querystring.stringify({ list: list_id });

    return fetch(`/api/child/${child_id}/waitlist/position?${searchParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readChildSeats(headers: Headers, provider_id: string | undefined, child_id: string): Promise<any> {
    let url;
    if (provider_id) {
      const searchParams = querystring.stringify({ provider_id });
      url = `/api/child/${child_id}/seats?${searchParams}`;
    }
    else {
      url = `/api/child/${child_id}/seats`;
    }

    return fetch(url, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readChildTours(headers: Headers, provider_id: string | undefined, child_id: string): Promise<any> {
    let url;
    if (provider_id) {
      const searchParams = querystring.stringify({ provider_id });
      url = `/api/child/${child_id}/tours?${searchParams}`;
    }
    else {
      url = `/api/child/${child_id}/tours`;
    }

    return fetch(url, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addChild(headers: Headers, parent_id: string, child: Child) {
    const queryParams = querystring.stringify({ parent: parent_id });

    return fetch(`/api/child?${queryParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify(child),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readChildNotes(headers: Headers, child_id: string) {
    return fetch(`/api/child/${child_id}/notes`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateChild(headers: Headers, child: Child) {
    return fetch(`/api/child/${child.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify(child),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default childApi;

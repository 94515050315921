import { Center, Child, Parent, Payment } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface Receipt {
  payment: Payment;
  centers: Center[];
  child: Child;
  parent: Parent;
  parent2?: Parent;
}

interface IDefaultPaymentState {
  currentPayment: Payment | undefined;
  currentToken: string | undefined;
  receipts: Receipt[] | undefined;
  saveReceiptSuccess: boolean | undefined;
  errorCode: string | undefined;
}

const defaultProductState: IDefaultPaymentState = {
  currentPayment: undefined,
  currentToken: undefined,
  receipts: undefined,
  saveReceiptSuccess: undefined,
  errorCode: undefined,
};

function reduceReadPaymentCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }
  const currentPayment: Payment = new Payment();

  currentPayment.buildFromJSON(action.payment);

  return { ...state, currentPayment };
}

function reduceReadReceiptsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const receipts: Receipt[] = [];
  action.payments.forEach(r => {
    const payment = new Payment();
    payment.buildFromJSON(r.payment);
    const child = new Child();
    child.buildFromJSON(r.child);
    const parent = new Parent();
    parent.buildFromJSON(r.parent);
    let parent2;

    if (r.parent2) {
      parent2 = new Parent();
      parent2.buildFromJSON(r.parent2);
    }
    const centers: Center[] = [];
    (r.centers || []).forEach(c => {
      const center = new Center();
      center.buildFromJSON(c);
      centers.push(center);
    });

    receipts.push({
      payment,
      centers,
      child,
      parent,
      parent2,
    });
  });

  return { ...state, receipts };
}

function reduceSaveReceipt(state: any, action: any) {
  return { ...state, saveReceiptSuccess: undefined, errorCode: undefined };
}

function reduceSaveReceiptCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, saveReceiptSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, saveReceiptSuccess: action.success, errorCode: action.errorCode };
}

function reducePayments(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.PAYMENT_READ_CALLBACK:
      return reduceReadPaymentCallback(state, action);
    case actionTypes.PAYMENT_READ_RECEIPTS_CALLBACK:
      return reduceReadReceiptsCallback(state, action);
    case actionTypes.PAYMENT_SAVE_RECEIPT:
      return reduceSaveReceipt(state, action);
    case actionTypes.PAYMENT_SAVE_RECEIPT_CALLBACK:
      return reduceSaveReceiptCallback(state, action);
    default:
      return state;
  }
}

export default reducePayments;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import feedbackApi from "../api/feedbackApi";
import {
  callbackRemoveFile,
  callbackSubmitFeedback,
  callbackUploadFile,
} from "../actions/feedbackActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchUploadFileSaga(action: any) {
  const state = yield select();
  const data = yield call(feedbackApi.uploadFile, prepareHeader(state.clientStateReducer), action.group,
    action.provider_id, action.center_id, action.employee_id, action.bucketType, action.fileContent, action.filename, action.filetype, action.base64, action.filesList);
  yield put(callbackUploadFile(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUploadFileSaga() {
  yield takeLatest(actionTypes.FEEDBACK_UPLOAD_FILE, fetchUploadFileSaga);
}

function* fetchRemoveFileSaga(action: any) {
  const state = yield select();
  const data = yield call(feedbackApi.removeFile, prepareHeader(state.clientStateReducer), action.group,
    action.provider_id, action.center_id, action.bucketType, action.filename, action.fileId);
  yield put(callbackRemoveFile(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getRemoveFileSaga() {
  yield takeLatest(actionTypes.FEEDBACK_REMOVE_FILE, fetchRemoveFileSaga);
}

function* fetchSubmitFeedbackSaga(action: any) {
  const state = yield select();
  const data = yield call(feedbackApi.submitFeedback, prepareHeader(state.clientStateReducer), action.subject, action.feedback);
  const success = !!(data && data.success);
  yield put(callbackSubmitFeedback(success, action.toastText));
}

function* getSubmitFeedbackSaga() {
  yield takeLatest(actionTypes.FEEDBACK_SUBMIT, fetchSubmitFeedbackSaga);
}

function* yieldFeedbackSagas() {
  yield all([
    fork(getUploadFileSaga),
    fork(getRemoveFileSaga),
    fork(getSubmitFeedbackSaga),
  ]);
}

export default yieldFeedbackSagas;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import subsidyApi from "../api/subsidyApi";
import {
  callbackListSubsidies,
} from "../actions/subsidyActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchListSubsidiesSaga(action: any) {
  const state = yield select();
  const data = yield call(subsidyApi.listSubsidies, prepareHeader(state.clientStateReducer), action.id, action.itemType);
  yield put(callbackListSubsidies(data.success, data.subsidies, data.enabledSubsidies, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getListSubsidiesSaga() {
  yield takeLatest(actionTypes.LIST_SUBSIDIES, fetchListSubsidiesSaga);
}

function* yieldSubsidySagas() {
  yield all([
    fork(getListSubsidiesSaga),
  ]);
}

export default yieldSubsidySagas;

import querystring from "querystring";

import { Child, Contract, Parent, Program, Seat, Tour } from "@legup/legup-model";

const seatApi = {
  readSeat(headers: Headers, seat_id: string): Promise<any> {
    return fetch(`/api/seat/${seat_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addSeat(headers: Headers, classroom_id: string, seat: Seat): Promise<boolean | undefined> {
    const queryParams = querystring.stringify({ classroom: classroom_id });

    return fetch(`/api/seat?${queryParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify(seat),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateSeat(headers: Headers, seat: Seat): Promise<boolean | undefined> {
    return fetch(`/api/seat/${seat.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify(seat),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  pauseSeat(headers: Headers, seat_id: string, pause: boolean, paused_until: Date | undefined): Promise<boolean | undefined> {
    return fetch(`/api/seat/${seat_id}/pause`, {
      headers,
      method: "POST",
      body: JSON.stringify({ pause, paused_until }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  offerLegupFamilies(headers: Headers, seat_id: string, offer: boolean): Promise<boolean | undefined> {
    return fetch(`/api/seat/${seat_id}/legupoffer?offer=${offer ? 1 : 0}`, {
      headers,
      method: "POST",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  splitSeat(headers: Headers, seat_id: string, schedules: Array<{program: Program, contracts: Contract[]}>): Promise<boolean | undefined> {
    return fetch(`/api/seat/${seat_id}/split`, {
      headers,
      method: "POST",
      body: JSON.stringify(schedules),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  offerSeat(headers: Headers, seat_id: string, child_id: string, legupFamily: boolean): Promise<boolean | undefined> {
    const queryParams = querystring.stringify({ child: child_id, legupFamily: legupFamily ? 1 : 0 });

    return fetch(`/api/seat/${seat_id}/offer?${queryParams}`, {
      headers,
      method: "POST",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  offerFamilySeat(headers: Headers, seat_id: string, contract_id: string, tour: Tour | undefined, state: string,
    notes: string | undefined, child: Child, parent: Parent, start_date: Date | undefined, expire_date: Date | undefined,
    reminder_date: Date | undefined, discount_percentage: boolean, discount_amount: number | undefined,
    discount_refundable_deposit: number | undefined, discount_reason: string | undefined): Promise<boolean | undefined> {
    return fetch(`/api/seat/${seat_id}/offerfamily`, {
      headers,
      method: "POST",
      body: JSON.stringify({
        child,
        parent,
        state,
        notes,
        contract: contract_id,
        tour,
        start: start_date,
        expire: expire_date,
        reminder: reminder_date,
        discount_percentage,
        discount_amount,
        discount_refundable_deposit,
        discount_reason,
      }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateSeatStates(headers: Headers, updates: any[], mailProvider: boolean, mailForm: boolean): Promise<boolean | undefined> {
    let url: string;
    const params: any = {};

    if (mailProvider) {
      params.mailProvider = 1;
    }
    if (mailForm) {
      params.mailForm = 1;
    }

    if (Object.keys(params).length) {
      const queryParams = querystring.stringify(params);
      url = `/api/seat/updatestates?${queryParams}`;
    }
    else {
      url = "/api/seat/updatestates";
    }

    return fetch(url, {
      headers,
      method: "POST",
      body: JSON.stringify({ updates }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  removeSeat(headers: Headers, seat_id: string, reason: string): Promise<boolean | undefined> {
    return fetch(`/api/seat/${seat_id}`, {
      headers,
      method: "DELETE",
      body: JSON.stringify({ reason }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readSpot(headers: Headers, seat_id: string): Promise<any> {
    return fetch(`/api/seat/${seat_id}/spot`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readNotes(headers: Headers, seat_id: string): Promise<any> {
    return fetch(`/api/seat/${seat_id}/notes`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readNearHits(headers: Headers, seat_id: string): Promise<any> {
    return fetch(`/api/seat/${seat_id}/nearhits`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  getActionableSeats(headers: Headers, provider_id: string, center_id: string, admin: boolean) {
    const queryParams = querystring.stringify({ provider: provider_id, center: center_id, admin: (admin ? 1 : undefined) });

    return fetch(`/api/seat/actionable?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  seatsSummary(headers: Headers, partner_id: string | undefined, center_id: string | undefined, latitude: number | undefined, longitude: number | undefined): Promise<any> {
    const queryParams = querystring.stringify({ partner: partner_id, center: center_id, latitude, longitude });

    return fetch(`/api/seat/directory?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  seatReminders(headers: Headers): Promise<any> {
    return fetch("/api/seat/reminder", {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateSeatReminder(headers: Headers, reminder_id: string, reminder_time: Date): Promise<any> {
    return fetch(`/api/seat/reminder/${reminder_id}`, {
      headers,
      method: "POST",
      body: JSON.stringify({ reminder_time }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  seatsAdminStats(headers: Headers): Promise<any> {
    return fetch("/api/seat/admin/stats", {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default seatApi;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import centerApi from "../api/centerApi";
import {
  callbackAddCenter,
  callbackDeleteCenter,
  callbackFindCenters,
  callbackListAgeGroups,
  callbackListAvailableTourTimes,
  callbackListQuestions,
  callbackListWaitlist,
  callbackReadCenter,
  callbackReadCenterDirectory,
  callbackReadCenterDisplayOptions,
  callbackReadCenterEnrolledSeats,
  callbackReadCenterEnrollments,
  callbackReadCenterFamilies,
  callbackReadCenterPostEnrollmentReviews,
  callbackReadCenterProfileCMS,
  callbackReadCenterTourSchedules,
  callbackReadCenterTours,
  callbackReadTourPolicies,
  callbackSaveCenterEnrollment,
  callbackScoreWaitlists,
  callbackSendPostEnrollmentReviewRequestEmails,
  callbackSendWaitlistForm,
  callbackSetAgeGroups,
  callbackUpdateCenter,
  callbackUpdateCenterDisplayOptions,
  callbackUpdateCenterProfileCMS,
  callbackUpdateCenterQuestions,
  callbackUpdateCenterTourScheduleVacation,
  callbackUpdateCenterTourSchedules,
  callbackUpdateTourPolicies,
} from "../actions/centerActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchFindCentersSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.findCenters, prepareHeader(state.clientStateReducer), action.provider_id, action.name, action.relationship);
  yield put(callbackFindCenters(data.centers, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getFindCentersSaga() {
  yield takeLatest(actionTypes.CENTER_FIND, fetchFindCentersSaga);
}

function* fetchReadCenterSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.readCenter, prepareHeader(state.clientStateReducer), action.center_id);
  yield put(callbackReadCenter(data.center, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadCenterSaga() {
  yield takeLatest(actionTypes.CENTER_READ, fetchReadCenterSaga);
}

function* fetchReadCenterFamiliesSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.readCenterFamilies, prepareHeader(state.clientStateReducer), action.center_id, action.includes);
  yield put(callbackReadCenterFamilies(data.families, data.tours, data.tourRequests, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadCenterFamiliesSaga() {
  yield takeLatest(actionTypes.CENTER_READ_FAMILIES, fetchReadCenterFamiliesSaga);
}

function* fetchReadCenterDirectorySaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.readDirectory, prepareHeader(state.clientStateReducer), action.partner_id,
    action.latitude, action.longitude, action.county, action.state);
  yield put(callbackReadCenterDirectory(data.directory, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadCenterDirectorySaga() {
  yield takeLatest(actionTypes.CENTER_READ_DIRECTORY, fetchReadCenterDirectorySaga);
}

function* fetchScoreWaitlistsSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.scoreWaitlists, prepareHeader(state.clientStateReducer), action.center_ids,
    action.child_id, action.preferred_date);
  yield put(callbackScoreWaitlists(data.scores, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getScoreWaitlistsSaga() {
  yield takeLatest(actionTypes.CENTER_SCORE_WAITLISTS, fetchScoreWaitlistsSaga);
}

function* fetchReadProfileCMSSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.readProfileCMS, prepareHeader(state.clientStateReducer), action.center_id);
  yield put(callbackReadCenterProfileCMS(data.cmsData, data.metadata, data.employees, data.centerBasics, data.profileImages, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadProfileCMSSaga() {
  yield takeLatest(actionTypes.CENTER_READ_PROFILE_CMS, fetchReadProfileCMSSaga);
}

function* fetchReadCenterToursSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.readTours, prepareHeader(state.clientStateReducer), action.center_id, action.start, action.end);
  yield put(callbackReadCenterTours(data.tours, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadCenterToursSaga() {
  yield takeLatest(actionTypes.CENTER_READ_TOURS, fetchReadCenterToursSaga);
}

function* fetchReadCenterTourSchedulesSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.readTourSchedules, prepareHeader(state.clientStateReducer), action.center_id);
  yield put(callbackReadCenterTourSchedules(data.tourSchedules, data.vacationDays, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadCenterTourSchedulesSaga() {
  yield takeLatest(actionTypes.CENTER_READ_TOUR_SCHEDULES, fetchReadCenterTourSchedulesSaga);
}

function* fetchReadTourPoliciesSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.readTourPolicy, prepareHeader(state.clientStateReducer), action.center_id);
  yield put(callbackReadTourPolicies(data.policy, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadTourPoliciesSaga() {
  yield takeLatest(actionTypes.CENTER_READ_TOUR_POLICIES, fetchReadTourPoliciesSaga);
}

function* fetchListAvailableTourTimesSaga(action: any) {
  // This is an anonymous function
  const state = yield select();
  const data = yield call(centerApi.listAvailableTourTimes, action.center_id, action.age_group_id, action.start, action.days);
  yield put(callbackListAvailableTourTimes(data.times, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getListAvailableTourTimesSaga() {
  yield takeLatest(actionTypes.CENTER_LIST_AVAILABLE_TOURS, fetchListAvailableTourTimesSaga);
}

function* fetchReadCenterDisplayOptionsSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.readCenterDisplayOptions, prepareHeader(state.clientStateReducer), action.center_id);
  yield put(callbackReadCenterDisplayOptions(data.displayOptions, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadCenterDisplayOptionsSaga() {
  yield takeLatest(actionTypes.CENTER_READ_DISPLAY_OPTIONS, fetchReadCenterDisplayOptionsSaga);
}

function* fetchReadCenterEnrolledSeatsSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.readCenterEnrolledSeats, prepareHeader(state.clientStateReducer), action.center_id);
  yield put(callbackReadCenterEnrolledSeats(data.list, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadCenterEnrolledSeatsSaga() {
  yield takeLatest(actionTypes.CENTER_READ_ENROLLED_SEATS, fetchReadCenterEnrolledSeatsSaga);
}

function* readCenterPostEnrollmentReviewsSaga(action: any) {
  const state = yield select();
  const data = yield call(
    centerApi.readCenterPostEnrollmentReviews, 
    prepareHeader(state.clientStateReducer), 
    action.center_id
  );
  yield put(
    callbackReadCenterPostEnrollmentReviews(
      action.center_id, 
      data.reviewRequestsSentCount, 
      !data.success ? (data.errorCode || "APIERROR") : undefined
    )
  );
}

function* readCenterPostEnrollmentReviewsListener() {
  yield takeLatest(
    actionTypes.CENTER_READ_POST_ENROLLMENT_REVIEWS_LOADING,
    readCenterPostEnrollmentReviewsSaga
  );
}

function* sendPostEnrollmentReviewRequestEmailsSaga(action: any) {
  const state = yield select();
  const data = yield call(
    centerApi.sendPostEnrollmentReviewRequestEmails, 
    prepareHeader(state.clientStateReducer), 
    action.center_id
  );
  yield put(
    callbackSendPostEnrollmentReviewRequestEmails(
      action.center_id, 
      !data.success ? (data.errorCode || "APIERROR") : undefined
    )
  );
}

function* sendPostEnrollmentReviewRequestEmailsListener() {
  yield takeLatest(
    actionTypes.CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_LOADING,
    sendPostEnrollmentReviewRequestEmailsSaga,
  );
}

function* fetchReadCenterEnrollmentsSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.readCenterEnrollments, prepareHeader(state.clientStateReducer), action.center_id);
  yield put(callbackReadCenterEnrollments(action.center_id, data.enrollments, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadCenterEnrollmentsSaga() {
  yield takeLatest(actionTypes.CENTER_READ_ENROLLMENTS, fetchReadCenterEnrollmentsSaga);
}

function* fetchSaveCenterEnrollmentSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.saveCenterEnrollment, prepareHeader(state.clientStateReducer), action.center_id, action.parent, action.child, action.amount);
  yield put(callbackSaveCenterEnrollment(action.center_id, data.parent, data.child, data.amount, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSaveCenterEnrollmentSaga() {
  yield takeLatest(actionTypes.CENTER_SAVE_ENROLLMENT_LOADING, fetchSaveCenterEnrollmentSaga);
}

function* fetchListQuestionsSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.listQuestions, prepareHeader(state.clientStateReducer), action.center_id, action.includeInternal, action.form);
  yield put(callbackListQuestions(data.questions, data.centerCount, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getListQuestionsSaga() {
  yield takeLatest(actionTypes.CENTER_QUESTIONS, fetchListQuestionsSaga);
}

function* fetchListAgeGroupsSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.listAgeGroups, prepareHeader(state.clientStateReducer), action.center_id);
  yield put(callbackListAgeGroups(data.ageGroups, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getListAgeGroupsSaga() {
  yield takeLatest(actionTypes.CENTER_AGEGROUPS, fetchListAgeGroupsSaga);
}

function* fetchSetAgeGroupsSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.setAgeGroups, prepareHeader(state.clientStateReducer), action.center_id, action.ageGroups);
  yield put(callbackSetAgeGroups(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSetAgeGroupsSaga() {
  yield takeLatest(actionTypes.CENTER_SET_AGEGROUPS, fetchSetAgeGroupsSaga);
}

function* fetchListWaitlistSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.listWaitlist, prepareHeader(state.clientStateReducer), action.center_id, action.states);
  yield put(callbackListWaitlist(data.spots, data.ageGroups, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getListWaitlistSaga() {
  yield takeLatest(actionTypes.CENTER_WAITLISTS, fetchListWaitlistSaga);
}

function* fetchAddCenterSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.addCenter, prepareHeader(state.clientStateReducer), action.center);
  const success = !!(data && data.success);
  const center_id = (data && data.center_id) ? data.center_id : undefined;
  yield put(callbackAddCenter(center_id, success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddCenterSaga() {
  yield takeLatest(actionTypes.CENTER_ADD, fetchAddCenterSaga);
}

function* fetchUpdateCenterSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.updateCenter, prepareHeader(state.clientStateReducer), action.center);
  const success = !!(data && data.success);
  yield put(callbackUpdateCenter(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateCenterSaga() {
  yield takeLatest(actionTypes.CENTER_UPDATE, fetchUpdateCenterSaga);
}

function* fetchUpdateCenterQuestionsSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.updateCenterQuestions, prepareHeader(state.clientStateReducer), action.center_id, action.questions);
  const success = !!(data && data.success);
  yield put(callbackUpdateCenterQuestions(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateCenterQuestionsSaga() {
  yield takeLatest(actionTypes.CENTER_UPDATE_QUESTIONS, fetchUpdateCenterQuestionsSaga);
}

function* fetchUpdateProfileCMSSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.updateProfileCMS, prepareHeader(state.clientStateReducer), action.center_id, action.summary,
    action.seo_text, action.about, action.about_image_url, action.education_philosophy, action.special, action.special_image_url, action.safety, action.safety_image_url,
    action.banner_image_url, action.single_image_banner, action.gallery_result_url, action.tour_video_url, action.social_links, action.ga_account, action.sections, 
    action.center_basics, action.profile_images, action.schedule_types);
  const success = !!(data && data.success);
  yield put(callbackUpdateCenterProfileCMS(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateProfileCMSSaga() {
  yield takeLatest(actionTypes.CENTER_UPDATE_PROFILE_CMS, fetchUpdateProfileCMSSaga);
}

function* fetchUpdateTourSchedulesSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.updateCenterTourSchedules, prepareHeader(state.clientStateReducer), action.center_id, action.tourSchedules); 
  const success = !!(data && data.success);
  yield put(callbackUpdateCenterTourSchedules(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateTourSchedulesSaga() {
  yield takeLatest(actionTypes.CENTER_UPDATE_TOUR_SCHEDULES, fetchUpdateTourSchedulesSaga);
}

function* fetchUpdateTourScheduleVacationSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.updateCenterTourScheduleVacation, prepareHeader(state.clientStateReducer), action.center_id, action.vacationDays); 
  const success = !!(data && data.success);
  yield put(callbackUpdateCenterTourScheduleVacation(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateTourScheduleVacationSaga() {
  yield takeLatest(actionTypes.CENTER_UPDATE_TOUR_SCHEDULE_VACATION, fetchUpdateTourScheduleVacationSaga);
}

function* fetchUpdateTourPoliciesSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.updateTourPolicy, prepareHeader(state.clientStateReducer), action.center_id, action.policy); 
  const success = !!(data && data.success);
  yield put(callbackUpdateTourPolicies(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateTourPoliciesSaga() {
  yield takeLatest(actionTypes.CENTER_UPDATE_TOUR_POLICIES, fetchUpdateTourPoliciesSaga);
}

function* fetchUpdateDsiplayOptionsSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.updateCenterDisplayOptions, prepareHeader(state.clientStateReducer), action.center_id, action.displayOptions);
  const success = !!(data && data.success);
  yield put(callbackUpdateCenterDisplayOptions(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateDisplayOptionsSaga() {
  yield takeLatest(actionTypes.CENTER_UPDATE_DISPLAY_OPTIONS, fetchUpdateDsiplayOptionsSaga);
}

function* fetchDeleteCenterSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.deleteCenter, prepareHeader(state.clientStateReducer), action.center_id, action.safe);
  const success = !!(data && data.success);
  yield put(callbackDeleteCenter(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getDeleteCenterSaga() {
  yield takeLatest(actionTypes.CENTER_DELETE, fetchDeleteCenterSaga);
}

function* fetchSendWaitlistFormSaga(action: any) {
  const state = yield select();
  const data = yield call(centerApi.sendWaitlistForm, prepareHeader(state.clientStateReducer), action.center_id, action.tour_id, action.parent_ids);
  const success = !!(data && data.success);
  yield put(callbackSendWaitlistForm(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSendWaitlistFormSaga() {
  yield takeLatest(actionTypes.CENTER_SEND_WAITLIST_FORM, fetchSendWaitlistFormSaga);
}

function* yieldCenterSagas() {
  yield all([
    fork(getFindCentersSaga),
    fork(getReadCenterSaga),
    fork(getReadCenterDirectorySaga),
    fork(getReadCenterFamiliesSaga),
    fork(getReadProfileCMSSaga),
    fork(getReadCenterToursSaga),
    fork(getReadCenterTourSchedulesSaga),
    fork(getReadTourPoliciesSaga),
    fork(getListAvailableTourTimesSaga),
    fork(getReadCenterDisplayOptionsSaga),
    fork(getReadCenterEnrolledSeatsSaga),
    fork(readCenterPostEnrollmentReviewsListener),
    fork(getReadCenterEnrollmentsSaga),
    fork(getSaveCenterEnrollmentSaga),
    fork(getScoreWaitlistsSaga),
    fork(getListQuestionsSaga),
    fork(getListAgeGroupsSaga),
    fork(getSetAgeGroupsSaga),
    fork(getListWaitlistSaga),
    fork(getAddCenterSaga),
    fork(getUpdateCenterSaga),
    fork(getUpdateCenterQuestionsSaga),
    fork(getUpdateProfileCMSSaga),
    fork(getUpdateTourSchedulesSaga),
    fork(getUpdateTourScheduleVacationSaga),
    fork(getUpdateTourPoliciesSaga),
    fork(getUpdateDisplayOptionsSaga),
    fork(getDeleteCenterSaga),
    fork(getSendWaitlistFormSaga),
    fork(sendPostEnrollmentReviewRequestEmailsListener),
  ]);
}

export default yieldCenterSagas;

import querystring from "querystring";

import { AgeGroup, Center, CenterDisplayOptions, TourPolicy, TourSchedule } from "@legup/legup-model";

import { CenterBasics, formatDateForDatePicker } from "../infra/utils";

const centerApi = {
  findCenters(headers: Headers, provider_id: string | undefined, name: string, relationship: string): Promise<Center[]> {
    const searchParams = provider_id ? querystring.stringify({ name, provider_id, relationship }) :
      querystring.stringify({ name, relationship });

    return fetch(`/api/center/find?${searchParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readCenterFamilies(headers: Headers, center_id: string, includes: string[]): Promise<any> {
    const searchParams = querystring.stringify({ includes: includes.join(",") });

    return fetch(`/api/center/${center_id}/families?${searchParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readDirectory(headers: Headers, partner_id: string | undefined, latitude: number | undefined, longitude: number | undefined,
    county: string | undefined, state: string | undefined): Promise<any> {
    const queryParams = querystring.stringify({
      partner: partner_id,
      latitude,
      longitude,
      county,
      state,
    });

    return fetch(`/api/center/directory?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  scoreWaitlists(headers: Headers, center_ids: string[], child_id: string, preferred_date: Date): Promise<any> {
    return fetch("/api/center/waitlistscore", {
      headers,
      method: "POST",
      body: JSON.stringify({ version: "0.1", center_ids, child_id, preferred_date }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readCenter(headers: Headers, center_id: string): Promise<Center> {
    return fetch(`/api/center/${center_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readProfileCMS(headers: Headers, center_id: string): Promise<any> {
    return fetch(`/api/center/${center_id}/profile`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readTours(headers: Headers, center_id: string, start: Date, end: Date): Promise<any> {
    const queryParams = querystring.stringify({ start: start.toString(), end: end.toString() });

    return fetch(`/api/center/${center_id}/tours?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readTourSchedules(headers: Headers, center_id: string): Promise<any> {
    return fetch(`/api/center/${center_id}/tourSchedule`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readTourPolicy(headers: Headers, center_id: string): Promise<any> {
    return fetch(`/api/center/${center_id}/tourPolicy`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  // NOTE: This API does not require authentication
  listAvailableTourTimes(center_id: string, age_group_id: string | undefined, start: Date, days: number): Promise<any> {
    const queryParams = querystring.stringify({ start: formatDateForDatePicker(start), agid: age_group_id, days });

    return fetch(`/api/center/${center_id}/tours/available?${queryParams}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readCenterDisplayOptions(headers: Headers, center_id: string): Promise<CenterDisplayOptions> {
    return fetch(`/api/center/${center_id}/displayoptions`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readCenterEnrolledSeats(headers: Headers, center_id: string): Promise<Center> {
    return fetch(`/api/center/${center_id}/enrolledseats`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readCenterPostEnrollmentReviews(headers: Headers, center_id: string): Promise<Center> {
    return fetch(`/api/center/${center_id}/surveys/postenrollment`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  sendPostEnrollmentReviewRequestEmails(headers: Headers, center_id: string): Promise<Center> {
    return fetch(`/api/center/${center_id}/surveys/postenrollment`, {
      headers,
      method: "POST",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readCenterEnrollments(headers: Headers, center_id: string): Promise<Center> {
    return fetch(`/api/center/${center_id}/enrollment`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  saveCenterEnrollment(headers: Headers, center_id: string, parent, child, amount): Promise<Center> {
    return fetch(`/api/center/${center_id}/enrollment`, {
      headers,
      method: "POST",
      body: JSON.stringify({ parent, child, amount }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  listQuestions(headers: Headers, center_id: string, includeInternal: boolean, form: "waitlist" | "deposit" | "both"): Promise<any[]> {
    const params: any = { form };
    if (includeInternal) {
      params.internal = 1;
    }
    const searchParams = querystring.stringify(params);

    return fetch(`/api/center/${center_id}/questions?${searchParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  listAgeGroups(headers: Headers, center_id: string): Promise<AgeGroup[] | undefined> {
    return fetch(`/api/center/${center_id}/agegroups`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  setAgeGroups(headers: Headers, center_id: string, ageGroups: AgeGroup[]) {
    return fetch(`/api/center/${center_id}/agegroups`, {
      headers,
      method: "POST",
      body: JSON.stringify({ ageGroups }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  listWaitlist(headers: Headers, center_id: string, states: string[] | undefined): Promise<AgeGroup[] | undefined> {
    let url;
    if (states) {
      const searchParams = querystring.stringify({ states: states.join(",") });
      url = `/api/center/${center_id}/waitlist?${searchParams}`;
    }
    else {
      url = `/api/center/${center_id}/waitlist`;
    }

    return fetch(url, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addCenter(headers: Headers, center: Center) {
    const queryParams = querystring.stringify({ provider: center.getProviderId() });

    return fetch(`/api/center?${queryParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify(center),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateCenter(headers: Headers, center: Center) {
    return fetch(`/api/center/${center.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify(center),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateCenterQuestions(headers: Headers, center_id: string, questions: any) {
    return fetch(`/api/center/${center_id}/questions`, {
      headers,
      method: "POST",
      body: JSON.stringify({ questions }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateProfileCMS(headers: Headers, center_id: string, summary: string | undefined, seo_text: string | undefined,
    about: string | undefined, about_image_url: string | undefined, education_philosophy: string | undefined,
    special: string | undefined, special_image_url: string | undefined, safety: string | undefined,
    safety_image_url: string | undefined, banner_image_url: string | undefined,
    single_image_banner: boolean | undefined, gallery_result_url: string | undefined,
    tour_video_url: string | undefined, social_links: {[s: string]: string}, ga_account: string | undefined,
    sections: {[s: string]: any}, center_basics?: CenterBasics, profile_images?: string[], schedule_types?: string[]) {
    return fetch(`/api/center/${center_id}/profile`, {
      headers,
      method: "POST",
      body: JSON.stringify({
        summary,
        seo_text,
        about,
        about_image_url,
        education_philosophy,
        special,
        special_image_url,
        safety,
        safety_image_url,
        banner_image_url,
        single_image_banner,
        gallery_result_url,
        tour_video_url,
        social_links,
        ga_account,
        sections,
        center_basics,
        profile_images,
        schedule_types,
      }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateCenterTourSchedules(headers: Headers, center_id: string, tourSchedules: TourSchedule[]) {
    return fetch(`/api/center/${center_id}/tourSchedule`, {
      headers,
      method: "POST",
      body: JSON.stringify(tourSchedules),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateCenterTourScheduleVacation(headers: Headers, center_id: string, vacationDays: Date[]) {
    return fetch(`/api/center/${center_id}/tours/schedule/exceptions`, {
      headers,
      method: "POST",
      body: JSON.stringify({ vacationDays }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateTourPolicy(headers: Headers, center_id: string, policy: TourPolicy): Promise<any> {
    return fetch(`/api/center/${center_id}/tourPolicy`, {
      headers,
      method: "POST",
      body: JSON.stringify({ policy }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateCenterDisplayOptions(headers: Headers, center_id: string, displayOptions: CenterDisplayOptions) {
    return fetch(`/api/center/${center_id}/displayoptions`, {
      headers,
      method: "POST",
      body: JSON.stringify(displayOptions),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  deleteCenter(headers: Headers, center_id: string, safe: boolean): Promise<string> {
    const queryParams = querystring.stringify({ safe: (safe ? 1 : 0) });

    return fetch(`/api/center/${center_id}?${queryParams}`, {
      headers,
      method: "DELETE",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  sendWaitlistForm(headers: Headers, center_id: string, tour_id: string | undefined, parent_ids: string[]): Promise<string> {
    const params: { [s: string]: string } = { pids: parent_ids.join(",") };
    if (tour_id) {
      params.tid = tour_id;
    }
    const queryParams = querystring.stringify(params);

    return fetch(`/api/center/${center_id}/waitlistform?${queryParams}`, {
      headers,
      method: "POST",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default centerApi;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import leadApi from "../api/leadApi";
import {
  callbackAddLead,
  callbackUpdateLead,
} from "../actions/leadActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchAddLead(action: any) {
  const state = yield select();
  const data = yield call(leadApi.addLead, prepareHeader(state.clientStateReducer), action.center_id, action.lead);
  yield put(callbackAddLead(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddLeadSaga() {
  yield takeLatest(actionTypes.LEAD_ADD, fetchAddLead);
}

function* fetchUpdateLead(action: any) {
  const state = yield select();
  const data = yield call(leadApi.updateLead, prepareHeader(state.clientStateReducer), action.lead);
  yield put(callbackUpdateLead(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateLeadSaga() {
  yield takeLatest(actionTypes.LEAD_UPDATE, fetchUpdateLead);
}

function* yieldLeadSagas() {
  yield all([
    fork(getAddLeadSaga),
    fork(getUpdateLeadSaga),
  ]);
}

export default yieldLeadSagas;

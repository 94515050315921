import querystring from "querystring";

import { WaitlistSpot } from "@legup/legup-model";

const waitlistApi = {
  fillWaitlist(headers: Headers, spot_id: string, seat_id: string, remove: boolean): Promise<boolean> {
    const queryParams = querystring.stringify({ spot: spot_id, seat: seat_id });

    return fetch(`/api/waitlist/fill?${queryParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify({ remove }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  listWaitlist(headers: Headers, list_id: string, states: string[] | undefined): Promise<WaitlistSpot[]> {
    let url;
    if (states) {
      const searchParams = querystring.stringify({ states: states.join(",") });
      url = `/api/waitlist/${list_id}?${searchParams}`;
    }
    else {
      url = `/api/waitlist/${list_id}`;
    }

    return fetch(url, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  getWaitlistFormUrl(headers: Headers, provider_id: string | undefined, center_id: string | undefined): Promise<any> {
    const searchParams = querystring.stringify({ provider: provider_id, center: center_id });

    return fetch(`/api/waitlist/formUrl?${searchParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  getFees(headers: Headers, provider_id: string | undefined, center_id: string | undefined): Promise<any> {
    const searchParams = querystring.stringify({ provider: provider_id, center: center_id });

    return fetch(`/api/waitlist/fees?${searchParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  setFees(headers: Headers, provider_id: string | undefined, center_id: string | undefined, waitlist_fees: any): Promise<boolean> {
    const searchParams = querystring.stringify({ provider: provider_id, center: center_id });

    return fetch(`/api/waitlist/fees?${searchParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify({ waitlist_fees }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  importList(headers: Headers, center_id: string, list_id: string, provider: string, center: string, onboard: boolean, records: string[][], initialEmails: boolean): Promise<boolean> {
    const params: any = {};
    if (center_id) {
      params.center_id = center_id;
    }
    if (list_id) {
      params.list_id = list_id;
    }
    if (provider) {
      params.provider_name = provider;
    }
    if (center) {
      params.center_name = center;
    }
    if (onboard) {
      params.onboard = 1;
    }
    if (initialEmails) {
      params.initialEmails = 1;
    }
    const queryParams = querystring.stringify(params);

    return fetch(`/api/waitlist/import?${queryParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify(records),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  exportList(headers: Headers, center_id: string, list_id: string, mode: string): Promise<string[][] | undefined> {
    const params: any = {};
    if (center_id) {
      params.center = center_id;
    }
    if (list_id) {
      params.list = list_id;
    }
    if (mode) {
      params.mode = mode;
    }
    const queryParams = querystring.stringify(params);

    return fetch(`/api/waitlist/export?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  generateOfferLinks(headers: Headers, spot_id: string, seat_id: string): Promise<boolean> {
    const queryParams = querystring.stringify({ seat: seat_id });

    return fetch(`/api/spot/${spot_id}/offerlinks?${queryParams}`, {
      headers,
      method: "POST",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readSpot(headers: Headers, spot_id: WaitlistSpot): Promise<boolean> {
    return fetch(`/api/spot/${spot_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addSpot(headers: Headers, waitlist_spot: WaitlistSpot): Promise<boolean> {
    const queryParams = querystring.stringify({ list: waitlist_spot.getListId() });

    return fetch(`/api/spot?${queryParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify(waitlist_spot),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  removeSpot(headers: Headers, waitlist_spot: WaitlistSpot, removal_reason: string, removalRefund: boolean, deleteSpot: boolean, mailParents: boolean): Promise<boolean> {
    const queryParams = querystring.stringify({ list: waitlist_spot.getListId() });

    return fetch(`/api/spot/${waitlist_spot.getId()}?${queryParams}`, {
      headers,
      method: "DELETE",
      body: JSON.stringify({ reason: removal_reason, refund: removalRefund, delete: deleteSpot, mailParents }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readSeat(headers: Headers, spot_id: WaitlistSpot): Promise<boolean> {
    return fetch(`/api/spot/${spot_id}/seat`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  reactivateSpot(headers: Headers, waitlist_spot: WaitlistSpot): Promise<boolean> {
    const queryParams = querystring.stringify({ list: waitlist_spot.getListId() });

    return fetch(`/api/spot/${waitlist_spot.getId()}/reactivate?${queryParams}`, {
      headers,
      method: "POST",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateSpot(headers: Headers, waitlist_spot: WaitlistSpot): Promise<boolean> {
    return fetch(`/api/spot/${waitlist_spot.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify(waitlist_spot),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  sendEmailInvite(headers: Headers, recipient: string, child_id: string, center_id: string): Promise<boolean | undefined> {
    return fetch("/api/waitlist/send", {
      headers,
      method: "POST",
      body: JSON.stringify({
        recipient,
        child_id,
        center_id,
      }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default waitlistApi;

import { theme } from "@legup/legup-react-components";

const css = `
*::selection {
  color: white;
  background-color: ${theme.palette.primary.main};
}

body {
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}

.background-color-neutral {
  background-color: ${theme.palette.grey[200]};
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.font-weight-600,
.bold {
  font-weight: 600 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.italic {
  font-style: italic;
}

.cursor-pointer {
  cursor: pointer;
}

.text-align-center {
  text-align: center;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.position-relative {
  position: relative;
}

.flex-shrink-1 {
  flex-shrink: 1;
}
`;

// Inject CSS if on browser
if (typeof window !== "undefined") {
  // Attach CSS to document
  const head = document.head || document.getElementsByTagName("head")[0];
  const style = document.createElement("style");

  head.appendChild(style);
  style.appendChild(document.createTextNode(css));
}

import { AgeGroup, Center, Child, Navigator, Parent, Seat, Tour } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface FamilyCenters {
  waitlists: Center[];
  tours: Center[];
  favoriteOnly: Center[];
  centerData: any[];
  favorites: string[];
}
interface IDefaultParentState {
  query: string | undefined;
  parentList: Parent[];
  currentParents: Parent[] | undefined;
  currentNavigator: Navigator | undefined;
  familyCenters: FamilyCenters | undefined;
  childrenWaitlists: any[];
  familySeats: any[];
  tourList: Tour[];
  matchedSeats: Seat[];
  savedCenters: { total: number, withOpenings: number, matchedSeats: number, notToured: number };
  unseenCenters: Array<{ center_id: string, cost_amount: number, cost_frequency: string, distance: number }>;
  legupMarketId: string | undefined;
  legupWaitlistId: string | undefined;
  tookSurvey: boolean | undefined;
  addSuccess: boolean | undefined;
  updateSuccess: boolean | undefined;
  updateSubsidiesSuccess: boolean | undefined;
  mergeChildrenSuccess: boolean | undefined;
  addedParentId: string | undefined;
  errorCode: string | undefined;
  readParentLoading: boolean | undefined;
}
const defaultProductState: IDefaultParentState = {
  query: undefined,
  parentList: undefined,
  currentParents: undefined,
  currentNavigator: undefined,
  familyCenters: undefined,
  childrenWaitlists: undefined,
  familySeats: undefined,
  tourList: undefined,
  matchedSeats: undefined,
  savedCenters: undefined,
  unseenCenters: undefined,
  legupMarketId: undefined,
  legupWaitlistId: undefined,
  tookSurvey: undefined,
  addSuccess: undefined,
  updateSuccess: undefined,
  updateSubsidiesSuccess: undefined,
  mergeChildrenSuccess: undefined,
  addedParentId: undefined,
  errorCode: undefined,
  readParentLoading: undefined,
};

function reduceFindParentsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const parentList = [];

  if (action.parentList) {
    action.parentList.forEach(p => {
      const parent = new Parent();
      parent.buildFromJSON(p);
      parentList.push(parent);
    });
  }

  return { ...state, parentList, currentParent: null };
}

function reduceReadParent(state: any, action: any) {
  return {
    ...state,
    currentParents: undefined,
    currentNavigator: undefined,
    readParentLoading: true,
  };
}

function reduceReadParentCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const currentParents: Parent[] = [];
  action.parents.forEach(p => {
    const parent: Parent = new Parent();
    parent.buildFromJSON(p);
    currentParents.push(parent);
  });
  let currentNavigator: Navigator | undefined;
  if (action.navigator) {
    currentNavigator = new Navigator();
    currentNavigator.buildFromJSON(action.navigator);
  }

  return {
    ...state,
    currentParents,
    currentNavigator,
    readParentLoading: false,
  };
}

function reduceReadStatusCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const legupMarketId: string | undefined = action.legupMarketId;
  const marketWaitlistId: string | undefined = action.marketWaitlistId;
  const savedCenters: { total: number, withOpenings: number, matchedSeats: number, notToured: number } = action.savedCenters;
  const unseenCenters: Array<{ center_id: string, cost_amount: number, cost_frequency: string, distance: number }> = action.unseenCenters;
  const tookSurvey: boolean = action.tookSurvey;

  const childrenWaitlists: any = [];
  if (action.lists) {
    action.lists.forEach(c => {
      // Get child
      const result: any = {};
      result.child = new Child();
      result.child.buildFromJSON(c.child);

      // Get waitlists
      result.waitlists = [];
      c.waitlists.forEach(w => {
        const ageGroup: AgeGroup = new AgeGroup();
        ageGroup.buildFromJSON(w.ageGroup);
        const center: Center = new Center();
        center.buildFromJSON(w.center);
        let seat: Seat;
        if (w.seat) {
          seat = new Seat();
          seat.buildFromJSON(w.seat);
        }
        result.waitlists.push({
          ageGroup,
          center,
          seat,
          position: w.position,
          waitlist_type: w.waitlist_type,
          state: w.state,
          can_request_paperwork: w.can_request_paperwork,
          spot_id: w.spot_id,
          add_date: w.add_date,
          preferred_date: w.preferred_date,
        });
      });

      if (c.legup) {
        result.legup = {
          list_id: c.legup.list_id,
          spot_id: c.legup.spot_id,
          add_date: c.legup.add_date,
          preferred_date: c.legup.preferred_date,
        };
      }
      if (c.partner) {
        result.partner = {
          list_id: c.partner.list_id,
          spot_id: c.partner.spot_id,
          add_date: c.partner.add_date,
          preferred_date: c.partner.preferred_date,
        };
      }

      childrenWaitlists.push(result);
    });
  }

  const familySeats: any = [];
  if (action.seats) {
    action.seats.forEach(s => {
      // Get child
      const result: any = {};
      result.child = new Child();
      result.child.buildFromJSON(s.child);

      result.seat = new Seat();
      result.seat.buildFromJSON(s.seat);

      result.center = new Center();
      result.center.buildFromJSON(s.center);

      result.can_request_paperwork = s.can_request_paperwork;
      result.gallery_result_url = s.gallery_result_url;
      familySeats.push(result);
    });
  }

  const tourList: Tour[] = [];
  if (action.tours) {
    action.tours.forEach(t => {
      const tour: Tour = new Tour();
      tour.buildFromJSON(t);
      tourList.push(tour);
    });
  }

  const matchedSeats: Seat[] = [];
  if (action.matchedSeats) {
    action.matchedSeats.forEach(s => {
      const seat: Seat = new Seat();
      seat.buildFromJSON(s);
      matchedSeats.push(seat);
    });
  }

  return { ...state, childrenWaitlists, familySeats, tourList, matchedSeats, savedCenters, unseenCenters, legupMarketId, marketWaitlistId, tookSurvey };
}

function reduceReadCentersCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const familyCenters = { waitlists: [], tours: [], centerData: [], favoriteOnly: [], favorites: [] };
  (action.waitlists || []).forEach(c => {
    const center: Center = new Center();
    center.buildFromJSON(c);
    familyCenters.waitlists.push(center);
  });
  (action.tours || []).forEach(c => {
    const center: Center = new Center();
    center.buildFromJSON(c);
    familyCenters.tours.push(center);
  });
  (action.favoriteOnly || []).forEach(c => {
    const center: Center = new Center();
    center.buildFromJSON(c);
    familyCenters.favoriteOnly.push(center);
  });
  (action.centerData || []).forEach(c => {
    familyCenters.centerData.push(c);
  });
  (action.favorites || []).forEach(f => {
    familyCenters.favorites.push(f);
  });

  return { ...state, familyCenters };
}

function reduceAddParent(state: any, action: any) {
  return { ...state, addSuccess: undefined };
}

function reduceAddParentCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addSuccess: false, errorCode: action.errorCode };
  }

  if (action.parent_id) {
    // Set the ID of the current parent
    const parent: Parent = state.currentParent ? state.currentParent : new Parent();

    parent.setId(action.parent_id);

    return { ...state, currentParent: parent, addedParentId: action.parent_id, addSuccess: true };
  }
 
  return { ...state, addSuccess: false };
  
}

function reduceUpdateParent(state: any, action: any) {
  return { ...state, updateSuccess: undefined };
}

function reduceUpdateParentCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSuccess: action.success };
}

function reduceUpdateParentSubsidies(state: any, action: any) {
  return { ...state, updateSubsidiesSuccess: undefined };
}

function reduceUpdateParentSubsidiesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSubsidiesSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSubsidiesSuccess: action.success };
}

function reduceMergeChildrenCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, mergeChildrenSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, mergeChildrenSuccess: action.success };
}

function reduceParents(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.PARENT_FIND_CALLBACK:
      return reduceFindParentsCallback(state, action);
    case actionTypes.PARENT_READ:
      return reduceReadParent(state, action);
    case actionTypes.PARENT_READ_CALLBACK:
      return reduceReadParentCallback(state, action);
    case actionTypes.PARENT_READ_STATUS_CALLBACK:
      return reduceReadStatusCallback(state, action);
    case actionTypes.PARENT_READ_CENTERS_CALLBACK:
      return reduceReadCentersCallback(state, action);
    case actionTypes.PARENT_ADD:
      return reduceAddParent(state, action);
    case actionTypes.PARENT_ADD_CALLBACK:
      return reduceAddParentCallback(state, action);
    case actionTypes.PARENT_UPDATE:
      return reduceUpdateParent(state, action);
    case actionTypes.PARENT_UPDATE_CALLBACK:
      return reduceUpdateParentCallback(state, action);
    case actionTypes.PARENT_UPDATE_SUBSIDIES:
      return reduceUpdateParentSubsidies(state, action);
    case actionTypes.PARENT_UPDATE_SUBSIDIES_CALLBACK:
      return reduceUpdateParentSubsidiesCallback(state, action);
    case actionTypes.PARENT_MERGE_CHILDREN_CALLBACK:
      return reduceMergeChildrenCallback(state, action);
    default:
      return state;
  }
}

export default reduceParents;

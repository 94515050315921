
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import navigatorApi from "../api/navigatorApi";
import {
  callbackAddNavigator,
  callbackReadNavigator,
  callbackReadNavigatorCenters,
  callbackReadNavigatorFamilies,
  callbackReadNavigatorStatus,
  callbackUpdateNavigator,
} from "../actions/navigatorActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchAddNavigatorSaga(action: any) {
  const state = yield select();
  const data = yield call(navigatorApi.addNavigator, prepareHeader(state.clientStateReducer), action.navigator);
  yield put(callbackAddNavigator(data.navigator_id, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddNavigatorSaga() {
  yield takeLatest(actionTypes.NAVIGATOR_ADD, fetchAddNavigatorSaga);
}

function* fetchUpdateNavigatorSaga(action: any) {
  const state = yield select();
  const data = yield call(navigatorApi.updateNavigator, prepareHeader(state.clientStateReducer), action.navigator);
  yield put(callbackUpdateNavigator(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateNavigatorSaga() {
  yield takeLatest(actionTypes.NAVIGATOR_UPDATE, fetchUpdateNavigatorSaga);
}

function* fetchReadNavigatorSaga(action: any) {
  const state = yield select();
  const data = yield call(navigatorApi.readNavigator, prepareHeader(state.clientStateReducer), action.navigator_id);
  yield put(callbackReadNavigator(data.navigator, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadNavigatorSaga() {
  yield takeLatest(actionTypes.NAVIGATOR_READ, fetchReadNavigatorSaga);
}

function* fetchReadNavigatorStatusSaga(action: any) {
  const state = yield select();
  const data = yield call(navigatorApi.readNavigatorStatus, prepareHeader(state.clientStateReducer), action.navigator_id);
  yield put(callbackReadNavigatorStatus(data.children, data.seats, data.tours, data.market_waitlist_id, data.legup_list_id, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadNavigatorStatusSaga() {
  yield takeLatest(actionTypes.NAVIGATOR_READ_STATUS, fetchReadNavigatorStatusSaga);
}

function* fetchReadNavigatorFamiliesSaga(action: any) {
  const state = yield select();
  const data = yield call(navigatorApi.readNavigatorFamilies, prepareHeader(state.clientStateReducer), action.navigator_id);
  yield put(callbackReadNavigatorFamilies(data.families, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadNavigatorFamiliesSaga() {
  yield takeLatest(actionTypes.NAVIGATOR_READ_FAMILIES, fetchReadNavigatorFamiliesSaga);
}

function* fetchReadNavigatorCentersSaga(action: any) {
  const state = yield select();
  const data = yield call(navigatorApi.readNavigatorCenters, prepareHeader(state.clientStateReducer), action.parent_id);
  yield put(callbackReadNavigatorCenters(data.waitlists, data.tours, data.favoriteOnly, data.centerData, data.favorites, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadNavigatorCentersSaga() {
  yield takeLatest(actionTypes.NAVIGATOR_READ_CENTERS, fetchReadNavigatorCentersSaga);
}

function* yieldNavigatorSagas() {
  yield all([
    fork(getAddNavigatorSaga),
    fork(getUpdateNavigatorSaga),
    fork(getReadNavigatorSaga),
    fork(getReadNavigatorFamiliesSaga),
    fork(getReadNavigatorStatusSaga),
    fork(getReadNavigatorCentersSaga),
  ]);
}

export default yieldNavigatorSagas;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import contractApi from "../api/contractApi";
import programApi from "../api/programApi";
import {
  callbackAddContract,
  callbackDeleteContract,
  callbackReadContract,
  callbackUpdateContract,
} from "../actions/contractActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchReadContractSaga(action: any) {
  const state = yield select();
  const data = yield call(contractApi.readContract, prepareHeader(state.clientStateReducer), action.contract_id);
  yield put(callbackReadContract(data.contract, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadContractSaga() {
  yield takeLatest(actionTypes.CONTRACT_READ, fetchReadContractSaga);
}

function* fetchAddContractSaga(action: any) {
  const state = yield select();
  const preparedHeader = prepareHeader(state.clientStateReducer);

  const program = action.program.copy();
  const contract = action.contract.copy();
  const ageGroupIds = action.ageGroupIds;

  // - save contract (age group to contract relation is also saved via the db function)
  const contractData = yield call(contractApi.addContracts, preparedHeader, action.provider_id, [contract], ageGroupIds);

  if (contractData?.ids?.length === 1) {
    contract.contract_id = contractData.ids[0];
  }

  // - add contract to program.setContracts([contract])
  program.setContracts([contract]);

  // - save program (program to contract relation is also saved via the db function)
  const programData = yield call(programApi.addPrograms, preparedHeader, [program]);

  const success = !!(programData && programData.success && contractData && contractData.success);
  yield put(callbackAddContract(success, contractData, !contractData.success ? (contractData.errorCode || "APIERROR") : undefined));
}

function* getAddContractSaga() {
  yield takeLatest(actionTypes.CONTRACT_ADD, fetchAddContractSaga);
}

function* fetchUpdateContractSaga(action: any) {
  const state = yield select();

  const preparedHeader = prepareHeader(state.clientStateReducer);

  const program = action.program.copy();
  const contract = action.contract.copy();

  // - add contract to program
  program.setContracts([contract]);

  const contractData = yield call(contractApi.updateContract, preparedHeader, contract, action.ageGroupIds);

  // - update program or add program if not exists
  let programData;
  if (program.program_id) {
    programData = yield call(programApi.updateProgram, preparedHeader, program);
  }
  else {
    programData = yield call(programApi.addPrograms, preparedHeader, [program]);
  }

  const success = !!(contractData && contractData.success && programData && programData.success);
  yield put(callbackUpdateContract(success, !success ? (contractData.errorCode || programData.errorCode || "APIERROR") : undefined));
}

function* getUpdateContractSaga() {
  yield takeLatest(actionTypes.CONTRACT_UPDATE, fetchUpdateContractSaga);
}

function* fetchDeleteContractSaga(action: any) {
  const state = yield select();
  const data = yield call(contractApi.deleteContract, prepareHeader(state.clientStateReducer), action.contract_id);
  const success = !!(data && data.success);
  yield put(callbackDeleteContract(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getDeleteContractSaga() {
  yield takeLatest(actionTypes.CONTRACT_DELETE, fetchDeleteContractSaga);
}

function* yieldContractSagas() {
  yield all([
    fork(getReadContractSaga),
    fork(getAddContractSaga),
    fork(getUpdateContractSaga),
    fork(getDeleteContractSaga),
  ]);
}

export default yieldContractSagas;

import { Program } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function addPrograms(programs: Program[]) {
  return {
    type: actionTypes.PROGRAM_ADD,
    programs,
  };
}

export function callbackAddPrograms(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROGRAM_ADD_CALLBACK,
    success,
    errorCode,
  };
}

export function updateProgram(program: Program) {
  return {
    type: actionTypes.PROGRAM_UPDATE,
    program,
  };
}

export function callbackUpdateProgram(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROGRAM_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

export function copyProgram(program_id: string, program_prefix: string | undefined, program_suffix: string | undefined, contract_prefix: string | undefined, contract_suffix: string | undefined) {
  return {
    type: actionTypes.PROGRAM_COPY,
    program_id,
    program_prefix,
    program_suffix,
    contract_prefix,
    contract_suffix,
  };
}

export function callbackCopyProgram(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROGRAM_COPY_CALLBACK,
    success,
    errorCode,
  };
}

export function deleteProgram(program_id: string) {
  return {
    type: actionTypes.PROGRAM_DELETE,
    program_id,
  };
}

export function callbackDeleteProgram(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PROGRAM_DELETE_CALLBACK,
    success,
    errorCode,
  };
}

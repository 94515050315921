import actionTypes from "../infra/constants/actionTypes";

interface IDefaultLeadState {
  addSuccess: boolean | undefined;
  updateSuccess: boolean | undefined;
  errorCode: string | undefined;
}

const defaultProductState: IDefaultLeadState = {
  addSuccess: undefined,
  updateSuccess: undefined,
  errorCode: undefined,
};

function reduceAddLead(state: any, action: any) {
  return { ...state, addSuccess: undefined, errorCode: undefined };
}

function reduceAddLeadCallback(state: any, action: any) {
  return { ...state, addSuccess: action.success, errorCode: action.errorCode };
}

function reduceUpdateLead(state: any, action: any) {
  return { ...state, updateSuccess: undefined, errorCode: undefined };
}

function reduceUpdateLeadCallback(state: any, action: any) {
  return { ...state, updateSuccess: action.success, errorCode: action.errorCode };
}

function reduceLeads(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.LEAD_ADD:
      return reduceAddLead(state, action);
    case actionTypes.LEAD_ADD_CALLBACK:
      return reduceAddLeadCallback(state, action);
    case actionTypes.LEAD_UPDATE:
      return reduceUpdateLead(state, action);
    case actionTypes.LEAD_UPDATE_CALLBACK:
      return reduceUpdateLeadCallback(state, action);
    default:
      return state;
  }
}

export default reduceLeads;

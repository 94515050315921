
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import paymentApi from "../api/paymentApi";
import {
  callbackReadPayment,
  callbackReadReceipts,
  callbackSaveReceipt,
} from "../actions/paymentActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchReadPaymentSaga(action: any) {
  const state = yield select();
  const data = yield call(paymentApi.readPayment, prepareHeader(state.clientStateReducer), action.payment_id, action.provider_id);
  yield put(callbackReadPayment(data.payment, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadPaymentSaga() {
  yield takeLatest(actionTypes.PAYMENT_READ, fetchReadPaymentSaga);
}

function* fetchReadReceiptsSaga(action: any) {
  const state = yield select();
  const data = yield call(paymentApi.readReceipts, prepareHeader(state.clientStateReducer), action.provider_id, action.start, action.end);
  yield put(callbackReadReceipts(data.payments, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadReceiptsSaga() {
  yield takeLatest(actionTypes.PAYMENT_READ_RECEIPTS, fetchReadReceiptsSaga);
}

function* fetchSaveReceiptSaga(action: any) {
  const state = yield select();
  const data = yield call(paymentApi.saveReceipt, action.payment_id, action.receipt, action.token);
  const success = !!(data && data.success);
  yield put(callbackSaveReceipt(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSaveReceiptSaga() {
  yield takeLatest(actionTypes.PAYMENT_SAVE_RECEIPT, fetchSaveReceiptSaga);
}

function* yieldPaymentSagas() {
  yield all([
    fork(getReadPaymentSaga),
    fork(getReadReceiptsSaga),
    fork(getSaveReceiptSaga),
  ]);
}

export default yieldPaymentSagas;

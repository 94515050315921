import { Partner } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultAdminUnitState {
  partnerList: Partner[];
  currentPartner: Partner;
  errorCode: string | undefined;
}
const defaultProductState: IDefaultAdminUnitState = {
  partnerList: undefined,
  currentPartner: undefined,
  errorCode: undefined,
};

function reduceFindPartnersCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const partnerList: Partner[] = [];

  if (action.partnerList) {
    action.partnerList.forEach((p: any) => {
      const partner: Partner = new Partner();
      partner.buildFromJSON(p);
      partnerList.push(partner);
    });
  }

  return { ...state, partnerList };
}

function reduceReadPartnerCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  let currentPartner: Partner | undefined;
  if (action.partner) {
    currentPartner = new Partner();
    currentPartner.buildFromJSON(action.partner);
  }

  return { ...state, currentPartner };
}

function reducePartners(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.PARTNER_FIND_CALLBACK:
      return reduceFindPartnersCallback(state, action);
    case actionTypes.PARTNER_READ_CALLBACK:
      return reduceReadPartnerCallback(state, action);
    default:
      return state;
  }
}

export default reducePartners;

import { all, fork } from "redux-saga/effects";

import yieldAgeGroupSagas from "./ageGroupSaga";
import yieldAdminUnitSagas from "./adminunitSaga";
import yieldAuthSagas from "./authSaga";
import yieldCenterSagas from "./centerSaga";
import yieldChildSagas from "./childSaga";
import yieldClassroomSagas from "./classroomSaga";
import yieldClientStateSagas from "./clientStateSaga";
import yieldContractSagas from "./contractSaga";
import yieldCouponSagas from "./couponSaga";
import yieldFeedbackSagas from "./feedbackSaga";
import yieldLeadSagas from "./leadSaga";
import yieldLeguplistSagas from "./leguplistSaga";
import yieldLinkSagas from "./linkSaga";
import yieldNavigatorSagas from "./navigatorSaga";
import yieldParentSagas from "./parentSaga";
import yieldPartnerSagas from "./partnerSaga";
import yieldPaymentSagas from "./paymentSaga";
import yieldProgramSagas from "./programSaga";
import yieldProviderSagas from "./providerSaga";
import yieldSeatSagas from "./seatSaga";
import yieldSearchSagas from "./searchSaga";
import yieldSubsidySagas from "./subsidySaga";
import yieldTourSagas from "./tourSaga";
import yieldUserSagas from "./userSaga";
import yieldWaitlistSagas from "./waitlistSaga";
import yieldCommsSagas from "./commsSaga";

function* initGlobalSaga() {
  yield all([
    fork(yieldAgeGroupSagas),
    fork(yieldAdminUnitSagas),
    fork(yieldAuthSagas),
    fork(yieldCenterSagas),
    fork(yieldChildSagas),
    fork(yieldClassroomSagas),
    fork(yieldClientStateSagas),
    fork(yieldContractSagas),
    fork(yieldCouponSagas),
    fork(yieldFeedbackSagas),
    fork(yieldLeadSagas),
    fork(yieldLeguplistSagas),
    fork(yieldLinkSagas),
    fork(yieldNavigatorSagas),
    fork(yieldParentSagas),
    fork(yieldPartnerSagas),
    fork(yieldPaymentSagas),
    fork(yieldProgramSagas),
    fork(yieldProviderSagas),
    fork(yieldSeatSagas),
    fork(yieldSearchSagas),
    fork(yieldSubsidySagas),
    fork(yieldTourSagas),
    fork(yieldUserSagas),
    fork(yieldWaitlistSagas),
    fork(yieldCommsSagas),
  ]);
}

export default initGlobalSaga;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import ageGroupApi from "../api/ageGroupApi";
import centerApi from "../api/centerApi";

import {
  callbackAddAgeGroup,
  callbackDeleteAgeGroup,
  callbackReadAgeGroup,
  callbackUpdateAgeGroup,
} from "../actions/ageGroupActions";
import {
  callbackListAgeGroups,
} from "../actions/centerActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchReadAgeGroupSaga(action: any) {
  const state = yield select();
  const data = yield call(ageGroupApi.readAgeGroup, prepareHeader(state.clientStateReducer), action.age_group_id);
  yield put(callbackReadAgeGroup(data.ageGroup, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadAgeGroupSaga() {
  yield takeLatest(actionTypes.AGEGROUP_READ, fetchReadAgeGroupSaga);
}

function* fetchAddAgeGroupSaga(action: any) {
  const state = yield select();

  // Add age group
  const data = yield call(ageGroupApi.addAgeGroup, prepareHeader(state.clientStateReducer), action.ageGroup);
  yield put(callbackAddAgeGroup(data.ageGroup, !data.success ? (data.errorCode || "APIERROR") : undefined));

  // list age groups
  const centerData = yield call(centerApi.listAgeGroups, prepareHeader(state.clientStateReducer), action.centerId);
  yield put(callbackListAgeGroups(centerData.ageGroups, !centerData.success ? (centerData.errorCode || "APIERROR") : undefined));
}

function* getAddAgeGroupSaga() {
  yield takeLatest(actionTypes.AGEGROUP_ADD, fetchAddAgeGroupSaga);
}

function* fetchUpdateAgeGroupSaga(action: any) {
  const state = yield select();

  // update age group
  const ageGroupData = yield call(ageGroupApi.updateAgeGroup, prepareHeader(state.clientStateReducer), action.ageGroup);
  yield put(callbackUpdateAgeGroup((ageGroupData && ageGroupData.success), !ageGroupData.success ? (ageGroupData.errorCode || "APIERROR") : undefined));

  // list age groups
  const centerData = yield call(centerApi.listAgeGroups, prepareHeader(state.clientStateReducer), action.centerId);
  yield put(callbackListAgeGroups(centerData.ageGroups, !centerData.success ? (centerData.errorCode || "APIERROR") : undefined));
}

function* getUpdateAgeGroupSaga() {
  yield takeLatest(actionTypes.AGEGROUP_UPDATE, fetchUpdateAgeGroupSaga);
}

function* fetchDeleteAgeGroupSaga(action: any) {
  const state = yield select();

  // delete age group
  const data = yield call(ageGroupApi.deleteAgeGroup, prepareHeader(state.clientStateReducer), action.age_group_id, action.safe);
  const success = !!(data && data.success);
  yield put(callbackDeleteAgeGroup(success, !data.success ? (data.errorCode || "APIERROR") : undefined));

  // list age groups
  const centerData = yield call(centerApi.listAgeGroups, prepareHeader(state.clientStateReducer), action.center_id);
  yield put(callbackListAgeGroups(centerData.ageGroups, !centerData.success ? (centerData.errorCode || "APIERROR") : undefined));
}

function* getDeleteAgeGroupSaga() {
  yield takeLatest(actionTypes.AGEGROUP_DELETE, fetchDeleteAgeGroupSaga);
}

function* yieldAgeGroupSagas() {
  yield all([
    fork(getReadAgeGroupSaga),
    fork(getAddAgeGroupSaga),
    fork(getUpdateAgeGroupSaga),
    fork(getDeleteAgeGroupSaga),
  ]);
}

export default yieldAgeGroupSagas;

import querystring from "querystring";

const commsApi = {
  sendMessage(headers: Headers, provider_id: string, name: string): Promise<any> {
    const queryParams = querystring.stringify({ pid: provider_id, name });

    return fetch(`/api/comms?${queryParams}`, {
      headers,
      method: "POST",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readParentContacts(headers: Headers, center_id): Promise<any> {
    return fetch("/api/comms/contacts", {
      headers,
      method: "POST",
      body: `{"center_id": ${center_id}}`,
    })
        .then(res => res.json())
        .then(data => data)
        .catch(e => {
          console.log(e);
        });
  },

  readMessages(headers: Headers, to_contact: any, from_contact: any): Promise<any> {
    return fetch("/api/comms/chat", {
      headers,
      method: "POST",
      body: `{"to": ${JSON.stringify(to_contact)},
              "from": ${JSON.stringify(from_contact)}}`,
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
  readMessageThread(headers: Headers, thread: any): Promise<any> {
    return fetch("/api/comms/mail/thread", {
      headers,
      method: "POST",
      body: `${JSON.stringify(thread)}`,
    })
        .then(res => res.json())
        .then(data => data)
        .catch(e => {
          console.log(e);
        });
  },
  readInboxMessages(headers: Headers, to_contact: any): Promise<any> {
    return fetch("/api/comms/mail/inbox", {
      headers,
      method: "POST",
      body: `{"to": ${JSON.stringify(to_contact)}}`,
    })
        .then(res => res.json())
        .then(data => data)
        .catch(e => {
          console.log(e);
        });
  },
  readSentMessages(headers: Headers, from_contact: any): Promise<any> {
    return fetch("/api/comms/mail/sent", {
      headers,
      method: "POST",
      body: `{"from": ${JSON.stringify(from_contact)}}`,
    })
        .then(res => res.json())
        .then(data => data)
        .catch(e => {
          console.log(e);
        });
  },

};

export default commsApi;

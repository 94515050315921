import { Seat, WaitlistSpot } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultWaitlistState {
  currentSpot: WaitlistSpot | undefined;
  waitlistSpots: WaitlistSpot[] | undefined;
  exportedRecords: string[][] | undefined;
  offerCodes: any[] | undefined;
  offerEmail: string | undefined;
  formUrl: string | undefined;
  waitlistFees: any | undefined;
  commission: any | undefined;
  centerCount: number | undefined;
  seat: Seat | undefined;
  importedSpots: string[] | undefined;
  setFeesSuccess: boolean | undefined;
  importSuccess: boolean | undefined;
  addSpotSuccess: boolean | undefined;
  removeSpotSuccess: boolean | undefined;
  reactivateSpotSuccess: boolean | undefined;
  updateSpotSuccess: boolean | undefined;
  fillSuccess: boolean | undefined;
  addedSpotId: string | undefined;
  errorCode: string | undefined;
  sendParentInviteEmailSuccess: boolean | undefined;
}

const defaultProductState: IDefaultWaitlistState = {
  currentSpot: undefined,
  waitlistSpots: null,
  exportedRecords: undefined,
  offerCodes: undefined,
  offerEmail: undefined,
  formUrl: undefined,
  waitlistFees: undefined,
  commission: undefined,
  centerCount: undefined,
  seat: undefined,
  importedSpots: undefined,
  setFeesSuccess: undefined,
  importSuccess: undefined,
  addSpotSuccess: undefined,
  removeSpotSuccess: undefined,
  reactivateSpotSuccess: undefined,
  updateSpotSuccess: undefined,
  fillSuccess: undefined,
  addedSpotId: undefined,
  errorCode: undefined,
  sendParentInviteEmailSuccess: undefined,
};

function reduceFillWaitlist(state: any, action: any) {
  return { ...state, fillSuccess: undefined };
}

function reduceFillWaitlistCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, fillSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, fillSuccess: action.success };
}

function reduceListWaitlistCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const waitlistSpots: WaitlistSpot[] = [];

  if (action.spots) {
    action.spots.forEach(s => {
      const spot = new WaitlistSpot();
      spot.buildFromJSON(s);
      waitlistSpots.push(spot);
    });
  }

  return { ...state, waitlistSpots };
}

function reduceFormUrl(state: any, action: any) {
  return { ...state, formUrl: undefined, errorCode: undefined };
}

function reduceFormUrlCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, formUrl: action.url };
}

function reduceGetFeesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, waitlistFees: action.waitlist_fees, commission: action.commission, centerCount: action.center_count };
}

function reduceSetFees(state: any, action: any) {
  return { ...state, setFeesSuccess: null, setFeesLoading: true };
}

function reduceSetFeesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, setFeesSuccess: false, errorCode: action.errorCode, setFeesLoading: false };
  }

  return { ...state, setFeesSuccess: action.success, setFeesLoading: false };
}

function reduceReadSpot(state: any, action: any) {
  return { ...state, currentSpot: undefined, errorCode: undefined };
}

function reduceReadSpotCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const currentSpot: WaitlistSpot = new WaitlistSpot();

  currentSpot.buildFromJSON(action.waitlist_spot);

  return { ...state, currentSpot };
}

function reduceReadSeat(state: any, action: any) {
  return { ...state, seat: undefined, errorCode: undefined };
}

function reduceReadSeatCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const seat: Seat = new Seat();
  seat.buildFromJSON(action.seat);

  return { ...state, seat };
}

function reduceAddSpot(state: any, action: any) {
  return { ...state, addedSpotId: undefined, addSpotSuccess: undefined, errorCode: undefined };
}

function reduceAddSpotCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addSpotSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, addedSpotId: action.spot_id, addSpotSuccess: action.success };
}

function reduceRemoveSpot(state: any, action: any) {
  return { ...state, removeSpotSuccess: undefined, errorCode: undefined };
}

function reduceRemoveSpotCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, removeSpotSucess: false, errorCode: action.errorCode };
  }

  return { ...state, removeSpotSuccess: action.success };
}

function reduceReactivateSpot(state: any, action: any) {
  return { ...state, reactivateSpotSuccess: undefined };
}

function reduceReactivateSpotCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, reactivateSpotSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, reactivateSpotSuccess: action.success };
}

function reduceUpdateSpot(state: any, action: any) {
  return { ...state, updateSpotSuccess: undefined, errorCode: undefined };
}

function reduceUpdateSpotCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSpotSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSpotSuccess: action.success };
}

function reduceExportListCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, exportedRecords: action.records };
}

function reduceImportList(state: any, action: any) {
  return { ...state, importSuccess: undefined, errorCode: undefined, importedSpots: undefined };
}

function reduceImportListCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, importSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, importSuccess: action.success, importedSpots: action.spot_ids };
}

function reduceGenerateOfferLinksCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, offerEmail: action.email, offerCodes: action.codes };
}

function reduceSendEmailInvite(state: any, action: any) {
  return { ...state, sendParentInviteEmailSuccess: undefined, errorCode: undefined };
}

function reduceSendEmailInviteCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, sendParentInviteEmailSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, sendParentInviteEmailSuccess: action.success };
}

function reduceWaitlist(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.WAITLIST_FILL:
      return reduceFillWaitlist(state, action);
    case actionTypes.WAITLIST_FILL_CALLBACK:
      return reduceFillWaitlistCallback(state, action);
    case actionTypes.WAITLIST_LIST_WAITLIST_CALLBACK:
      return reduceListWaitlistCallback(state, action);
    case actionTypes.WAITLIST_FORM_URL:
      return reduceFormUrl(state, action);
    case actionTypes.WAITLIST_FORM_URL_CALLBACK:
      return reduceFormUrlCallback(state, action);
    case actionTypes.WAITLIST_FEES_CALLBACK:
      return reduceGetFeesCallback(state, action);
    case actionTypes.WAITLIST_SET_FEES_LOADING:
      return reduceSetFees(state, action);
    case actionTypes.WAITLIST_SET_FEES_SUCCESS:
    case actionTypes.WAITLIST_SET_FEES_ERROR:
      return reduceSetFeesCallback(state, action);
    case actionTypes.WAITLIST_SPOT_READ:
      return reduceReadSpot(state, action);
    case actionTypes.WAITLIST_SPOT_READ_CALLBACK:
      return reduceReadSpotCallback(state, action);
    case actionTypes.WAITLIST_SPOT_READ_SEAT:
      return reduceReadSeat(state, action);
    case actionTypes.WAITLIST_SPOT_READ_SEAT_CALLBACK:
      return reduceReadSeatCallback(state, action);
    case actionTypes.WAITLIST_SPOT_ADD:
      return reduceAddSpot(state, action);
    case actionTypes.WAITLIST_SPOT_ADD_CALLBACK:
      return reduceAddSpotCallback(state, action);
    case actionTypes.WAITLIST_SPOT_REMOVE:
      return reduceRemoveSpot(state, action);
    case actionTypes.WAITLIST_SPOT_REMOVE_CALLBACK:
      return reduceRemoveSpotCallback(state, action);
    case actionTypes.WAITLIST_SPOT_REACTIVATE:
      return reduceReactivateSpot(state, action);
    case actionTypes.WAITLIST_SPOT_REACTIVATE_CALLBACK:
      return reduceReactivateSpotCallback(state, action);
    case actionTypes.WAITLIST_SPOT_UPDATE:
      return reduceUpdateSpot(state, action);
    case actionTypes.WAITLIST_SPOT_UPDATE_CALLBACK:
      return reduceUpdateSpotCallback(state, action);
    case actionTypes.WAITLIST_EXPORT_LIST_CALLBACK:
      return reduceExportListCallback(state, action);
    case actionTypes.WAITLIST_IMPORT_LIST:
      return reduceImportList(state, action);
    case actionTypes.WAITLIST_IMPORT_LIST_CALLBACK:
      return reduceImportListCallback(state, action);
    case actionTypes.WAITLIST_GENERATE_OFFER_LINKS_CALLBACK:
      return reduceGenerateOfferLinksCallback(state, action);
    case actionTypes.WAITLIST_SEND_EMAIL_INVITE_CALLBACK:
      return reduceSendEmailInviteCallback(state, action);
    case actionTypes.WAITLIST_SEND_EMAIL_INVITE:
      return reduceSendEmailInvite(state, action);
    default:
      return state;
  }
}

export default reduceWaitlist;

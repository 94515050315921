import { Center, Navigator } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function addNavigator(navigator: Navigator) {
  return {
    type: actionTypes.NAVIGATOR_ADD,
    navigator,
  };
}

export function callbackAddNavigator(navigator_id: string, errorCode: string | undefined) {
  return {
    type: actionTypes.NAVIGATOR_ADD_CALLBACK,
    navigator_id,
    errorCode,
  };
}

export function updateNavigator(navigator: Navigator) {
  return {
    type: actionTypes.NAVIGATOR_UPDATE,
    navigator,
  };
}

export function callbackUpdateNavigator(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.NAVIGATOR_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

export function readNavigator(navigator_id: string) {
  return {
    type: actionTypes.NAVIGATOR_READ,
    navigator_id,
  };
}

export function callbackReadNavigator(navigator: Navigator, errorCode: string | undefined) {
  return {
    type: actionTypes.NAVIGATOR_READ_CALLBACK,
    navigator,
    errorCode,
  };
}

export function readNavigatorFamilies(navigator_id: string) {
  return {
    type: actionTypes.NAVIGATOR_READ_FAMILIES,
    navigator_id,
  };
}

export function callbackReadNavigatorFamilies(families: any, errorCode: string | undefined) {
  return {
    type: actionTypes.NAVIGATOR_READ_FAMILIES_CALLBACK,
    families,
  };
}

export function readNavigatorCenters(parent_id: string) {
  return {
    type: actionTypes.NAVIGATOR_READ_CENTERS,
    parent_id,
  };
}

export function callbackReadNavigatorCenters(waitlists: Center[], tours: Center[], favoriteOnly: Center[], centerData: any[], favorites: string[], errorCode: string | undefined) {
  return {
    type: actionTypes.NAVIGATOR_READ_CENTERS_CALLBACK,
    waitlists,
    tours,
    favoriteOnly,
    centerData,
    favorites,
    errorCode,
  };
}

export function readNavigatorStatus(navigator_id: string) {
  return {
    type: actionTypes.NAVIGATOR_READ_STATUS,
    navigator_id,
  };
}

export function callbackReadNavigatorStatus(lists: any[], seats: any[], tours: any[], marketWaitlistId: string | undefined, legupMarketId: string | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.NAVIGATOR_READ_STATUS_CALLBACK,
    lists,
    seats,
    tours,
    marketWaitlistId,
    legupMarketId,
    errorCode,
  };
}

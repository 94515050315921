import { Center, Child, Parent, Tour, TourRequest } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultSearchState {
  currentTour: Tour | undefined;
  currentTourRequest: TourRequest | undefined;
  actionableTourRequests: TourRequest[] | undefined;
  addSuccess: boolean | undefined;
  updateSuccess: boolean | undefined;
  removeSuccess: boolean | undefined;
  removeTourRequestSuccess: boolean | undefined;
  addScheduleSuccess: boolean | undefined;
  updateScheduleSuccess: boolean | undefined;
  removeScheduleSuccess: boolean | undefined;
  errorCode: string | undefined;
}

const defaultProductState: IDefaultSearchState = {
  currentTour: undefined,
  currentTourRequest: undefined,
  actionableTourRequests: undefined,
  addSuccess: undefined,
  updateSuccess: undefined,
  removeSuccess: undefined,
  removeTourRequestSuccess: undefined,
  addScheduleSuccess: undefined,
  updateScheduleSuccess: undefined,
  removeScheduleSuccess: undefined,
  errorCode: undefined,
};

function reduceReadTourCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const currentTour: Tour = new Tour();
  currentTour.buildFromJSON(action.tour);

  return { ...state, currentTour };
}

function reduceAddTour(state: any, action: any) {
  return { ...state, addSuccess: undefined };
}

function reduceAddTourCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, addSuccess: action.success };
}

function reduceUpdateTour(state: any, action: any) {
  return { ...state, updateSuccess: undefined };
}

function reduceUpdateTourCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSuccess: action.success };
}

function reduceRemoveTour(state: any, action: any) {
  return { ...state, removeSuccess: undefined };
}

function reduceRemoveTourCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, removeSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, removeSuccess: action.success };
}

function reduceReadTourRequestCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const currentTourRequest: TourRequest = new TourRequest();
  currentTourRequest.buildFromJSON(action.request);

  return { ...state, currentTourRequest };
}

function reduceReadActionableTourRequestsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const actionableTourRequests: TourRequest[] = [];
  action.requests.forEach(r => {
    const request: TourRequest = new TourRequest();
    request.buildFromJSON(r);
    actionableTourRequests.push(request);
  });

  return { ...state, actionableTourRequests };
}

function reduceRemoveTourRequest(state: any, action: any) {
  return { ...state, removeTourRequestSuccess: undefined };
}

function reduceRemoveTourRequestCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, removeTourRequestSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, removeTourRequestSuccess: action.success };
}

function reduceAddTourSchedule(state: any, action: any) {
  return { ...state, addScheduleSuccess: undefined };
}

function reduceAddTourScheduleCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addScheduleSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, addScheduleSuccess: action.success };
}

function reduceUpdateTourSchedule(state: any, action: any) {
  return { ...state, updateScheduleSuccess: undefined };
}

function reduceUpdateTourScheduleCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateScheduleSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateScheduleSuccess: action.success };
}

function reduceRemoveTourSchedule(state: any, action: any) {
  return { ...state, removeScheduleSuccess: undefined };
}

function reduceRemoveTourScheduleCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, removeScheduleSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, removeScheduleSuccess: action.success };
}

function reduceTour(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.TOUR_READ_CALLBACK:
      return reduceReadTourCallback(state, action);
    case actionTypes.TOUR_ADD:
      return reduceAddTour(state, action);
    case actionTypes.TOUR_ADD_CALLBACK:
      return reduceAddTourCallback(state, action);
    case actionTypes.TOUR_UPDATE:
      return reduceUpdateTour(state, action);
    case actionTypes.TOUR_UPDATE_CALLBACK:
      return reduceUpdateTourCallback(state, action);
    case actionTypes.TOUR_REMOVE:
      return reduceRemoveTour(state, action);
    case actionTypes.TOUR_REMOVE_CALLBACK:
      return reduceRemoveTourCallback(state, action);
    case actionTypes.TOUR_REQUEST_READ_CALLBACK:
      return reduceReadTourRequestCallback(state, action);
    case actionTypes.TOUR_REQUEST_ACTIONABLE_CALLBACK:
      return reduceReadActionableTourRequestsCallback(state, action);
    case actionTypes.TOUR_REQUEST_DELETE:
      return reduceRemoveTourRequest(state, action);
    case actionTypes.TOUR_REQUEST_DELETE_CALLBACK:
      return reduceRemoveTourRequestCallback(state, action);
    case actionTypes.TOUR_SCHEDULE_ADD:
      return reduceAddTourSchedule(state, action);
    case actionTypes.TOUR_SCHEDULE_ADD_CALLBACK:
      return reduceAddTourScheduleCallback(state, action);
    case actionTypes.TOUR_SCHEDULE_UPDATE:
      return reduceUpdateTourSchedule(state, action);
    case actionTypes.TOUR_SCHEDULE_UPDATE_CALLBACK:
      return reduceUpdateTourScheduleCallback(state, action);
    case actionTypes.TOUR_SCHEDULE_DELETE:
      return reduceRemoveTourSchedule(state, action);
    case actionTypes.TOUR_SCHEDULE_DELETE_CALLBACK:
      return reduceRemoveTourScheduleCallback(state, action);
    default:
      return state;
  }
}

export default reduceTour;

import "flexpad/dist/flexpad.css";
import "mpxx/mpxx.min.css";
import "../src/styles";

import { SplitFactory } from "@splitsoftware/splitio-react";
import SplitIO from "@splitsoftware/splitio-react/types/splitio/splitio";

import React from "react";
import App from "next/app";
import Head from "next/head";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import withRedux from "next-redux-wrapper";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { theme } from "@legup/legup-react-components";

import reduxStore from "../src/store";
import fetchIntercept from "../infra/fetchInterceptor";
import strings from "../infra/constants/strings";
import { initGA } from "../infra/utils";
import "react-quill/dist/quill.snow.css";

const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: process.env.splitClientKey,
    key: "legup-admin",
  },
};

type AppProps = {
  Component: any,
  pageProps: any,
  store: any,
};

class MyApp extends App<AppProps> {
  static async getInitialProps({ Component, ctx }) {
    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {};

    return (Object.keys(pageProps).length > 0) ? { pageProps } : { pageProps: undefined };
  }

  state = {
    appTitle: strings.appTitle,
    launchIntercom: false,
  };

  componentDidMount() {
    fetchIntercept(reduxStore(undefined).dispatch);

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    // Init GA
    if (!(window as any).GA_INITIALIZED) {
      initGA();
      (window as any).GA_INITIALIZED = true;
    }

    if ((window as any).location.hostname.indexOf("family.") > -1) {
      this.setState({ appTitle: strings.appTitleParent });
    }

    this.setState({ launchIntercom: !!process.env.intercomAppId });
  }

  render() {
    const { Component, pageProps, store } = this.props;

    return (
      <SplitFactory config={sdkConfig}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Provider store={store}>
            <PersistGate persistor={store.__PERSISTOR} loading={null}>
              <Head>
                {/* Google Tag Manager */}
                <script>
                  {"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-N2QW9SR9');"}
                </script>
                {/* End Google Tag Manager */}

                <title>{this.state.appTitle}</title>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
                <link rel="stylesheet" href="https://use.typekit.net/lit5jgz.css" />
                {this.state.launchIntercom && (
                  <script>
                    {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.intercomAppId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
                  </script>
                )}

                {!!process.env.POSTHOG_PROJECT_API_KEY && (
                  <script>
                    {`
                      !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host.replace(".i.posthog.com","-assets.i.posthog.com")+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="init push capture register register_once register_for_session unregister unregister_for_session getFeatureFlag getFeatureFlagPayload isFeatureEnabled reloadFeatureFlags updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures on onFeatureFlags onSessionId getSurveys getActiveMatchingSurveys renderSurvey canRenderSurvey getNextSurveyStep identify setPersonProperties group resetGroups setPersonPropertiesForFlags resetPersonPropertiesForFlags setGroupPropertiesForFlags resetGroupPropertiesForFlags reset get_distinct_id getGroups get_session_id get_session_replay_url alias set_config startSessionRecording stopSessionRecording sessionRecordingStarted loadToolbar get_property getSessionProperty createPersonProfile opt_in_capturing opt_out_capturing has_opted_in_capturing has_opted_out_capturing clear_opt_in_out_capturing debug".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
                      posthog.init('${process.env.POSTHOG_PROJECT_API_KEY}',{api_host:'https://us.i.posthog.com', person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
                      })
                    `}
                  </script>
                )}

                {!!(process.env.SEGMENT_ENABLED && process.env.SEGMENT_WEBSITE_WRITE_KEY) && (
                  <script>
                    {`
                      !function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="${process.env.SEGMENT_WEBSITE_WRITE_KEY}";;analytics.SNIPPET_VERSION="5.2.0";
                      analytics.load("${process.env.SEGMENT_WEBSITE_WRITE_KEY}");
                      analytics.page();
                      }}();
                    `}
                  </script>
                )}
              </Head>
              <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Component {...pageProps} />
              </ThemeProvider>
            </PersistGate>
          </Provider>
        </MuiPickersUtilsProvider>
      </SplitFactory>
    );
  }
}

export default withRedux(reduxStore)(MyApp);

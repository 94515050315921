import { Tour, TourRequest, TourSchedule } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function readTour(tour_id: string) {
  return {
    type: actionTypes.TOUR_READ,
    tour_id,
  };
}

export function callbackReadTour(tour: Tour, errorCode: string | undefined) {
  return {
    type: actionTypes.TOUR_READ_CALLBACK,
    tour,
    errorCode,
  };
}

export function addTour(tour: Tour, sendMail: boolean) {
  return {
    type: actionTypes.TOUR_ADD,
    tour,
    sendMail,
  };
}

export function callbackAddTour(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.TOUR_ADD_CALLBACK,
    success,
    errorCode,
  };
}

export function updateTour(tour: Tour, sendMail: boolean, requestReschedule: boolean, parentRequest: boolean) {
  return {
    type: actionTypes.TOUR_UPDATE,
    tour,
    sendMail,
    requestReschedule,
    parentRequest,
  };
}

export function callbackUpdateTour(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.TOUR_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

export function removeTour(tour_id: string, sendMail: boolean, notes: string | undefined) {
  return {
    type: actionTypes.TOUR_REMOVE,
    tour_id,
    sendMail,
    notes,
  };
}

export function callbackRemoveTour(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.TOUR_REMOVE_CALLBACK,
    success,
    errorCode,
  };
}

export function readTourRequest(request_id: string) {
  return {
    type: actionTypes.TOUR_REQUEST_READ,
    request_id,
  };
}

export function callbackReadTourRequest(request: TourRequest, errorCode: string | undefined) {
  return {
    type: actionTypes.TOUR_REQUEST_READ_CALLBACK,
    request,
    errorCode,
  };
}

export function readActionableTourRequests(provider_id: string, center_id: string) {
  return {
    type: actionTypes.TOUR_REQUEST_ACTIONABLE,
    provider_id,
    center_id,
  };
}

export function callbackReadActionableTourRequests(requests: TourRequest[], errorCode: string | undefined) {
  return {
    type: actionTypes.TOUR_REQUEST_ACTIONABLE_CALLBACK,
    requests,
    errorCode,
  };
}

export function deleteTourRequest(request_id: string, sendMail: boolean, notes: string) {
  return {
    type: actionTypes.TOUR_REQUEST_DELETE,
    request_id,
    sendMail,
    notes,
  };
}

export function callbackDeleteTourRequest(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.TOUR_REQUEST_DELETE_CALLBACK,
    success,
    errorCode,
  };
}

export function addTourSchedule(schedule: TourSchedule) {
  return {
    type: actionTypes.TOUR_SCHEDULE_ADD,
    schedule,
  };
}

export function callbackAddTourSchedule(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.TOUR_SCHEDULE_ADD_CALLBACK,
    success,
    errorCode,
  };
}

export function updateTourSchedule(schedule: TourSchedule) {
  return {
    type: actionTypes.TOUR_SCHEDULE_UPDATE,
    schedule,
  };
}

export function callbackUpdateTourSchedule(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.TOUR_SCHEDULE_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

export function deleteTourSchedule(tour_schedule_id: string) {
  return {
    type: actionTypes.TOUR_SCHEDULE_DELETE,
    tour_schedule_id,
  };
}

export function callbackDeleteScheduleRequest(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.TOUR_SCHEDULE_DELETE_CALLBACK,
    success,
    errorCode,
  };
}

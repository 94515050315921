import { Classroom } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultClassroomState {
  currentClassroom: Classroom | undefined;
  loading: boolean | false;
  readSuccess: boolean | undefined;
  addSuccess: boolean | undefined;
  updateSuccess: boolean | undefined;
  deleteSuccess: boolean | undefined;
  errorCode: string | undefined;
}

const defaultProductState: IDefaultClassroomState = {
  currentClassroom: null,
  loading: false,
  readSuccess: undefined,
  addSuccess: undefined,
  updateSuccess: undefined,
  deleteSuccess: undefined,
  errorCode: undefined,
};

function reduceReadClassroom(state: any, action: any) {
  return { ...state, loading: true, readSuccess: undefined };
}

function reduceReadClassroomCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, loading: false, errorCode: action.errorCode, readSuccess: false };
  }
  const classroom: Classroom = new Classroom();

  classroom.buildFromJSON(action.classroom);

  return { ...state, loading: false, currentClassroom: classroom, readSuccess: true };
}

function reduceAddClassroom(state: any, action: any) {
  return { ...state, loading: true, addSuccess: undefined };
}

function reduceAddClassroomCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, loading: false, addSuccess: false, errorCode: action.errorCode };
  }
  if (action.ageGroup) {
    // Set the ID of the current age group
    const currentClassroom = new Classroom();
    currentClassroom.setId(action.classroom_id);

    return { ...state, loading: false, currentClassroom, addSuccess: true };
  }

  return { ...state, loading: false, addSuccess: true };
  
}

function reduceUpdateClassroom(state: any, action: any) {
  return { ...state, loading: true, updateSuccess: undefined };
}

function reduceUpdateClassroomCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, loading: false, updateSuccess: false, errorCode: action.errorCode };
  }
  
  return { ...state, loading: false, updateSuccess: action.success };
}

function reduceDeleteClassroom(state: any, action: any) {
  return { ...state, loading: true, deleteSuccess: undefined };
}

function reduceDeleteClassroomCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, loading: false, deleteSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, loading: false, deleteSuccess: action.success };
}

function reduceClassroom(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.CLASSROOM_READ_LOADING:
      return reduceReadClassroom(state, action);
    case actionTypes.CLASSROOM_READ_SUCCESS:
    case actionTypes.CLASSROOM_READ_ERROR:
      return reduceReadClassroomCallback(state, action);
    case actionTypes.CLASSROOM_ADD_LOADING:
      return reduceAddClassroom(state, action);
    case actionTypes.CLASSROOM_ADD_SUCCESS:
    case actionTypes.CLASSROOM_ADD_ERROR:
      return reduceAddClassroomCallback(state, action);
    case actionTypes.CLASSROOM_UPDATE_LOADING:
      return reduceUpdateClassroom(state, action);
    case actionTypes.CLASSROOM_UPDATE_SUCCESS:
    case actionTypes.CLASSROOM_UPDATE_ERROR:
      return reduceUpdateClassroomCallback(state, action);
    case actionTypes.CLASSROOM_DELETE_LOADING:
      return reduceDeleteClassroom(state, action);
    case actionTypes.CLASSROOM_DELETE_SUCCESS:
    case actionTypes.CLASSROOM_DELETE_ERROR:
      return reduceDeleteClassroomCallback(state, action);
    default:
      return state;
  }
}

export default reduceClassroom;

import querystring from "querystring";

import { User } from "@legup/legup-model";

const userApi = {
  readUser(headers: Headers, user_id: string, call_kinside?: boolean): Promise<any> {
    const queryParams = querystring.stringify({ call_kinside });
    
    return fetch(`/api/user/${user_id}?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readTips(headers: Headers, user_id: string, groups: string): Promise<any> {
    const queryParams = querystring.stringify({ groups });

    return fetch(`/api/user/${user_id}/tips?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readNotifications(headers: Headers, email: string, date: Date): Promise<any> {
    const queryParams = querystring.stringify({ email, date: date ? date.valueOf() : undefined });

    return fetch(`/api/user/notifications?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateUser(headers: Headers, user: User, avatar: string | undefined): Promise<boolean | undefined> {
    return fetch(`/api/user/${user.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify({ user, avatar }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  setFavorite(headers: Headers, user_id: string, category: string, item: string, favorite: boolean): Promise<boolean | undefined> {
    return fetch(`/api/user/${user_id}/favorite`, {
      headers,
      method: "POST",
      body: JSON.stringify({ category, item, favorite }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  getInteraction(headers: Headers, user_id: string, area: string, prefix: string | undefined): Promise<boolean | undefined> {
    const queryParams = querystring.stringify({ area, prefix });

    return fetch(`/api/user/${user_id}/interaction?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  setInteraction(headers: Headers, user_id: string, area: string, feature: string, timestamp: Date): Promise<boolean | undefined> {
    return fetch(`/api/user/${user_id}/interaction`, {
      headers,
      method: "POST",
      body: JSON.stringify({ area, feature, timestamp }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default userApi;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import userApi from "../api/userApi";
import {
  callbackGetInteraction,
  callbackReadNotifications,
  callbackReadTips,
  callbackReadUser,
  callbackSetFavorite,
  callbackSetInteraction,
  callbackUpdateUser,
} from "../actions/userActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchReadUserSaga(action: any) {
  const state = yield select();
  const data = yield call(userApi.readUser, prepareHeader(state.clientStateReducer), action.user_id, action.call_kinside);
  yield put(callbackReadUser(data.user, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadUserSaga() {
  yield takeLatest(actionTypes.USER_READ, fetchReadUserSaga);
}

function* fetchReadTipsSaga(action: any) {
  const state = yield select();
  const data = yield call(userApi.readTips, prepareHeader(state.clientStateReducer), action.user_id, action.groups);
  yield put(callbackReadTips(data.tips, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadTipsSaga() {
  yield takeLatest(actionTypes.USER_READ_TIPS, fetchReadTipsSaga);
}

function* fetchReadNotificationsSaga(action: any) {
  const state = yield select();
  const data = yield call(userApi.readNotifications, prepareHeader(state.clientStateReducer), action.email, action.date);
  yield put(callbackReadNotifications(data.notifications, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadNotificationsSaga() {
  yield takeLatest(actionTypes.USER_NOTIFICATIONS, fetchReadNotificationsSaga);
}

function* fetchUpdateUserSaga(action: any) {
  const state = yield select();
  const data = yield call(userApi.updateUser, prepareHeader(state.clientStateReducer), action.user, action.avatar);
  const success = !!(data && data.success);
  yield put(callbackUpdateUser(success, data.avatarUrl, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateUserSaga() {
  yield takeLatest(actionTypes.USER_UPDATE, fetchUpdateUserSaga);
}

function* fetchSetFavoriteSaga(action: any) {
  const state = yield select();
  const data = yield call(userApi.setFavorite, prepareHeader(state.clientStateReducer), action.user_id, action.category, action.item, action.favorite);
  const success = !!(data && data.success);
  yield put(callbackSetFavorite(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSetFavoriteSaga() {
  yield takeLatest(actionTypes.USER_SET_FAVORITE, fetchSetFavoriteSaga);
}

function* fetchGetInteractionSaga(action: any) {
  const state = yield select();
  const data = yield call(userApi.getInteraction, prepareHeader(state.clientStateReducer), action.user_id, action.area, action.prefix);
  yield put(callbackGetInteraction(data.interactions, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getGetInteractionSaga() {
  yield takeLatest(actionTypes.USER_GET_INTERACTION, fetchGetInteractionSaga);
}

function* fetchSetInteractionSaga(action: any) {
  const state = yield select();
  const data = yield call(userApi.setInteraction, prepareHeader(state.clientStateReducer), action.user_id, action.area, action.feature, action.timestamp);
  const success = !!(data && data.success);
  yield put(callbackSetInteraction(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getSetInteractionSaga() {
  yield takeLatest(actionTypes.USER_SET_INTERACTION, fetchSetInteractionSaga);
}

function* yieldUserSagas() {
  yield all([
    fork(getReadUserSaga),
    fork(getReadTipsSaga),
    fork(getReadNotificationsSaga),
    fork(getUpdateUserSaga),
    fork(getSetFavoriteSaga),
    fork(getGetInteractionSaga),
    fork(getSetInteractionSaga),
  ]);
}

export default yieldUserSagas;

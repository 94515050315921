import { AuthToken } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultAuthState {
  currentAuthToken: AuthToken | undefined,
  accepted_terms: Date | undefined,
  acceptSuccess: boolean | undefined,
  lastLogin: Date | undefined,
  brand: { id: string, name: string, logo_url: string } | undefined, 
  errorCode: string | undefined,
}

const defaultAuthState : IDefaultAuthState = {
  currentAuthToken: undefined,
  accepted_terms: undefined,
  acceptSuccess: undefined,
  lastLogin: undefined,
  brand: undefined,
  errorCode: undefined,
};

function reduceSignInTokenCallback(state: any, action: any) {
  if (action.errorCode) {
    let errorCode = action.errorCode;
    if ((errorCode === "INVALIDUSER") && action.authToken?.email) {
      errorCode = `${errorCode}:${action.authToken.email}`;
    }
    else if ((errorCode === "UNREGISTEREDUSER") && action.authToken?.email && action.authToken?.id_token) {
      errorCode = `${errorCode}:${action.authToken.id_token}:${action.authToken.email}`;
    }

    return { ...state, errorCode };
  }

  const authToken: AuthToken = new AuthToken();
  authToken.buildFromJSON(action.authToken);

  return { ...state,
    currentAuthToken: authToken,
    accepted_terms: action.accepted_terms ? new Date(action.accepted_terms) : undefined,
    last_login: action.last_login,
    brand: action.brand,
  };
}

function reduceAcceptTermsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, acceptSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, acceptSuccess: action.success };
}

function reduceProviders(state = defaultAuthState, action: any) {
  switch (action.type) {
    case actionTypes.SIGN_IN_TOKEN_CALLBACK:
      return reduceSignInTokenCallback(state, action);
    case actionTypes.ACCEPT_TERMS_CALLBACK:
      return reduceAcceptTermsCallback(state, action);
    default:
      return state;
  }
}

export default reduceProviders;

import querystring from "querystring";

import { Parent } from "@legup/legup-model";

const parentApi = {
  findParents(headers: Headers, name: string, provider_id: string, center_id: string): Promise<Parent[]> {
    const queryParams = querystring.stringify({ name, provider: provider_id, center: center_id });

    return fetch(`/api/parent/find?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readParent(headers: Headers, parent_id: string): Promise<Parent> {
    return fetch(`/api/parent/${parent_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readParentStatus(headers: Headers, parent_id: string, partner_id: string): Promise<Parent> {
    return fetch(`/api/parent/${parent_id}/status`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readFamilyCenters(headers: Headers, parent_id: string, center_id: string): Promise<Parent> {
    let url;
    if (center_id) {
      const queryParams = querystring.stringify({ center: center_id });
      url = `/api/parent/${parent_id}/centers?${queryParams}`;
    }
    else {
      url = `/api/parent/${parent_id}/centers`;
    }

    return fetch(url, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addParent(headers: Headers, parent: Parent, children: string[] | undefined): Promise<string> {
    const body = {
      parent,
      grantAccess: !!(children && children.length),
      children,
    };

    return fetch("/api/parent", {
      headers,
      method: "POST",
      body: JSON.stringify(body),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateParent(headers: Headers, parent: Parent): Promise<boolean> {
    return fetch(`/api/parent/${parent.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify({ parent }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateParentSubsidies(headers: Headers, parent_id: string, subsidies: string[] | undefined): Promise<boolean> {
    return fetch(`/api/parent/${parent_id}/subsidies`, {
      headers,
      method: "POST",
      body: JSON.stringify({ subsidies }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  mergeChildren(headers: Headers, parent_id: string, child_id_from: string, child_id_to: string): Promise<boolean> {
    return fetch(`/api/parent/${parent_id}/merge`, {
      headers,
      method: "POST",
      body: JSON.stringify({ child_id_from, child_id_to }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default parentApi;

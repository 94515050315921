import { CenterQuestion, LegUpList } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultLegupListState {
  legupLists: LegUpList[] | undefined;
  currentLegupList: LegUpList | undefined;
  updateQuestionSuccess: boolean | undefined;
  errorCode: string | undefined;
}
const defaultProductState: IDefaultLegupListState = {
  legupLists: undefined,
  currentLegupList: undefined,
  updateQuestionSuccess: undefined,
  errorCode: undefined,
};

function reduceFindLegupList(state: any, action: any) {
  return { ...state, legupLists: undefined };
}

function reduceFindLegupListCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const legupLists = [];
  action.legupLists.forEach(l => {
    const legupList: LegUpList = new LegUpList();
    legupList.buildFromJSON(l);
    legupLists.push(legupList);
  });

  return { ...state, legupLists };
}

function reduceReadLegupList(state: any, action: any) {
  return { ...state, currentLegupList: undefined };
}

function reduceReadLegupListCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const currentLegupList: LegUpList = new LegUpList();
  currentLegupList.buildFromJSON(action.legupList);

  return { ...state, currentLegupList };
}

function reduceLegupList(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.LEGUPLIST_FIND:
      return reduceFindLegupList(state, action);
    case actionTypes.LEGUPLIST_FIND_CALLBACK:
      return reduceFindLegupListCallback(state, action);
    case actionTypes.LEGUPLIST_READ:
      return reduceReadLegupList(state, action);
    case actionTypes.LEGUPLIST_READ_CALLBACK:
      return reduceReadLegupListCallback(state, action);
    default:
      return state;
  }
}

export default reduceLegupList;

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultCouponState {
  addSuccess: boolean | undefined;
  updateSuccess: boolean | undefined;
  errorCode: string | undefined;
}

const defaultProductState: IDefaultCouponState = {
  addSuccess: undefined,
  updateSuccess: undefined,
  errorCode: undefined,
};

function reduceAddCoupon(state: any, action: any) {
  return { ...state, addSuccess: undefined, errorCode: undefined };
}

function reduceAddCouponCallback(state: any, action: any) {
  return { ...state, addSuccess: action.success, errorCode: action.errorCode };
}

function reduceUpdateCoupon(state: any, action: any) {
  return { ...state, updateSuccess: undefined, errorCode: undefined };
}

function reduceUpdateCouponCallback(state: any, action: any) {
  return { ...state, updateSuccess: action.success, errorCode: action.errorCode };
}

function reduceCoupons(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.COUPON_ADD:
      return reduceAddCoupon(state, action);
    case actionTypes.COUPON_ADD_CALLBACK:
      return reduceAddCouponCallback(state, action);
    case actionTypes.COUPON_UPDATE:
      return reduceUpdateCoupon(state, action);
    case actionTypes.COUPON_UPDATE_CALLBACK:
      return reduceUpdateCouponCallback(state, action);
    default:
      return state;
  }
}

export default reduceCoupons;

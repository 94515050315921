import { AgeGroup } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultAgeGroupState {
  currentAgeGroup: AgeGroup | undefined;
  addSuccess: boolean | undefined;
  updateSuccess: boolean | undefined;
  deleteSuccess: boolean | undefined;
  errorCode: string | undefined;
}

const defaultProductState: IDefaultAgeGroupState = {
  currentAgeGroup: null,
  addSuccess: undefined,
  updateSuccess: undefined,
  deleteSuccess: undefined,
  errorCode: undefined,
};

function reduceReadAgeGroupCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const ageGroup: AgeGroup = new AgeGroup();

  ageGroup.buildFromJSON(action.ageGroup);

  return { ...state, currentAgeGroup: ageGroup };
}

function reduceAddAgeGroup(state: any, action: any) {
  return { ...state, addSuccess: undefined };
}

function reduceAddAgeGroupCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addSuccess: false, errorCode: action.errorCode };
  }

  if (action.ageGroup) {
    // Set the ID of the current age group
    const currentAgeGroup = new AgeGroup();
    currentAgeGroup.buildFromJSON(action.ageGroup);

    return { ...state, currentAgeGroup, addSuccess: true };
  }
 
  return { ...state, addSuccess: false };
  
}

function reduceUpdateAgeGroup(state: any, action: any) {
  return { ...state, updateSuccess: undefined };
}

function reduceUpdateAgeGroupCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSuccess: action.success };
}

function reduceDeleteAgeGroup(state: any, action: any) {
  return { ...state, deleteSuccess: undefined };
}

function reduceDeleteAgeGroupCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, deleteSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, deleteSuccess: action.success };
}

function reduceAgeGroup(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.AGEGROUP_READ_CALLBACK:
      return reduceReadAgeGroupCallback(state, action);
    case actionTypes.AGEGROUP_ADD:
      return reduceAddAgeGroup(state, action);
    case actionTypes.AGEGROUP_ADD_CALLBACK:
      return reduceAddAgeGroupCallback(state, action);
    case actionTypes.AGEGROUP_UPDATE:
      return reduceUpdateAgeGroup(state, action);
    case actionTypes.AGEGROUP_UPDATE_CALLBACK:
      return reduceUpdateAgeGroupCallback(state, action);
    case actionTypes.AGEGROUP_DELETE:
      return reduceDeleteAgeGroup(state, action);
    case actionTypes.AGEGROUP_DELETE_CALLBACK:
      return reduceDeleteAgeGroupCallback(state, action);
    default:
      return state;
  }
}

export default reduceAgeGroup;

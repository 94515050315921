import querystring from "querystring";

import { Partner } from "@legup/legup-model";

const partnerApi = {
  findPartners(headers: Headers, name: string): Promise<Partner[]> {
    const queryParams = querystring.stringify({ name });

    return fetch(`/api/partner/find?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readPartner(headers: Headers, partner_id: string): Promise<Partner> {
    return fetch(`/api/partner/${partner_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default partnerApi;

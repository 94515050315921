import { AdminUnit, Provider } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultAdminUnitState {
  adminUnitList: AdminUnit[];
  currentAdminUnit: AdminUnit,
  addSuccess: boolean | undefined;
  updateSuccess: boolean | undefined;
  addedAdminUnitId: string | undefined;
  errorCode: string | undefined;
}
const defaultProductState: IDefaultAdminUnitState = {
  adminUnitList: undefined,
  currentAdminUnit: undefined,
  addSuccess: undefined,
  updateSuccess: undefined,
  addedAdminUnitId: undefined,
  errorCode: undefined,
};

function reduceFindAdminUnitsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const adminUnitList: AdminUnit[] = [];

  if (action.adminUnitList) {
    action.adminUnitList.forEach((a: any) => {
      const adminUnit: AdminUnit = new AdminUnit();
      adminUnit.buildFromJSON(a);
      adminUnitList.push(adminUnit);
    });
  }

  return { ...state, adminUnitList };
}

function reduceReadAdminUnitCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }
  const currentAdminUnit: AdminUnit = new AdminUnit();
  currentAdminUnit.buildFromJSON(action.adminUnit);

  return { ...state, currentAdminUnit };
}

function reduceAddAdminUnit(state: any, action: any) {
  return { ...state, addSuccess: undefined };
}

function reduceAddAdminUnitCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, addedAdminUnitId: action.admin_unit_id, addSuccess: action.success };
}

function reduceUpdateAdminUnit(state: any, action: any) {
  return { ...state, updateSuccess: undefined };
}

function reduceUpdateAdminUnitCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSuccess: action.success };
}

function reduceAdminUnits(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.ADMIN_UNIT_FIND_CALLBACK:
      return reduceFindAdminUnitsCallback(state, action);
    case actionTypes.ADMIN_UNIT_READ_CALLBACK:
      return reduceReadAdminUnitCallback(state, action);
    case actionTypes.ADMIN_UNIT_ADD:
      return reduceAddAdminUnit(state, action);
    case actionTypes.ADMIN_UNIT_ADD_CALLBACK:
      return reduceAddAdminUnitCallback(state, action);
    case actionTypes.ADMIN_UNIT_UPDATE:
      return reduceUpdateAdminUnit(state, action);
    case actionTypes.ADMIN_UNIT_UPDATE_CALLBACK:
      return reduceUpdateAdminUnitCallback(state, action);
    default:
      return state;
  }
}

export default reduceAdminUnits;

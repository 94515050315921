import actionTypes from "../infra/constants/actionTypes";

export function openChat(center_id: string) {
  return {
    type: actionTypes.CHAT_OPEN,
    center_id,
  };
}

export function closeChat() {
  return {
    type: actionTypes.CHAT_CLOSE,
  };
}

export function sendMessage(center_id: string, message: string) {
  return {
    type: actionTypes.CHAT_SEND,
    center_id,
    message,
  };
}

export function readMessages(to_contact: any, from_contact: any) {
  return {
    type: actionTypes.CHAT_READ,
    to_contact,
    from_contact,
  };
}

export function callbackReadMessages(messages: string[], success: string) {
  return {
    type: actionTypes.CHAT_READ_CALLBACK,
    messages,
    success,
  };
}

export function readMessageThread(thread:any) {
  return {
    type: actionTypes.COMMS_THREAD_READ,
    thread,
  };
}

export function callbackReadMessageThread(messages: string[], success: string) {
  return {
    type: actionTypes.COMMS_THREAD_READ_CALLBACK,
    messages,
    success,
  };
}

export function readInboxMessages(to_contact: any) {
  return {
    type: actionTypes.COMMS_INBOX_READ,
    to_contact,
  };
}

export function resetFolderMessages() {
  return {
    type: actionTypes.COMMS_RESET_FOLDER_MESSAGES,
  };
}

export function callbackReadInboxMessages(messages: string[], success: string) {
  return {
    type: actionTypes.COMMS_INBOX_READ_CALLBACK,
    messages,
    success,
  };
}

export function readSentMessages(from_contact: any) {
  return {
    type: actionTypes.COMMS_SENT_READ,
    from_contact,
  };
}

export function callbackReadSentMessages(messages: string[], success: string) {
  return {
    type: actionTypes.COMMS_SENT_READ_CALLBACK,
    messages,
    success,
  };
}

export function readParentContacts(center_id: any) {
  return {
    type: actionTypes.COMMS_PARENT_CONTACTS_READ,
    center_id,
  };
}

export function callbackReadParentContacts(contacts: any[], success: boolean) {
  return {
    type: actionTypes.COMMS_PARENT_CONTACTS_READ_CALLBACK,
    contacts,
    success,
  };
}

export function readCenterContacts(center_id: any) {
  return {
    type: actionTypes.COMMS_CENTER_CONTACTS_READ,
    center_id,
  };
}

export function callbackReadCenterContacts(contacts: string[], success: string) {
  return {
    type: actionTypes.COMMS_CENTER_CONTACTS_READ_CALLBACK,
    contacts,
    success,
  };
}

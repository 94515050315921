import actionTypes from "../infra/constants/actionTypes";

interface IDefaultSubsidyState {
  subsidies: Array<{code: string, name: string}> | undefined;
  enabledSubsidies: string[] | undefined;
  errorCode: string | undefined;
}
const defaultProductState: IDefaultSubsidyState = {
  subsidies: undefined,
  enabledSubsidies: undefined,
  errorCode: undefined,
};

function reduceListSubsidiesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, subsidies: action.subsidies, enabledSubsidies: action.enabledSubsidies };
}

function reduceSubsidy(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.LIST_SUBSIDIES_CALLBACK:
      return reduceListSubsidiesCallback(state, action);
    default:
      return state;
  }
}

export default reduceSubsidy;

import querystring from "querystring";

import { Classroom } from "@legup/legup-model";

const classroomApi = {
  readClassroom(headers: Headers, classroom_id: string): Promise<Classroom | undefined> {
    return fetch(`/api/classroom/${classroom_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addClassroom(headers: Headers, classroom: Classroom): Promise<string | undefined> {
    const queryParams = querystring.stringify({ agegroup: classroom.getAgeGroupId() });

    return fetch(`/api/classroom?${queryParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify(classroom),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateClassroom(headers: Headers, classroom: Classroom): Promise<string> {
    return fetch(`/api/classroom/${classroom.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify(classroom),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  deleteClassroom(headers: Headers, classroom: Classroom): Promise<string> {
    return fetch(`/api/classroom/${classroom.getId()}`, {
      headers,
      method: "DELETE",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default classroomApi;

import { Partner } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function findPartners(name: string) {
  return {
    type: actionTypes.PARTNER_FIND,
    name,
  };
}

export function callbackFindPartners(partnerList: Partner[], errorCode: string | undefined) {
  return {
    type: actionTypes.PARTNER_FIND_CALLBACK,
    partnerList,
    errorCode,
  };
}

export function readPartner(partner_id: string) {
  return {
    type: actionTypes.PARTNER_READ,
    partner_id,
  };
}

export function callbackReadPartner(partner: Partner, errorCode: string | undefined) {
  return {
    type: actionTypes.PARTNER_READ_CALLBACK,
    partner,
    errorCode,
  };
}

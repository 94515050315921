
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import adminunitApi from "../api/adminunitApi";
import {
  callbackAddAdminUnit,
  callbackFindAdminUnits,
  callbackReadAdminUnit,
  callbackUpdateAdminUnit,
} from "../actions/adminunitActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchFindAdminUnitsSaga(action: any) {
  const state = yield select();
  const data = yield call(adminunitApi.findAdminUnits, prepareHeader(state.clientStateReducer), action.name);
  yield put(callbackFindAdminUnits(data.adminUnits, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getFindAdminUnitsSaga() {
  yield takeLatest(actionTypes.ADMIN_UNIT_FIND, fetchFindAdminUnitsSaga);
}

function* fetchReadAdminUnitSaga(action: any) {
  const state = yield select();
  const data = yield call(adminunitApi.readAdminUnit, prepareHeader(state.clientStateReducer), action.admin_unit_id);
  yield put(callbackReadAdminUnit(data.adminUnit, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadAdminUnitSaga() {
  yield takeLatest(actionTypes.ADMIN_UNIT_READ, fetchReadAdminUnitSaga);
}

function* fetchAddAdminUnitSaga(action: any) {
  const state = yield select();
  const data = yield call(adminunitApi.addAdminUnit, prepareHeader(state.clientStateReducer), action.adminUnit);
  const admin_unit_id = (data && data.admin_unit_id) ? data.admin_unit_id : undefined;
  yield put(callbackAddAdminUnit(admin_unit_id, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddAdminUnitSaga() {
  yield takeLatest(actionTypes.ADMIN_UNIT_ADD, fetchAddAdminUnitSaga);
}

function* fetchUpdateAdminUnitSaga(action: any) {
  const state = yield select();
  const data = yield call(adminunitApi.updateAdminUnit, prepareHeader(state.clientStateReducer), action.adminUnit);
  const success = (data && data.success) ? data.success : undefined;
  yield put(callbackUpdateAdminUnit(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateAdminUnitSaga() {
  yield takeLatest(actionTypes.ADMIN_UNIT_UPDATE, fetchUpdateAdminUnitSaga);
}

function* yieldAdminUnitSagas() {
  yield all([
    fork(getFindAdminUnitsSaga),
    fork(getReadAdminUnitSaga),
    fork(getAddAdminUnitSaga),
    fork(getUpdateAdminUnitSaga),
  ]);
}

export default yieldAdminUnitSagas;

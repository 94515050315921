import { Lead } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function addLead(center_id: string, lead: Lead) {
  return {
    type: actionTypes.LEAD_ADD,
    center_id,
    lead,
  };
}

export function callbackAddLead(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.LEAD_ADD_CALLBACK,
    success,
    errorCode,
  };
}

export function updateLead(lead: Lead) {
  return {
    type: actionTypes.LEAD_UPDATE,
    lead,
  };
}

export function callbackUpdateLead(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.LEAD_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import tourApi from "../api/tourApi";
import {
  callbackAddTour,
  callbackAddTourSchedule,
  callbackDeleteScheduleRequest,
  callbackDeleteTourRequest,
  callbackReadActionableTourRequests,
  callbackReadTour,
  callbackReadTourRequest,
  callbackRemoveTour,
  callbackUpdateTour,
  callbackUpdateTourSchedule,
} from "../actions/tourActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchReadTourSaga(action: any) {
  const state = yield select();
  const data = yield call(tourApi.readTour, prepareHeader(state.clientStateReducer), action.tour_id);
  yield put(callbackReadTour(data.tour, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadTourSaga() {
  yield takeLatest(actionTypes.TOUR_READ, fetchReadTourSaga);
}

function* fetchAddTourSaga(action: any) {
  const state = yield select();
  const data = yield call(tourApi.addTour, prepareHeader(state.clientStateReducer), action.tour, action.sendMail);
  const success = !!(data && data.success);
  yield put(callbackAddTour(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddTourSaga() {
  yield takeLatest(actionTypes.TOUR_ADD, fetchAddTourSaga);
}

function* fetchUpdateTourSaga(action: any) {
  const state = yield select();
  const data = yield call(tourApi.updateTour, prepareHeader(state.clientStateReducer), action.tour, action.sendMail, action.requestReschedule, action.parentRequest);
  const success = !!(data && data.success);
  yield put(callbackUpdateTour(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateTourSaga() {
  yield takeLatest(actionTypes.TOUR_UPDATE, fetchUpdateTourSaga);
}

function* fetchRemoveTourSaga(action: any) {
  const state = yield select();
  const data = yield call(tourApi.deleteTour, prepareHeader(state.clientStateReducer), action.tour_id,
    action.sendMail, action.notes);
  const success = !!(data && data.success);
  yield put(callbackRemoveTour(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getRemoveTourSaga() {
  yield takeLatest(actionTypes.TOUR_REMOVE, fetchRemoveTourSaga);
}

function* fetchReadTourRequestSaga(action: any) {
  const state = yield select();
  const data = yield call(tourApi.readTourRequest, prepareHeader(state.clientStateReducer), action.request_id);
  yield put(callbackReadTourRequest(data.request, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadTourRequestSaga() {
  yield takeLatest(actionTypes.TOUR_REQUEST_READ, fetchReadTourRequestSaga);
}

function* fetchReadActionableTourRequestsSaga(action: any) {
  const state = yield select();
  const data = yield call(tourApi.readActionableTourRequests, prepareHeader(state.clientStateReducer), action.provider_id, action.center_id);
  yield put(callbackReadActionableTourRequests(data.requests, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadActionableTourRequestsSaga() {
  yield takeLatest(actionTypes.TOUR_REQUEST_ACTIONABLE, fetchReadActionableTourRequestsSaga);
}

function* fetchRemoveTourRequestSaga(action: any) {
  const state = yield select();
  const data = yield call(tourApi.deleteTourRequest, prepareHeader(state.clientStateReducer), action.request_id, action.sendMail, action.notes);
  const success = !!(data && data.success);
  yield put(callbackDeleteTourRequest(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getRemoveTourRequestSaga() {
  yield takeLatest(actionTypes.TOUR_REQUEST_DELETE, fetchRemoveTourRequestSaga);
}

function* fetchAddTourScheduleSaga(action: any) {
  const state = yield select();
  const data = yield call(tourApi.addTourSchedule, prepareHeader(state.clientStateReducer), action.schedule);
  const success = !!(data && data.success);
  yield put(callbackAddTourSchedule(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddTourScheduleSaga() {
  yield takeLatest(actionTypes.TOUR_SCHEDULE_ADD, fetchAddTourScheduleSaga);
}

function* fetchUpdateTourScheduleSaga(action: any) {
  const state = yield select();
  const data = yield call(tourApi.updateTourSchedule, prepareHeader(state.clientStateReducer), action.schedule);
  const success = !!(data && data.success);
  yield put(callbackUpdateTourSchedule(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateTourScheduleSaga() {
  yield takeLatest(actionTypes.TOUR_SCHEDULE_UPDATE, fetchUpdateTourScheduleSaga);
}

function* fetchRemoveTourScheduleSaga(action: any) {
  const state = yield select();
  const data = yield call(tourApi.deleteTourSchedule, prepareHeader(state.clientStateReducer), action.tour_schedule_id);
  const success = !!(data && data.success);
  yield put(callbackDeleteScheduleRequest(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getRemoveTourScheduleSaga() {
  yield takeLatest(actionTypes.TOUR_SCHEDULE_DELETE, fetchRemoveTourScheduleSaga);
}

function* yieldTourSagas() {
  yield all([
    fork(getReadTourSaga),
    fork(getAddTourSaga),
    fork(getUpdateTourSaga),
    fork(getRemoveTourSaga),
    fork(getReadTourRequestSaga),
    fork(getReadActionableTourRequestsSaga),
    fork(getRemoveTourRequestSaga),
    fork(getAddTourScheduleSaga),
    fork(getUpdateTourScheduleSaga),
    fork(getRemoveTourScheduleSaga),
  ]);
}

export default yieldTourSagas;

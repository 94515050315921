import { applyMiddleware, createStore } from "redux";

import createSagaMiddleware from "redux-saga";

import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";

import initGlobalSaga from "../sagas/initializeSaga";
import mainReducer from "../reducers/mainReducer";

export default initialState => {
  let store;
  const sagaMiddleware = createSagaMiddleware();
  const isClient = typeof window !== "undefined";

  if (isClient) {
    const { persistReducer } = require("redux-persist");
    const storage = require("redux-persist/lib/storage").default;
    const persistConfig = {
      key: "auth0",
      storage,
      whitelist: ["clientStateReducer"],
    };
    store = createStore(
      persistReducer(persistConfig, mainReducer),
      initialState,
      composeWithDevTools(applyMiddleware(sagaMiddleware)),
    );
    store.__PERSISTOR = persistStore(store);
  }
  else {
    store = createStore(
      mainReducer,
      initialState,
      composeWithDevTools(applyMiddleware(sagaMiddleware)),
    );
  }

  store.sagaTask = sagaMiddleware.run(initGlobalSaga);

  return store;
};

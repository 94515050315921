import { Contract, Program } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function readContract(contract_id: string) {
  return {
    type: actionTypes.CONTRACT_READ,
    contract_id,
  };
}

export function callbackReadContract(contract: Contract, errorCode: string | undefined) {
  return {
    type: actionTypes.CONTRACT_READ_CALLBACK,
    contract,
    errorCode,
  };
}

export function addContract(provider_id: string, contract: Contract, program: Program, ageGroupIds: string[]) {
  return {
    type: actionTypes.CONTRACT_ADD,
    provider_id,
    contract,
    program,
    ageGroupIds,
  };
}

export function callbackAddContract(success: boolean, id: string, errorCode: string | undefined) {
  return {
    type: actionTypes.CONTRACT_ADD_CALLBACK,
    success,
    id,
    errorCode,
  };
}

export function updateContract(contract: Contract, program: Program, ageGroupIds: string[]) {
  return {
    type: actionTypes.CONTRACT_UPDATE,
    contract,
    program,
    ageGroupIds,
  };
}

export function callbackUpdateContract(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CONTRACT_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

export function deleteContract(contract_id: string) {
  return {
    type: actionTypes.CONTRACT_DELETE,
    contract_id,
  };
}

export function callbackDeleteContract(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CONTRACT_DELETE_CALLBACK,
    success,
    errorCode,
  };
}

import { combineReducers } from "redux";

import ageGroupReducer from "./ageGroupReducer";
import adminunitReducer from "./adminunitReducer";
import authReducer from "./authReducer";
import centerReducer from "./centerReducer";
import chatReducer from "./chatReducer";

import childReducer from "./childReducer";
import classroomReducer from "./classroomReducer";
import clientStateReducer from "./clientStateReducer";
import contractReducer from "./contractReducer";
import couponReducer from "./couponReducer";
import feedbackReducer from "./feedbackReducer";
import leadReducer from "./leadReducer";
import leguplistReducer from "./leguplistReducer";
import linkReducer from "./linkReducer";
import navigatorReducer from "./navigatorReducer";
import parentReducer from "./parentReducer";
import partnerReducer from "./partnerReducer";
import paymentReducer from "./paymentReducer";
import programReducer from "./programReducer";
import providerReducer from "./providerReducer";
import seatReducer from "./seatReducer";
import searchReducer from "./searchReducer";
import subsidyReducer from "./subsidyReducer";
import tourReducer from "./tourReducer";
import userReducer from "./userReducer";
import waitlistReducer from "./waitlistReducer";

const reducers = combineReducers({
  ageGroupReducer,
  adminunitReducer,
  authReducer,
  centerReducer,
  chatReducer,
  childReducer,
  classroomReducer,
  clientStateReducer,
  contractReducer,
  couponReducer,
  feedbackReducer,
  leadReducer,
  leguplistReducer,
  linkReducer,
  navigatorReducer,
  parentReducer,
  partnerReducer,
  paymentReducer,
  programReducer,
  providerReducer,
  seatReducer,
  searchReducer,
  subsidyReducer,
  tourReducer,
  userReducer,
  waitlistReducer,
});

export default reducers;

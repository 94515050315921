import { Program } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface IDefaultProgramState {
  addSuccess: boolean | undefined;
  updateSuccess: boolean | undefined;
  copySuccess: boolean | undefined;
  deleteSuccess: boolean | undefined;
  errorCode: string | undefined;
}

const defaultProductState: IDefaultProgramState = {
  addSuccess: undefined,
  updateSuccess: undefined,
  copySuccess: undefined,
  deleteSuccess: undefined,
  errorCode: undefined,
};

function reduceAddPrograms(state: any, action: any) {
  return { ...state, addSuccess: undefined };
}

function reduceAddProgramsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, addSuccess: action.success };
}

function reduceUpdateProgram(state: any, action: any) {
  return { ...state, updateSuccess: undefined };
}

function reduceUpdateProgramCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSuccess: action.success };
}

function reduceCopyProgram(state: any, action: any) {
  return { ...state, copySuccess: undefined };
}

function reduceCopyProgramCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, copySuccess: false, errorCode: action.errorCode };
  }

  return { ...state, copySuccess: action.success };
}

function reduceDeleteProgram(state: any, action: any) {
  return { ...state, deleteSuccess: undefined };
}

function reduceDeleteProgramCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, deleteSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, deleteSuccess: action.success };
}

function reduceProgram(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.PROGRAM_ADD:
      return reduceAddPrograms(state, action);
    case actionTypes.PROGRAM_ADD_CALLBACK:
      return reduceAddProgramsCallback(state, action);
    case actionTypes.PROGRAM_UPDATE:
      return reduceUpdateProgram(state, action);
    case actionTypes.PROGRAM_UPDATE_CALLBACK:
      return reduceUpdateProgramCallback(state, action);
    case actionTypes.PROGRAM_COPY:
      return reduceCopyProgram(state, action);
    case actionTypes.PROGRAM_COPY_CALLBACK:
      return reduceCopyProgramCallback(state, action);
    case actionTypes.PROGRAM_DELETE:
      return reduceDeleteProgram(state, action);
    case actionTypes.PROGRAM_DELETE_CALLBACK:
      return reduceDeleteProgramCallback(state, action);
    default:
      return state;
  }
}

export default reduceProgram;

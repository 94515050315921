
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import couponApi from "../api/couponApi";
import {
  callbackAddCoupon,
  callbackUpdateCoupon,
} from "../actions/couponActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchAddCoupon(action: any) {
  const state = yield select();
  const data = yield call(couponApi.addCoupon, prepareHeader(state.clientStateReducer), action.provider_id, action.coupon);
  yield put(callbackAddCoupon(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddCouponSaga() {
  yield takeLatest(actionTypes.COUPON_ADD, fetchAddCoupon);
}

function* fetchUpdateCoupon(action: any) {
  const state = yield select();
  const data = yield call(couponApi.updateCoupon, prepareHeader(state.clientStateReducer), action.coupon, action.reset);
  yield put(callbackUpdateCoupon(data.success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateCouponSaga() {
  yield takeLatest(actionTypes.COUPON_UPDATE, fetchUpdateCoupon);
}

function* yieldCouponSagas() {
  yield all([
    fork(getAddCouponSaga),
    fork(getUpdateCouponSaga),
  ]);
}

export default yieldCouponSagas;

import { setBrand, setEmail, setGroups, setIdToken, setRefreshToken, setRelationship } from "../actions/clientStateActions";

const authRequiredCodes = ["APIINVALIDAUTH", "APIMISSINGAUTH", "AUTHEXPIRED"];

const relogin = (dispatch: any) => {
  dispatch(setIdToken(undefined));
  dispatch(setBrand(undefined));
  dispatch(setEmail(undefined));
  dispatch(setRefreshToken(undefined));
  dispatch(setGroups(undefined));
  dispatch(setRelationship(undefined));
  window.location.reload();
};

const interceptor = (dispatch: any) => {
  const { fetch: originalFetch } = window;

  window.fetch = async (...args) => {
    const [resource, config] = args;
    const response = await originalFetch(resource, config);
    if (!response.headers.get("content-type")?.includes("application/json")) {
      return response;
    }

    const jsonResponse = await response.clone().json();
    if (!jsonResponse.success && authRequiredCodes.includes(jsonResponse.errorCode)) {
      relogin(dispatch);
    }
  
    return response;
  };
};

export default interceptor;

import { Seat, WaitlistSpot } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function fillWaitlist(spot_id: string, seat_id: string, remove: boolean) {
  return {
    type: actionTypes.WAITLIST_FILL,
    spot_id,
    seat_id,
    remove,
  };
}

export function callbackFillWaitlist(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_FILL_CALLBACK,
    success,
    errorCode,
  };
}

export function listWaitlist(list_id: string, states: string[] | undefined) {
  return {
    type: actionTypes.WAITLIST_LIST_WAITLIST,
    list_id,
    states,
  };
}

export function callbackListWaitlist(spots: WaitlistSpot[], errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_LIST_WAITLIST_CALLBACK,
    spots,
    errorCode,
  };
}

export function waitlistFormUrl(provider_id: string | undefined, center_id: string | undefined) {
  return {
    type: actionTypes.WAITLIST_FORM_URL,
    provider_id,
    center_id,
  };
}

export function callbackWaitlistFormUrl(url: any, errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_FORM_URL_CALLBACK,
    url,
    errorCode,
  };
}

export function getFees(provider_id: string | undefined, center_id: string | undefined) {
  return {
    type: actionTypes.WAITLIST_FEES,
    provider_id,
    center_id,
  };
}

export function callbackGetFees(waitlist_fees: any, commission: any, center_count: number, errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_FEES_CALLBACK,
    waitlist_fees,
    commission,
    center_count,
    errorCode,
  };
}

export function setFees(provider_id: string | undefined, center_id: string | undefined, waitlist_fees: any) {
  return {
    type: actionTypes.WAITLIST_SET_FEES_LOADING,
    provider_id,
    center_id,
    waitlist_fees,
  };
}

export function callbackSetFees(success: boolean, errorCode: string | undefined) {
  if (errorCode) {
    return {
      type: actionTypes.WAITLIST_SET_FEES_ERROR,
      success,
      errorCode,
    };

  }

  return {
    type: actionTypes.WAITLIST_SET_FEES_SUCCESS,
    success,
    errorCode,
  };
}

export function addSpot(waitlist_spot: WaitlistSpot) {
  return {
    type: actionTypes.WAITLIST_SPOT_ADD,
    waitlist_spot,
  };
}

export function callbackAddSpot(spot_id: string, success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_SPOT_ADD_CALLBACK,
    spot_id,
    success,
    errorCode,
  };
}

export function readSpot(spot_id: string) {
  return {
    type: actionTypes.WAITLIST_SPOT_READ,
    spot_id,
  };
}

export function callbackReadSpot(waitlist_spot: WaitlistSpot, errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_SPOT_READ_CALLBACK,
    waitlist_spot,
    errorCode,
  };
}

export function readSeat(spot_id: string) {
  return {
    type: actionTypes.WAITLIST_SPOT_READ_SEAT,
    spot_id,
  };
}

export function callbackReadSeat(seat: Seat, errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_SPOT_READ_SEAT_CALLBACK,
    seat,
    errorCode,
  };
}

export function removeSpot(waitlist_spot: WaitlistSpot, removal_reason: string, removalRefund: boolean, deleteSpot: boolean, mailParents: boolean) {
  return {
    type: actionTypes.WAITLIST_SPOT_REMOVE,
    waitlist_spot,
    removal_reason,
    removalRefund,
    deleteSpot,
    mailParents,
  };
}

export function callbackRemoveSpot(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_SPOT_REMOVE_CALLBACK,
    success,
    errorCode,
  };
}

export function reactivateSpot(waitlist_spot: WaitlistSpot) {
  return {
    type: actionTypes.WAITLIST_SPOT_REACTIVATE,
    waitlist_spot,
  };
}

export function callbackReactivateSpot(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_SPOT_REACTIVATE_CALLBACK,
    success,
    errorCode,
  };
}

export function updateSpot(waitlist_spot: WaitlistSpot) {
  return {
    type: actionTypes.WAITLIST_SPOT_UPDATE,
    waitlist_spot,
  };
}

export function callbackUpdateSpot(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_SPOT_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

export function importList(center_id: string, list_id: string, provider: string, center: string, onboard: boolean, records: string[][], initialEmails: boolean) {
  return {
    type: actionTypes.WAITLIST_IMPORT_LIST,
    center_id,
    list_id,
    provider,
    center,
    onboard,
    records,
    initialEmails,
  };
}

export function callbackImportList(success: boolean, errorCode: string | undefined, spot_ids: string[]) {
  return {
    type: actionTypes.WAITLIST_IMPORT_LIST_CALLBACK,
    success,
    errorCode,
    spot_ids,
  };
}

export function exportList(center_id: string, list_id: string, mode: string) {
  return {
    type: actionTypes.WAITLIST_EXPORT_LIST,
    center_id,
    list_id,
    mode,
  };
}

export function callbackExportList(records: string[][] | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_EXPORT_LIST_CALLBACK,
    records,
    errorCode,
  };
}

export function generateOfferLinks(spot_id: string, seat_id: string) {
  return {
    type: actionTypes.WAITLIST_GENERATE_OFFER_LINKS,
    spot_id,
    seat_id,
  };
}

export function callbackGenerateOfferLinks(email: string, codes: any[] | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_GENERATE_OFFER_LINKS_CALLBACK,
    email,
    codes,
    errorCode,
  };
}

export function sendEmailInvite(recipient: string, child_id: string, center_id: string) {
  return {
    type: actionTypes.WAITLIST_SEND_EMAIL_INVITE,
    recipient,
    child_id,
    center_id,
  };
}

export function callbackSendEmailInvite(recipient: string, child_id: string, center_id: string, success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.WAITLIST_SEND_EMAIL_INVITE_CALLBACK,
    recipient,
    child_id,
    center_id,
    success,
    errorCode,
  };
}

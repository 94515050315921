
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import childApi from "../api/childApi";
import {
  callbackAddChild,
  callbackReadChild,
  callbackReadChildNotes,
  callbackReadChildSeats,
  callbackReadChildTours,
  callbackReadChildWaitlistPosition,
  callbackReadChildWaitlists,
  callbackUpdateChild,
} from "../actions/childActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchReadChildSaga(action: any) {
  const state = yield select();
  const data = yield call(childApi.readChild, prepareHeader(state.clientStateReducer), action.child_id);
  yield put(callbackReadChild(data.child, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadChildSaga() {
  yield takeLatest(actionTypes.CHILD_READ, fetchReadChildSaga);
}

function* fetchReadChildWaitlistsSaga(action: any) {
  const state = yield select();
  const data = yield call(childApi.readChildWaitlists, prepareHeader(state.clientStateReducer), action.provider_id, action.child_id);
  yield put(callbackReadChildWaitlists(data.waitlists, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadChildWaitlistsSaga() {
  yield takeLatest(actionTypes.CHILD_READ_WAITLISTS, fetchReadChildWaitlistsSaga);
}

function* fetchReadChildWaitlistPositionSaga(action: any) {
  const state = yield select();
  const data = yield call(childApi.readChildWaitlistPosition, prepareHeader(state.clientStateReducer), action.child_id, action.list_id);
  yield put(callbackReadChildWaitlistPosition(data.positionDetails, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadChildWaitlistPositionSaga() {
  yield takeLatest(actionTypes.CHILD_READ_WAITLIST_POSITION, fetchReadChildWaitlistPositionSaga);
}

function* fetchReadChildSeatsSaga(action: any) {
  const state = yield select();
  const data = yield call(childApi.readChildSeats, prepareHeader(state.clientStateReducer), action.provider_id, action.child_id);
  yield put(callbackReadChildSeats(data.seats, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadChildSeatsSaga() {
  yield takeLatest(actionTypes.CHILD_READ_SEATS, fetchReadChildSeatsSaga);
}

function* fetchReadChildToursSaga(action: any) {
  const state = yield select();
  const data = yield call(childApi.readChildTours, prepareHeader(state.clientStateReducer), action.provider_id, action.child_id);
  yield put(callbackReadChildTours(data.tours, data.tourRequests, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadChildToursSaga() {
  yield takeLatest(actionTypes.CHILD_READ_TOURS, fetchReadChildToursSaga);
}

function* fetchAddChildSaga(action: any) {
  const state = yield select();
  const data = yield call(childApi.addChild, prepareHeader(state.clientStateReducer), action.parent_id, action.child);
  const child_id = (data && data.child_id) ? data.child_id : undefined;
  yield put(callbackAddChild(child_id, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getAddChildSaga() {
  yield takeLatest(actionTypes.CHILD_ADD, fetchAddChildSaga);
}

function* fetchReadChidNotesSaga(action: any) {
  const state = yield select();
  const data = yield call(childApi.readChildNotes, prepareHeader(state.clientStateReducer), action.child_id);
  yield put(callbackReadChildNotes(data.history, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadChildNotesSaga() {
  yield takeLatest(actionTypes.CHILD_READ_NOTES, fetchReadChidNotesSaga);
}

function* fetchUpdateChildSaga(action: any) {
  const state = yield select();
  const data = yield call(childApi.updateChild, prepareHeader(state.clientStateReducer), action.child);
  const success = !!(data && data.success);
  yield put(callbackUpdateChild(success, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getUpdateChildSaga() {
  yield takeLatest(actionTypes.CHILD_UPDATE, fetchUpdateChildSaga);
}

function* yieldChildSagas() {
  yield all([
    fork(getReadChildSaga),
    fork(getReadChildWaitlistsSaga),
    fork(getReadChildWaitlistPositionSaga),
    fork(getReadChildSeatsSaga),
    fork(getReadChildToursSaga),
    fork(getAddChildSaga),
    fork(getReadChildNotesSaga),
    fork(getUpdateChildSaga),
  ]);
}

export default yieldChildSagas;

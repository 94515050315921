import querystring from "querystring";

import { Tour, TourSchedule } from "@legup/legup-model";

const tourApi = {
  readTour(headers: Headers, tour_id: string): Promise<Tour> {
    return fetch(`/api/tour/${tour_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addTour(headers: Headers, tour: Tour, sendMail: boolean) {
    const center = tour.getCenter() ? tour.getCenter().getId() : undefined;
    const queryParams = querystring.stringify({ center, sendmail: sendMail ? 1 : 0 });

    return fetch(`/api/tour?${queryParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify(tour),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateTour(headers: Headers, tour: Tour, sendMail: boolean, requestReschedule: boolean, parentRequest: boolean) {
    const queryParams = querystring.stringify({
      req: parentRequest ? 1 : 0,
      reschedule: requestReschedule ? 1 : 0,
      sendmail: sendMail ? 1 : 0,
    });

    return fetch(`/api/tour/${tour.getId()}?${queryParams}`, {
      headers,
      method: "POST",
      body: JSON.stringify(tour),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  deleteTour(headers: Headers, tour_id: string, sendMail: boolean, notes: string): Promise<boolean> {
    let url;
    if (sendMail) {
      url = `/api/tour/${tour_id}?sendmail=1`;
    }
    else {
      url = `/api/tour/${tour_id}`;
    }

    return fetch(url, {
      headers,
      method: "DELETE",
      body: JSON.stringify({ notes }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
  
  readTourRequest(headers: Headers, request_id: string): Promise<boolean> {
    return fetch(`/api/tour/request/${request_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readActionableTourRequests(headers: Headers, provider_id: string, center_id: string): Promise<Tour> {
    const queryParams = querystring.stringify({ provider: provider_id, center: center_id });

    return fetch(`/api/tour/request/actionable?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  deleteTourRequest(headers: Headers, request_id: string, sendMail: boolean, notes: string): Promise<boolean> {
    let url;
    if (sendMail) {
      url = `/api/tour/request/${request_id}?sendmail=1`;
    }
    else {
      url = `/api/tour/request/${request_id}`;
    }

    return fetch(url, {
      headers,
      method: "DELETE",
      body: JSON.stringify({ notes }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addTourSchedule(headers: Headers, schedule: TourSchedule) {
    return fetch("/api/tour/schedule", {
      headers,
      method: "POST",
      body: JSON.stringify(schedule),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateTourSchedule(headers: Headers, schedule: TourSchedule) {
    return fetch(`/api/tour/schedule/${schedule.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify(schedule),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  deleteTourSchedule(headers: Headers, tour_schedule_id: string): Promise<boolean> {
    return fetch(`/api/tour/schedule/${tour_schedule_id}`, {
      headers,
      method: "DELETE",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default tourApi;

import { Payment } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

export function readPayment(payment_id: string, provider_id: string) {
  return {
    type: actionTypes.PAYMENT_READ,
    payment_id,
    provider_id,
  };
}

export function callbackReadPayment(payment: Payment, errorCode: string | undefined) {
  return {
    type: actionTypes.PAYMENT_READ_CALLBACK,
    payment,
    errorCode,
  };
}

export function readReceipts(provider_id: string, start: Date, end: Date) {
  return {
    type: actionTypes.PAYMENT_READ_RECEIPTS,
    provider_id,
    start,
    end,
  };
}

export function callbackReadReceipts(payments: any[], errorCode: string | undefined) {
  return {
    type: actionTypes.PAYMENT_READ_RECEIPTS_CALLBACK,
    payments,
    errorCode,
  };
}

export function saveReceipt(payment_id: string, receipt: string | undefined, token: string) {
  return {
    type: actionTypes.PAYMENT_SAVE_RECEIPT,
    payment_id,
    receipt,
    token,
  };
}

export function callbackSaveReceipt(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.PAYMENT_SAVE_RECEIPT_CALLBACK,
    success,
    errorCode,
  };
}

import { AgeGroup, Center, CenterDisplayOptions, CenterQuestion, Child, Parent, Payment,
  Seat, Tour, TourPolicy, TourRequest, TourSchedule, WaitlistSpot } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";
import { CenterBasics } from "../infra/utils";

interface FamilyItem {
  child: Child;
  parent: Parent;
  parent2?: Parent;
  center?: Center;
  tour_id?: string;
  request_id?: string;
  tourState?: string;
  spot?: WaitlistSpot;
  seat?: Seat;
}

interface Waitlist {
  spots: WaitlistSpot[];
  ageGroups: AgeGroup[];
}

interface EnrolledSeat {
  child: Child;
  seat: Seat;
  payment: Payment;
}

interface Directory {
  centerCount: number,
  results: Array<{ center: Center, data: any }>,
}

interface TourTime {
  start: Date,
  duration: number,
  virtual: boolean,
}

interface IDefaultCenterState {
  loading: boolean,
  centerList: Center[];
  directory: Directory,
  familyList: FamilyItem[];
  tourFamilies: Tour[];
  tourRequestFamilies: TourRequest[];
  questionList: any[] | undefined;
  profileCMS: any | undefined,
  centerMetadata: any | undefined,
  employees: any | undefined,
  centerBasics: CenterBasics,
  profileImages: string[] | undefined,
  tourList: Tour[] | undefined;
  tourSchedules: TourSchedule[] | undefined;
  tourPolicy: TourPolicy | undefined;
  vacationDays: Date[] | undefined;
  availableTourTimes: TourTime[] | undefined;
  hasTourSchedule: boolean | undefined;
  displayOptions: CenterDisplayOptions | undefined;
  centerCount: number | undefined;
  ageGroupList: AgeGroup[] | undefined;
  ageGroupListByCenterId: { [center_id: string]: AgeGroup[] };
  waitlist: Waitlist | undefined;
  enrolledSeats: EnrolledSeat[] | undefined;
  enrollments: any | undefined;
  waitlistScores: Array<{ center_id: string, score: number }>;
  currentCenter: Center | undefined;
  addSuccess: boolean | undefined;
  addedCenterId: string | undefined;
  updateSuccess: boolean | undefined;
  updateQuestionsSuccess: boolean | undefined;
  updateProfileCMSSuccess: boolean | undefined;
  updateDisplayOptionsSuccess: boolean | undefined;
  updateTourSchedulesSuccess: boolean | undefined;
  updateTourScheduleVacationSuccess: boolean | undefined;
  updateTourPolicySuccess: boolean | undefined;
  sendWaitlistFormSuccess: boolean | undefined;
  setAgeGroupSuccess: boolean | undefined;
  deleteSuccess: boolean | undefined;
  errorCode: string | undefined;
  reviewRequestsSentCount: string | undefined,
  reviewRequestsSentCountLoading: boolean | undefined,
  reviewRequestsSentCountSuccess: boolean | undefined,
  reviewRequestsSentCountError: boolean | undefined,
  sendReviewRequestEmailsLoading: boolean | undefined,
  sendReviewRequestEmailsSuccess: boolean | undefined,
  sendReviewRequestEmailsError: boolean | undefined,
  saveCenterEnrollmentLoading: boolean | undefined,
  saveCenterEnrollmentSuccess: boolean | undefined,
  saveCenterEnrollmentError: string | undefined,
  profileCMSLoaded: boolean,
}
const defaultProductState: IDefaultCenterState = {
  loading: false,
  centerList: undefined,
  directory: undefined,
  familyList: undefined,
  tourFamilies: undefined,
  tourRequestFamilies: undefined,
  questionList: undefined,
  profileCMS: undefined,
  centerMetadata: undefined,
  employees: undefined,
  centerBasics: undefined,
  profileImages: undefined,
  tourList: undefined,
  tourSchedules: undefined,
  tourPolicy: undefined,
  vacationDays: undefined,
  availableTourTimes: undefined,
  hasTourSchedule: undefined,
  displayOptions: undefined,
  centerCount: undefined,
  ageGroupList: undefined,
  ageGroupListByCenterId: {},
  waitlist: undefined,
  enrolledSeats: undefined,
  enrollments: undefined,
  waitlistScores: undefined,
  currentCenter: null,
  addSuccess: undefined,
  addedCenterId: undefined,
  updateSuccess: undefined,
  updateQuestionsSuccess: undefined,
  updateProfileCMSSuccess: undefined,
  updateDisplayOptionsSuccess: undefined,
  updateTourSchedulesSuccess: undefined,
  updateTourScheduleVacationSuccess: undefined,
  updateTourPolicySuccess: undefined,
  sendWaitlistFormSuccess: undefined,
  setAgeGroupSuccess: undefined,
  deleteSuccess: undefined,
  errorCode: undefined,
  reviewRequestsSentCount: undefined,
  reviewRequestsSentCountLoading: undefined,
  reviewRequestsSentCountSuccess: undefined,
  reviewRequestsSentCountError: undefined,
  sendReviewRequestEmailsLoading: undefined,
  sendReviewRequestEmailsSuccess: undefined,
  sendReviewRequestEmailsError: undefined,
  saveCenterEnrollmentLoading: undefined,
  saveCenterEnrollmentSuccess: undefined,
  saveCenterEnrollmentError: undefined,
  profileCMSLoaded: undefined,
};

function reduceFindCentersCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const centerList = [];
  if (action.centerList) {
    action.centerList.forEach(c => {
      const center = new Center();
      center.buildFromJSON(c);
      centerList.push(center);
    });
  }

  return { ...state, centerList };
}

function reduceReadCenterCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const center: Center = new Center();

  center.buildFromJSON(action.center);

  return { ...state, currentCenter: center };
}

function reduceReadCenterFamiliesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const familyList: FamilyItem[] = [];
  (action.families || []).forEach(f => {
    let child: Child | undefined;
    let parent: Parent | undefined;
    let parent2: Parent | undefined;
    let center: Center | undefined;
    let spot: WaitlistSpot | undefined;
    let seat: Seat | undefined;

    if (f.child) {
      child = new Child();
      child.buildFromJSON(f.child);
    }
    if (f.parent) {
      parent = new Parent();
      parent.buildFromJSON(f.parent);
    }
    if (f.parent2) {
      parent2 = new Parent();
      parent2.buildFromJSON(f.parent2);
    }
    if (f.center) {
      center = new Center();
      center.buildFromJSON(f.center);
    }
    if (f.spot) {
      spot = new WaitlistSpot();
      spot.buildFromJSON(f.spot);
      spot.setChild(child);
    }
    if (f.seat) {
      seat = new Seat();
      seat.buildFromJSON(f.seat);
    }

    familyList.push({
      child,
      parent,
      parent2,
      center,
      tour_id: f.tour_id,
      request_id: f.request_id,
      tourState: f.tourState,
      spot,
      seat,
    });
  });

  const tourFamilies: Tour[] = [];
  (action.tours || []).forEach(t => {
    const tour: Tour = new Tour();
    tour.buildFromJSON(t);
    tourFamilies.push(tour);
  });

  const tourRequestFamilies: TourRequest[] = [];
  (action.tourRequests || []).forEach(r => {
    const tourRequest: TourRequest = new TourRequest();
    tourRequest.buildFromJSON(r);
    tourRequestFamilies.push(tourRequest);
  });

  return { ...state, familyList, tourFamilies, tourRequestFamilies };
}

function reduceReadDirectoryCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const directory: Directory = { results: [], centerCount: 0 };
  directory.centerCount = action.directory.centerCount;
  action.directory.results.forEach(r => {
    const center = new Center();
    center.buildFromJSON(r.center);
    directory.results.push({ center, data: { ...r.data } });
  });

  return { ...state, directory };
}

function reduceScoreWaitlistsCallback(state: any, action: any) {
  // Unlike other reducers, we don't care to message an error
  if (!action.errorCode) {
    const waitlistScores: Array<{ center_ids: string, score: number }> = (action.scores || []).slice(0);

    return { ...state, waitlistScores };
  }

  return { ...state };
}

function reduceReadProfileCMS(state: any, _action: any) {
  return { ...state, profileCMSLoaded: undefined };
}

function reduceReadProfileCMSCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode, profileCMSLoaded: true };
  }

  return { ...state,
    profileCMS: action.cmsData,
    centerMetadata: action.metadata,
    employees: action.employees,
    centerBasics: action.centerBasics,
    profileImages: action.profileImages,
    profileCMSLoaded: true,
  };
}

function reduceReadToursCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const tourList: Tour[] = [];
  (action.tours || []).forEach(t => {
    const tour = new Tour();
    tour.buildFromJSON(t);
    tourList.push(tour);
  });

  return { ...state, tourList };
}

function reduceReadTourSchedulesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const tourSchedules: TourSchedule[] = [];
  (action.tourSchedules || []).forEach(t => {
    const slot = new TourSchedule();
    slot.buildFromJSON(t);
    tourSchedules.push(slot);
  });

  // We can ignore any vacation days that are in the past
  // Remember we're comparing against UTC dates coming in
  const vacationDays: Date[] = [];
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  today.setTime(today.getTime() - today.getTimezoneOffset() * 60000);
  (action.vacationDays || []).forEach(d => {
    const date = new Date(d);
    if (date >= today) {
      vacationDays.push(date);
    }
  });

  return { ...state, tourSchedules, vacationDays };
}

function reduceReadTourPolicyCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const tourPolicy: TourPolicy | undefined = action.policy ? ({ ...action.policy }) : undefined;

  return { ...state, tourPolicy };
}

function reduceListAvailableTourTimesCallback(state: any, action: any) {
  if (action.errorCode) {
    if (action.errorCode === "NOTOURSCHEDULE") {
      return { ...state, availableTourTimes: [], hasTourSchedule: false };
    }

    return { ...state, errorCode: action.errorCode };

  }

  const times: TourTime[] = [];
  (action.times || []).forEach((t: any) => {
    times.push({
      start: new Date(t.start),
      duration: t.duration,
      virtual: t.virtual,
    });
  });

  return { ...state, availableTourTimes: times, hasTourSchedule: true };
}

function reduceReadDisplayOptionsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, displayOptions: action.displayOptions };
}

function reduceUpdateTourSchedules(state: any, action: any) {
  return { ...state, updateTourSchedulesSuccess: undefined };
}

function reduceUpdateTourSchedulesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, updateTourSchedulesSuccess: action.success };
}

function reduceUpdateTourScheduleVacation(state: any, action: any) {
  return { ...state, updateTourScheduleVacationSuccess: undefined };
}

function reduceUpdateTourScheduleVacationCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, updateTourScheduleVacationSuccess: action.success };
}

function reduceUpdateTourPolicyCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, updateTourPolicySuccess: action.success };
}

function reduceReadCenterEnrolledSeatsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const enrolledSeats: EnrolledSeat[] = [];
  action.list.forEach(l => {
    const seat = new Seat();
    seat.buildFromJSON(l.seat);
    const child = new Child();
    child.buildFromJSON(l.child);
    const payment = new Payment();
    payment.buildFromJSON(l.payment);
    enrolledSeats.push({ seat, child, payment });
  });

  return { ...state, enrolledSeats };
}

function reduceReadCenterPostEnrollmentReviewsCallback(state: any, action: any) {
  switch (action.type) {
    case "center_read_post_enrollment_reviews_loading":
      return {
        ...state,
        reviewRequestsSentCountLoading: true,
      };
    case "center_read_post_enrollment_reviews_success":
      return {
        ...state,
        reviewRequestsSentCount: action.reviewRequestsSentCount,
        reviewRequestsSentCountLoading: false,
        reviewRequestsSentCountSuccess: true,
        reviewRequestsSentCountError: false,
        errorCode: action.errorCode,
      };
    case "center_read_post_enrollment_reviews_error":
      return {
        ...state,
        reviewRequestsSentCountLoading: false,
        reviewRequestsSentCountSuccess: false,
        reviewRequestsSentCountError: true,
        errorCode: action.errorCode,
      };
    default:
      return {
        ...state,
        reviewRequestsSentCountLoading: false,
        reviewRequestsSentCountSuccess: false,
        reviewRequestsSentCountError: false,
      };
  }
}

function reduceSendPostEnrollmentReviewRequestEmailsCallback(state: any, action: any) {
  switch (action.type) {
    case "center_send_post_enrollment_review_request_emails_loading":
      return {
        ...state,
        sendReviewRequestEmailsLoading: true,
        sendReviewRequestEmailsSuccess: false,
        sendReviewRequestEmailsError: false,
      };
    case "center_send_post_enrollment_review_request_emails_success":
      return {
        ...state,
        sendReviewRequestEmailsLoading: false,
        sendReviewRequestEmailsSuccess: true,
        sendReviewRequestEmailsError: false,
      };
    case "center_send_post_enrollment_review_request_emails_error":
      return {
        ...state,
        sendReviewRequestEmailsLoading: false,
        sendReviewRequestEmailsSuccess: false,
        sendReviewRequestEmailsError: true,
        errorCode: action.errorCode,
      };
    case "center_send_post_enrollment_review_request_emails_reset":
      return {
        ...state,
        sendReviewRequestEmailsLoading: false,
        sendReviewRequestEmailsSuccess: false,
        sendReviewRequestEmailsError: false,
        errorCode: "",
      };
    default:
      return {
        ...state,
        sendReviewRequestEmailsLoading: undefined,
        sendReviewRequestEmailsSuccess: undefined,
        sendReviewRequestEmailsError: undefined,
      };
  }
}

function reduceReadCenterEnrollmentsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const enrollments = action.enrollments.map(enrollment => {
    const parent = new Parent();
    parent.buildFromJSON(enrollment.parent);
    const child = new Child();
    child.buildFromJSON(enrollment.child);
    const seat = () => {
      if (enrollment.seat) {
        const builtSeat = new Seat();
        builtSeat.buildFromJSON(enrollment.seat);

        return builtSeat;
      }

      return enrollment.seat;
    };

    const payment = () => {
      if (enrollment.payment) {
        const builtPayment = new Payment();
        builtPayment.buildFromJSON(enrollment.payment);

        return builtPayment;
      }

      return enrollment.payment;
    };

    return {
      amount: enrollment.amount,
      enrollment_id: enrollment.enrollment_id,
      child,
      parent,
      payment: payment(),
      seat: seat(),
    };
  });

  return { ...state, enrollments };
}

function reduceSaveCenterEnrollmentCallback(state: any, action: any) {
  switch (action.type) {
    case "center_save_enrollment_loading":
      return {
        ...state,
        saveCenterEnrollmentLoading: true,
        saveCenterEnrollmentSuccess: false,
        saveCenterEnrollmentError: action.errorCode,
      };
    case "center_save_enrollment_success":
      return {
        ...state,
        saveCenterEnrollmentLoading: false,
        saveCenterEnrollmentSuccess: true,
        saveCenterEnrollmentError: action.errorCode,
      };
    case "center_save_enrollment_error":
      return {
        ...state,
        saveCenterEnrollmentLoading: false,
        saveCenterEnrollmentSuccess: false,
        saveCenterEnrollmentError: action.errorCode,
      };
    default:
      return {
        ...state,
        saveCenterEnrollmentLoading: undefined,
        saveCenterEnrollmentSuccess: undefined,
        saveCenterEnrollmentError: undefined,
      };
  }
}

function reduceListQuestions(state: any, action: any) {
  return { ...state, questionList: undefined };
}

function reduceListQuestionsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }
  const questionList = [];
  if (action.questions) {
    action.questions.forEach(q => {
      const question = new CenterQuestion();
      question.buildFromJSON(q);
      questionList.push(question);
    });
  }

  return { ...state, questionList, centerCount: action.centerCount };
}

function reduceListAgeGroups(state: any, action: any) {
  return { ...state, ageGroupList: undefined, loading: true };
}

function reduceListAgeGroupsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode, loading: false };
  }

  const ageGroupList = [];
  const ageGroupListByCenterId = { ...state.ageGroupListByCenterId };
  if (action.ageGroups) {
    action.ageGroups.forEach(c => {
      const ageGroup = new AgeGroup();
      ageGroup.buildFromJSON(c);
      ageGroupList.push(ageGroup);
    });
    const centerId = ageGroupList?.[0]?.center_id;
    if (centerId) {
      ageGroupListByCenterId[centerId] = ageGroupList;
    }
  }

  return { ...state, ageGroupList, ageGroupListByCenterId, loading: false };
}

function reduceSetAgeGroups(state: any, action: any) {
  return { ...state, setAgeGroupSuccess: undefined };
}

function reduceSetAgeGroupsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, setAgeGroupSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, setAgeGroupSuccess: action.success };
}

function reduceListWaitlist(state: any, action: any) {
  return { ...state, waitlist: undefined };
}

function reduceListWaitlistCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const waitlist: Waitlist = { ageGroups: [], spots: [] };
  if (action.ageGroups) {
    action.ageGroups.forEach(c => {
      const ageGroup = new AgeGroup();
      ageGroup.buildFromJSON(c);
      waitlist.ageGroups.push(ageGroup);
    });
  }
  if (action.spots) {
    action.spots.forEach(s => {
      const spot = new WaitlistSpot();
      spot.buildFromJSON(s);
      waitlist.spots.push(spot);
    });
  }

  return { ...state, waitlist };
}

function reduceAddCenter(state: any, action: any) {
  return { ...state, addSuccess: undefined, addedCenterId: undefined };
}

function reduceAddCenterCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, addSuccess: action.success, addedCenterId: action.center_id };
}

function reduceUpdateCenter(state: any, action: any) {
  return { ...state, updateSuccess: undefined };
}

function reduceUpdateCenterCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSuccess: action.success };
}

function reduceUpdateCenterQuestionsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateQuestionsSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateQuestionsSuccess: action.success };
}

function reduceUpdateProfileCMS(state: any, action: any) {
  return { ...state, updateProfileCMSSuccess: undefined };
}

function reduceUpdateProfileCMSCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateProfileCMSSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateProfileCMSSuccess: action.success };
}

function reduceUpdateDisplayOptionsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateDisplayOptionsSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateDisplayOptionsSuccess: action.success };
}

function reduceDeleteCenter(state: any, action: any) {
  return { ...state, deleteSuccess: undefined };
}

function reduceDeleteCenterCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, deleteSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, deleteSuccess: action.success };
}

function reduceSendWaitlistForm(state: any, action: any) {
  return { ...state, sendWaitlistFormSuccess: undefined };
}

function reduceSendWaitlistFormCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, sendWaitlistFormSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, sendWaitlistFormSuccess: action.success };
}

function reduceCenters(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.CENTER_FIND_CALLBACK:
      return reduceFindCentersCallback(state, action);
    case actionTypes.CENTER_READ_CALLBACK:
      return reduceReadCenterCallback(state, action);
    case actionTypes.CENTER_READ_FAMILIES_CALLBACK:
      return reduceReadCenterFamiliesCallback(state, action);
    case actionTypes.CENTER_READ_DIRECTORY_CALLBACK:
      return reduceReadDirectoryCallback(state, action);
    case actionTypes.CENTER_SCORE_WAITLISTS_CALLBACK:
      return reduceScoreWaitlistsCallback(state, action);
    case actionTypes.CENTER_READ_PROFILE_CMS_CALLBACK:
      return reduceReadProfileCMSCallback(state, action);
    case actionTypes.CENTER_READ_TOURS_CALLBACK:
      return reduceReadToursCallback(state, action);
    case actionTypes.CENTER_READ_TOUR_SCHEDULES_CALLBACK:
      return reduceReadTourSchedulesCallback(state, action);
    case actionTypes.CENTER_READ_TOUR_POLICIES_CALLBACK:
      return reduceReadTourPolicyCallback(state, action);
    case actionTypes.CENTER_LIST_AVAILABLE_TOURS_CALLBACK:
      return reduceListAvailableTourTimesCallback(state, action);
    case actionTypes.CENTER_READ_DISPLAY_OPTIONS_CALLBACK:
      return reduceReadDisplayOptionsCallback(state, action);
    case actionTypes.CENTER_READ_ENROLLED_SEATS_CALLBACK:
      return reduceReadCenterEnrolledSeatsCallback(state, action);
    case actionTypes.CENTER_READ_ENROLLMENTS_CALLBACK:
      return reduceReadCenterEnrollmentsCallback(state, action);
    case actionTypes.CENTER_READ_POST_ENROLLMENT_REVIEWS_LOADING:
    case actionTypes.CENTER_READ_POST_ENROLLMENT_REVIEWS_SUCCESS:
    case actionTypes.CENTER_READ_POST_ENROLLMENT_REVIEWS_ERROR:
      return reduceReadCenterPostEnrollmentReviewsCallback(state, action);
    case actionTypes.CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_LOADING:
    case actionTypes.CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_SUCCESS:
    case actionTypes.CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_ERROR:
    case actionTypes.CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_RESET:
      return reduceSendPostEnrollmentReviewRequestEmailsCallback(state, action);
    case actionTypes.CENTER_SAVE_ENROLLMENT_LOADING:
    case actionTypes.CENTER_SAVE_ENROLLMENT_SUCCESS:
    case actionTypes.CENTER_SAVE_ENROLLMENT_ERROR:
      return reduceSaveCenterEnrollmentCallback(state, action);
    case actionTypes.CENTER_QUESTIONS:
      return reduceListQuestions(state, action);
    case actionTypes.CENTER_QUESTIONS_CALLBACK:
      return reduceListQuestionsCallback(state, action);
    case actionTypes.CENTER_AGEGROUPS:
      return reduceListAgeGroups(state, action);
    case actionTypes.CENTER_AGEGROUPS_CALLBACK:
      return reduceListAgeGroupsCallback(state, action);
    case actionTypes.CENTER_SET_AGEGROUPS:
      return reduceSetAgeGroups(state, action);
    case actionTypes.CENTER_SET_AGEGROUPS_CALLBACK:
      return reduceSetAgeGroupsCallback(state, action);
    case actionTypes.CENTER_WAITLISTS:
      return reduceListWaitlist(state, action);
    case actionTypes.CENTER_WAITLISTS_CALLBACK:
      return reduceListWaitlistCallback(state, action);
    case actionTypes.CENTER_ADD:
      return reduceAddCenter(state, action);
    case actionTypes.CENTER_ADD_CALLBACK:
      return reduceAddCenterCallback(state, action);
    case actionTypes.CENTER_UPDATE:
      return reduceUpdateCenter(state, action);
    case actionTypes.CENTER_UPDATE_CALLBACK:
      return reduceUpdateCenterCallback(state, action);
    case actionTypes.CENTER_UPDATE_QUESTIONS_CALLBACK:
      return reduceUpdateCenterQuestionsCallback(state, action);
    case actionTypes.CENTER_UPDATE_PROFILE_CMS:
      return reduceUpdateProfileCMS(state, action);
    case actionTypes.CENTER_UPDATE_DISPLAY_OPTIONS_CALLBACK:
      return reduceUpdateDisplayOptionsCallback(state, action);
    case actionTypes.CENTER_UPDATE_TOUR_SCHEDULES:
      return reduceUpdateTourSchedules(state, action);
    case actionTypes.CENTER_UPDATE_TOUR_SCHEDULES_CALLBACK:
      return reduceUpdateTourSchedulesCallback(state, action);
    case actionTypes.CENTER_UPDATE_TOUR_SCHEDULE_VACATION:
      return reduceUpdateTourScheduleVacation(state, action);
    case actionTypes.CENTER_UPDATE_TOUR_SCHEDULE_VACATION_CALLBACK:
      return reduceUpdateTourScheduleVacationCallback(state, action);
    case actionTypes.CENTER_UPDATE_TOUR_POLICIES_CALLBACK:
      return reduceUpdateTourPolicyCallback(state, action);
    case actionTypes.CENTER_UPDATE_PROFILE_CMS_CALLBACK:
      return reduceUpdateProfileCMSCallback(state, action);
    case actionTypes.CENTER_DELETE:
      return reduceDeleteCenter(state, action);
    case actionTypes.CENTER_DELETE_CALLBACK:
      return reduceDeleteCenterCallback(state, action);
    case actionTypes.CENTER_SEND_WAITLIST_FORM:
      return reduceSendWaitlistForm(state, action);
    case actionTypes.CENTER_SEND_WAITLIST_FORM_CALLBACK:
      return reduceSendWaitlistFormCallback(state, action);
    case actionTypes.CENTER_READ_PROFILE_CMS:
      return reduceReadProfileCMS(state, action);
    default:
      return state;
  }
}

export default reduceCenters;

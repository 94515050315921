import { Lead } from "@legup/legup-model";

const leadApi = {
  addLead(headers: Headers, center_id: string, lead: Lead): Promise<any> {
    return fetch("/api/lead", {
      headers,
      method: "POST",
      body: JSON.stringify({ center: center_id, lead }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateLead(headers: Headers, lead: Lead): Promise<any> {
    return fetch(`/api/lead/${lead.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify(lead),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default leadApi;

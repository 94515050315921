import querystring from "querystring";

import { AdminUnit } from "@legup/legup-model";

const adminUnitApi = {
  findAdminUnits(headers: Headers, name: string): Promise<AdminUnit[]> {
    const queryParams = querystring.stringify({ name });

    return fetch(`/api/adminunit/find?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readAdminUnit(headers: Headers, admin_unit_id: string): Promise<AdminUnit> {
    return fetch(`/api/adminunit/${admin_unit_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addAdminUnit(headers: Headers, adminUnit: AdminUnit): Promise<string> {
    return fetch("/api/adminunit", {
      headers,
      method: "POST",
      body: JSON.stringify(adminUnit),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateAdminUnit(headers: Headers, adminUnit: AdminUnit): Promise<boolean> {
    return fetch(`/api/adminunit/${adminUnit.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify(adminUnit),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default adminUnitApi;

import actionTypes from "../infra/constants/actionTypes";

export function listSubsidies(id: string, itemType: "admin" | "center" | "provider" | "leguplist" | "parent" | "partner") {
  return {
    type: actionTypes.LIST_SUBSIDIES,
    id,
    itemType,
  };
}

export function callbackListSubsidies(success: boolean, subsidies: any, enabledSubsidies: any, errorCode: string | undefined) {
  return {
    type: actionTypes.LIST_SUBSIDIES_CALLBACK,
    success,
    subsidies,
    enabledSubsidies,
    errorCode,
  };
}

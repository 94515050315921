import { AuthToken } from "@legup/legup-model";

const authAPI = {
  token(code: string, group: string, name: string, email: string, partner_id: string): Promise<AuthToken> {
    return fetch("/api/auth/token", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ code, group, name, email, partner: partner_id }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  acceptTerms(headers: Headers): Promise<boolean> {
    return fetch("/api/auth/acceptTerms", {
      headers,
      method: "POST",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default authAPI;

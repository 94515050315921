import querystring from "querystring";

import { Payment } from "@legup/legup-model";

import { formatDateForDatePicker, sendDebugInfo } from "../infra/utils";

const paymentApi = {
  readPayment(headers: Headers, payment_id: string, provider_id: string): Promise<Payment | undefined> {
    const queryParams = querystring.stringify({ provider: provider_id });

    return fetch(`/api/payment/${payment_id}?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readReceipts(headers: Headers, provider_id: string, start: Date, end: Date): Promise<any> {
    const queryParams = querystring.stringify({ provider: provider_id, start: formatDateForDatePicker(start), end: formatDateForDatePicker(end) });

    return fetch(`/api/payment/receipts?${queryParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  // Note this is an anonymous function
  saveReceipt(payment_id: string, receipt: string, token: string): Promise<boolean> {
    return fetch(`/api/payment/${payment_id}/receipt`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ receipt, token }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default paymentApi;


import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import classroomApi from "../api/classroomApi";
import {
  callbackAddClassroom,
  callbackDeleteClassroom,
  callbackReadClassroom,
  callbackUpdateClassroom,
} from "../actions/classroomActions";
import { prepareHeader } from "../infra/utils";
import actionTypes from "../infra/constants/actionTypes";

function* fetchReadClassroomSaga(action: any) {
  const state = yield select();
  const data = yield call(classroomApi.readClassroom, prepareHeader(state.clientStateReducer), action.classroom_id);
  yield put(callbackReadClassroom(data.classroom, !data.success ? (data.errorCode || "APIERROR") : undefined));
}

function* getReadClassroomSaga() {
  yield takeLatest(actionTypes.CLASSROOM_READ_LOADING, fetchReadClassroomSaga);
}

function* fetchAddClassroomSaga(action: any) {
  const state = yield select();
  const addClassroomData = yield call(classroomApi.addClassroom, prepareHeader(state.clientStateReducer), action.classroom);
  const classroom_id = addClassroomData?.classroom_id;
  yield put(callbackAddClassroom(classroom_id, !addClassroomData.success ? (addClassroomData.errorCode || "APIERROR") : undefined));
}

function* getAddClassroomSaga() {
  yield takeLatest(actionTypes.CLASSROOM_ADD_LOADING, fetchAddClassroomSaga);
}

function* fetchUpdateClassroomSaga(action: any) {
  const state = yield select();
  const updatedClassroomData = yield call(classroomApi.updateClassroom, prepareHeader(state.clientStateReducer), action.classroom);
  const updateSuccess = updatedClassroomData?.success;
  yield put(callbackUpdateClassroom(updateSuccess, !updatedClassroomData.success ? (updatedClassroomData.errorCode || "APIERROR") : undefined));
}

function* getUpdateClassroomSaga() {
  yield takeLatest(actionTypes.CLASSROOM_UPDATE_LOADING, fetchUpdateClassroomSaga);
}

function* fetchDeleteClassroomSaga(action: any) {
  const state = yield select();
  const deleteClassroomData = yield call(classroomApi.deleteClassroom, prepareHeader(state.clientStateReducer), action.classroom);
  const deleteSuccess = deleteClassroomData?.success;
  yield put(callbackDeleteClassroom(deleteSuccess, !deleteClassroomData.success ? (deleteClassroomData.errorCode || "APIERROR") : undefined));
}

function* getDeleteClassroomSaga() {
  yield takeLatest(actionTypes.CLASSROOM_DELETE_LOADING, fetchDeleteClassroomSaga);
}

function* yieldClassroomSagas() {
  yield all([
    fork(getReadClassroomSaga),
    fork(getAddClassroomSaga),
    fork(getUpdateClassroomSaga),
    fork(getDeleteClassroomSaga),
  ]);
}

export default yieldClassroomSagas;

import querystring from "querystring";

import { AgeGroup, Center, Classroom, Program, Provider, ProviderFile, Seat } from "@legup/legup-model";

interface ProviderSeats {
  seats: Seat[]
  classroom: Classroom[]
  centers: Center[]
}

const providerApi = {
  async getUnreadBadge(headers: Headers, email: string): Promise<Number> {
    const params = querystring.stringify({ email });

    return fetch(`/api/provider/messagesbadge?${params}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  getNewToursCount(headers: Headers, provider_id: string): Promise<Number> {
    const params = querystring.stringify({ provider_id });

    return fetch(`/api/provider/toursCount?${params}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  findProviders(headers: Headers, name: string, relationship: string): Promise<Provider[]> {
    const searchParams = querystring.stringify({ name, relationship });

    return fetch(`/api/provider/find?${searchParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readProvider(headers: Headers, provider_id: string): Promise<Provider> {
    return fetch(`/api/provider/${provider_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readProviderFamilies(headers: Headers, provider_id: string): Promise<Classroom[]> {
    return fetch(`/api/provider/${provider_id}/families`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readProviderClassrooms(headers: Headers, provider_id: string): Promise<Classroom[]> {
    return fetch(`/api/provider/${provider_id}/classrooms`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readProviderCommission(headers: Headers, provider_id: string): Promise<any> {
    return fetch(`/api/provider/${provider_id}/commission`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readProviderAgeGroups(headers: Headers, provider_id: string): Promise<AgeGroup[]> {
    return fetch(`/api/provider/${provider_id}/agegroups`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readProviderPrograms(headers: Headers, provider_id: string): Promise<Program[]> {
    return fetch(`/api/provider/${provider_id}/programs`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readProviderSeats(headers: Headers, provider_id: string, center_id: string): Promise<ProviderSeats> {
    let url: string;
    if (center_id) {
      const params = querystring.stringify({ cid: center_id });
      url = `/api/provider/${provider_id}/seats?${params}`;
    }
    else {
      url = `/api/provider/${provider_id}/seats`;
    }

    return fetch(url, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readProviderReport(headers: Headers, provider_id: string): Promise<any> {
    return fetch(`/api/provider/${provider_id}/report`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  listQuestions(headers: Headers, provider_id: string, addCenter: boolean, includeInternal: boolean, form: "waitlist" | "deposit" | "both"): Promise<any[]> {
    const params: any = { form };
    if (addCenter) {
      params.addCenter = "1";
    }
    if (includeInternal) {
      params.internal = "1";
    }
    const searchParams = querystring.stringify(params);

    return fetch(`/api/provider/${provider_id}/questions?${searchParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readProviderUserAccess(headers: Headers, provider_id: string): Promise<any> {
    return fetch(`/api/provider/${provider_id}/users`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  listPartners(headers: Headers, states: string[]): Promise<any> {
    let url;
    if (states) {
      const searchParams = querystring.stringify({ states: states.join(",") });
      url = `/api/provider/partners?${searchParams}`;
    }
    else {
      url = "/api/provider/partners";
    }

    return fetch(url, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  listCoupons(headers: Headers, provider_id: string): Promise<any[]> {
    return fetch(`/api/provider/${provider_id}/coupons`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateWaiveSubsidies(headers: Headers, provider_id: string, waive_subsidies: boolean) {
    return fetch(`/api/provider/${provider_id}/coupon/waive`, {
      headers,
      method: "POST",
      body: JSON.stringify({ waive_subsidies }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addProvider(headers: Headers, provider: Provider) {
    return fetch("/api/provider", {
      headers,
      method: "POST",
      body: JSON.stringify(provider),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateProvider(headers: Headers, provider: Provider) {
    return fetch(`/api/provider/${provider.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify(provider),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateProviderOnboarding(headers: Headers, provider_id: string, state: string, reason: string | undefined) {
    return fetch(`/api/provider/${provider_id}/onboarding`, {
      headers,
      method: "POST",
      body: JSON.stringify({ state, reason }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateCenterStates(headers: Headers, provider_id: string, centerStates: any[]) {
    return fetch(`/api/provider/${provider_id}/updatestates`, {
      headers,
      method: "POST",
      body: JSON.stringify({ centers: centerStates }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  signUpUserAccount(headers: Headers, provider_id: string, email: string, phone_number: string, first_name: string, last_name: string, sendEmail: boolean, notification: any) {
    return fetch(`/api/provider/${provider_id}/account/signUp`, {
      headers,
      method: "POST",
      body: JSON.stringify({ email, first_name, last_name, phone_number, sendEmail, notification }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  removeUserAccount(headers: Headers, provider_id: string, email: string, deleteUser: boolean) {
    return fetch(`/api/provider/${provider_id}/account`, {
      headers,
      method: "DELETE",
      body: JSON.stringify({ email, deleteUser }),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  getStripeUrl(headers: Headers, provider_id: string): Promise<Provider[]> {
    const searchParams = querystring.stringify({ provider: provider_id });

    return fetch(`/api/provider/stripe/authorize?${searchParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readActionItems(headers: Headers, provider_id: string, center_id: string) {
    const searchParams = querystring.stringify({ provider_id, center_id });

    return fetch(`/api/provider/${provider_id}/actionable?${searchParams}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readFiles(headers: Headers, provider_id: string) {
    return fetch(`/api/provider/${provider_id}/files`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  addFile(headers: Headers, provider_id: string, file: ProviderFile) {
    return fetch(`/api/provider/${provider_id}/files`, {
      headers,
      method: "POST",
      body: JSON.stringify(file),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
  confirmFeesStructure(headers: Headers, provider_id: string) {
    return fetch(`/api/provider/${provider_id}/confirmFeeStructure`, {
      headers,
      method: "PUT",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default providerApi;

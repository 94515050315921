import querystring from "querystring";

import { Navigator } from "@legup/legup-model";

const navigatorApi = {
  addNavigator(headers: Headers, navigator: Navigator): Promise<string> {
    return fetch("/api/navigator", {
      headers,
      method: "POST",
      body: JSON.stringify(navigator),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  updateNavigator(headers: Headers, navigator: Navigator): Promise<boolean> {
    return fetch(`/api/navigator/${navigator.getId()}`, {
      headers,
      method: "POST",
      body: JSON.stringify(navigator),
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readNavigator(headers: Headers, navigator_id: string): Promise<Navigator> {
    return fetch(`/api/navigator/${navigator_id}`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readNavigatorFamilies(headers: Headers, navigator_id: string): Promise<any> {
    return fetch(`/api/navigator/${navigator_id}/families`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readNavigatorStatus(headers: Headers, navigator_id: string): Promise<any> {
    return fetch(`/api/navigator/${navigator_id}/status`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },

  readNavigatorCenters(headers: Headers, navigator_id: string): Promise<any> {
    return fetch(`/api/navigator/${navigator_id}/centers`, {
      headers,
      method: "GET",
    })
    .then(res => res.json())
    .then(data => data)
    .catch(e => {
      console.log(e);
    });
  },
};

export default navigatorApi;
